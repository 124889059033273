export enum ExportType {
  PDF,
  CSV,
  PDF_CSV,
  NONE
}

export const Colors = {
  Bianco: '#FFFFFF',
  Rosso: '#FF0000',
  Verde: '#00FF00',
  Giallo: '#FFFF00',
  Arancione: '#FFAA00',
} as const
export type ColorsKeys = keyof typeof Colors

export const AbilitazioneLookup = {
  1: 'Overview',
  2: 'Read',
  3: 'Read, Update',
  4: 'Read, Update, Logic Delete',
  5: 'Read, Update, Logic Delete, Delete, Restore',
} as const
export type AbilitazioneLookupKeys = keyof typeof AbilitazioneLookup

export const TipoCampoLookup = {
  numeric: 'NUMBER',
  boolean: 'BOOLEAN',
  string: 'STRING',
} as const
export type TipoCampoLookupKeys = keyof typeof TipoCampoLookup

export const MostraSoloInLookup = {
  table: 'TABLE',
  form: 'FORM',
} as const
export type MostraSoloInLookupKeys = keyof typeof MostraSoloInLookup

export const ModificabileLookup = {
  onAdd: 'IN INSERISCI',
  always: 'SEMPRE',
  onUpdate: 'IN MODIFICA',
  never: 'MAI',
} as const
export type ModificabileLookupKeys = keyof typeof ModificabileLookup

export const ComponentiLookup = {
  CrudMaterialTable: 'Crud MaterialTable',
  CrudMaterialTableWithoutLogicDelete: 'Crud MaterialTable Without Logic Delete',
  ReadOnlyMaterialTable: 'ReadOnly Material Table',
  GeneralForm: 'General Form',
  SimpleIdSelect: 'Simple IdSelect',
} as const
export type ComponentiLookupKeys = keyof typeof ComponentiLookup

export const Autenticazione2FALookup = {
  0: 'Nessuna Autenticazione a due Fattori',
  1: 'Autenticazione a due Fattori Mediante Messaggio Email',
  2: 'Autenticazione a due Fattori Mediante Messaggio SMS',
  3: 'Autenticazione a due Fattori Mediante Messaggio Email e SMS'
} as const
export type Autenticazione2FALookupKeys = keyof typeof Autenticazione2FALookup

Object.preventExtensions(Colors);
Object.preventExtensions(TipoCampoLookup);