
import { Fields } from "./Fields";

export type UtentiAbilitazioniKeys = keyof UtentiAbilitazioni;

export const elementIdProps: UtentiAbilitazioniKeys[] = ["idUtente", "idOrganizzazione", "idContratto", "idApplicazioneSoftware", "idApplicazioneSoftwareProfilo", "abilitazioneInizio"];
export const elementRenderProps: UtentiAbilitazioniKeys[] = ["nomeUtente"];

export const allFields: Fields[] = [
  {
    field: "idContratto", required: true, show: true, titleKey: "contractParam", lookupField: true, showOn: "form", editable: "onAdd",
  },
  {
    field: "idOrganizzazione", required: true, show: false, titleKey: "organizationParam", lookupField: true, showOn: "form", editable: "onAdd",
  },
  {
    field: "nomeOrganizzazione", type: "string", required: false, show: true, titleKey: "nameOrganizzazione", showOn: "table"
  },
  {
    field: "idApplicazioneSoftware", required: true, show: false, titleKey: "applicationSoftware", lookupField: true, showOn: "form", editable: "onAdd",
  },
  {
    field: "applicazioneSoftwareDescrizione", type: "string", required: false, show: true, titleKey: "applicationSoftwareDescription", showOn: "table"
  },
  {
    field: "idApplicazioneSoftwareProfilo", required: true, show: false, titleKey: "applicationSoftwareProfile", lookupField: true, showOn: "form", editable: "onAdd",
  },
  {
    field: "applicazioneSoftwareProfiloDescrizione", type: "string", required: false, show: true, titleKey: "applicazioneSoftwareProfiloDescription", showOn: "table"
  },
  {
    field: "abilitazioneInizio", type: "date", required: true, show: true, titleKey: "startEnabling", editable: "onAdd",
  },
  {
    field: "abilitazioneFine", type: "date", required: false, show: true, titleKey: "endEnabling",
  },
];

export interface UtentiAbilitazioni {
  idContratto: number,
  idUtente: number,
  nomeUtente: string,
  idOrganizzazione: number,
  nomeOrganizzazione: string,
  idApplicazioneSoftware: number,
  applicazioneSoftwareDescrizione: string,
  idApplicazioneSoftwareProfilo: number,
  applicazioneSoftwareProfiloDescrizione: string,
  abilitazioneInizio: string,
  abilitazioneFine: string,
  version: number
}