import { Fields } from "./Fields";
import { Organizzazione } from "./Organizzazione";

export type ContrattoKeys = keyof Contratto;

export const elementIdProps: ContrattoKeys[] = ["idContratto"];
export const elementRenderProps: ContrattoKeys[] = ["dataInizio"];

export const allFields: Fields[] = [
  {
    field: "idApplicazioneSoftware", titleKey: "applicationSoftware", required: true, show: false, lookupField: true, editable: 'always',
  },
  {
    field: "idApplicazioneSoftwareProfiloDefault", titleKey: "applicazioneSoftwareProfiloDefault", required: true, show: false, lookupField: true, editable: 'always',
  },
  {
    field: "idOrganizzazione", titleKey: "organizationParam", required: true, show: false, lookupField: true, editable: 'always',
  },
  {
    field: "organizzazioneNome", titleKey: "organizationParam", required: false, show: true,
  },
  {
    field: "applicazioneSoftwareDescrizione", titleKey: "applicationSoftware", required: false, show: true,
  },
  {
    field: "dataInizio", type: 'date', titleKey: "startDateParam", required: true, show: true,
  },
  {
    field: "dataFine", type: 'date', titleKey: "endDateParam", required: false, show: true,
  },
  {
    field: "dataInizioFatturazione", type: 'date', titleKey: "startInvoicing", required: true, show: true,
  },
  {
    field: "dataFineFatturazione", type: 'date', titleKey: "endInvocing", required: false, show: true,
  },
  {
    field: "applicazioneSoftwareProfiloDefaultDescrizione", type: 'string', titleKey: "applicazioneSoftwareProfiloDefault", required: false, show: true, showOn: "table"
  }
];

export interface Contratto {
  idContratto: number;
  idContrattoPrecedente: number;
  dataInizio: string;
  dataFine: string;
  dataInizioFatturazione: string,
  dataFineFatturazione: string;
  version: number;

  idOrganizzazione: number;
  organizzazioneNome: string;
  idApplicazioneSoftware: number;
  applicazioneSoftwareDescrizione: string;
  applicazioneSoftwareProfiloDefaultDescrizione: string,

  organizzazione: Organizzazione;
  applicazioneSoftware: any//ApplicazioneSofware;
}