import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, getById, getNotExtendedEntityById, upd } from '../../services/services.service'
import { createLookup } from '../../utils/utilfunctions';
import { elementIdProps, NomiHostOrganizzazioni, NomiHostOrganizzazioniKeys } from '../../models/NomiHostOrganizzazioni';

const microservice = "organizzazioni";
const entity = "nomihostorganizzazioni";

interface NomiHostOrganizzazioniState {
  statusValidnomiHostOrganizzazione: StatusEnum,
  validNomiHostOrganizzazione: NomiHostOrganizzazioni[],
  nomiHostOrganizzazione: NomiHostOrganizzazioni | null,
  lookup: Lookup,
  error: string | null
}

const initialState: NomiHostOrganizzazioniState = {
  statusValidnomiHostOrganizzazione: StatusEnum.Succeeded,
  validNomiHostOrganizzazione: [],
  nomiHostOrganizzazione: null,
  lookup: {},
  error: null
}

// @GetMapping("cerca/organizzazioni/id/{idOrganizzazione}")
export const fetchById = createAsyncThunk(entity + '/fetchById', async (obj: { idnomeHost: number, idOrganizzazione: number }) => {
  let ids = [obj.idnomeHost, obj.idOrganizzazione];
  const response = await getNotExtendedEntityById(microservice, entity, ids);
  return response.data as NomiHostOrganizzazioni;
});

// @GetMapping("cerca/organizzazioni/all")
export const fetchAll = createAsyncThunk(entity + '/fetchAll', async (obj: { idNomeHost: number }) => {
  let ids = [obj.idNomeHost];
  const response = await getById(microservice, entity, ids);
  return response.data as NomiHostOrganizzazioni[];
});

// @PostMapping("inserisci/organizzazioni")
export const insert = createAsyncThunk(entity + '/insert', async (OrganizationInfo: NomiHostOrganizzazioni) => {
  const response = await create(OrganizationInfo, microservice, entity);
  return response.data as NomiHostOrganizzazioni;
});

// @PutMapping("modifica/organizzazioni")
export const update = createAsyncThunk(entity + '/update', async (OrganizationInfo: NomiHostOrganizzazioni) => {
  const response = await upd(OrganizationInfo, microservice, entity);
  return response.data as NomiHostOrganizzazioni;
});

// @DeleteMapping("delete/organizzazioni/id/{idOrganizationInfo}")
export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const nomiHostOrganizzazioniSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    cleanLookup: (state: NomiHostOrganizzazioniState) => {
      state.lookup = {};
    },
    reset: (state: NomiHostOrganizzazioniState) => {
      return initialState;
    },
    resetError: (state: NomiHostOrganizzazioniState) => {
      state.error = initialState.error;
      state.statusValidnomiHostOrganizzazione = initialState.statusValidnomiHostOrganizzazione;
    }
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidnomiHostOrganizzazione = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidnomiHostOrganizzazione = StatusEnum.Failed;
      state.nomiHostOrganizzazione = null;
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<NomiHostOrganizzazioni>) => {
      state.statusValidnomiHostOrganizzazione = StatusEnum.Succeeded;
      state.nomiHostOrganizzazione = payload;
    })

    // fetchAll
    builder.addCase(fetchAll.pending, (state) => {
      state.statusValidnomiHostOrganizzazione = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidnomiHostOrganizzazione = StatusEnum.Failed;
      state.lookup = {};
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<NomiHostOrganizzazioni[]>) => {
      state.statusValidnomiHostOrganizzazione = StatusEnum.Succeeded;
      state.validNomiHostOrganizzazione = payload;
      state.lookup = createLookup(payload, "idOrganizzazioneNomeHost", ["nomeHost"])
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidnomiHostOrganizzazione = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidnomiHostOrganizzazione = StatusEnum.Failed;
      state.nomiHostOrganizzazione = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<NomiHostOrganizzazioni>) => {
      state.statusValidnomiHostOrganizzazione = StatusEnum.Succeeded;
      state.nomiHostOrganizzazione = payload;
      state.validNomiHostOrganizzazione = state.validNomiHostOrganizzazione.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidnomiHostOrganizzazione = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidnomiHostOrganizzazione = StatusEnum.Failed;
      state.nomiHostOrganizzazione = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<NomiHostOrganizzazioni>) => {
      state.statusValidnomiHostOrganizzazione = StatusEnum.Succeeded;
      state.validNomiHostOrganizzazione = state.validNomiHostOrganizzazione.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.nomiHostOrganizzazione = payload;
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusValidnomiHostOrganizzazione = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidnomiHostOrganizzazione = StatusEnum.Failed;
      state.validNomiHostOrganizzazione = [];

    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidnomiHostOrganizzazione = StatusEnum.Succeeded;
      state.validNomiHostOrganizzazione = state.validNomiHostOrganizzazione.filter(el => payload.ids.some(idObj => el[idObj.name as NomiHostOrganizzazioniKeys] !== idObj.id));
    })
  }
});

export const { cleanLookup, reset, resetError } = nomiHostOrganizzazioniSlice.actions;
export default nomiHostOrganizzazioniSlice.reducer;