import { Column } from "@material-table/core";
import { Box, Button, createStyles, Grid, IconButton, makeStyles, Modal, Paper, Theme } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import i18n from "../../../i18n";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import { Fields } from "../../../models/Fields";
import { allFields, elementIdProps, elementRenderProps, OrganizzazioneServerSMTP, OrganizzazioneServerSMTPKeys } from "../../../models/Organizzazione";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { fetchAll, insert, update, physicalDel } from "../../../store/slices/organizzazioneSlice";
import { componentInsertPath, componentTabsPath } from "../../../utils/innerFuncPaths";
import { organizzazioniPath } from "../../../utils/utilconst";
import GeneralForm, { ChecksCallbackReturn, OptionalArgs, } from "../forms/GeneralForm";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import { ExportType } from "../../../utils/utildata";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 20,
      maxWidth: 1000,
      marginLeft: 'auto',
      marginRight: 'auto',
      overflow: 'auto',
    },
    paper: {
      maxHeight: '85vh'
    },
    image: {
      height: 200,
    }
  }),
);

const OrganizzazioniW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);

  const classes = useStyles();

  const abilitazione = useAppSelector(state => getAbilitazione(state, organizzazioniPath));

  const [obj, setObj] = useState<OrganizzazioneServerSMTP | null>(null);

  const insertCallback = () => {
    history.push(organizzazioniPath + componentInsertPath);
  };

  const updateDetailCallback = (organizationInfo: OrganizzazioneServerSMTP) => {
    setObj(organizationInfo)
    history.push(organizzazioniPath + componentTabsPath)
  }

  const [logo, setLogo] = useState<string>()
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleClose = () => {
    setIsOpen(false);
    setLogo(undefined);
  }

  const handleOpen = () => {
    setIsOpen(true);
  }

  const resetError = useCallback(() => {
    /**
     * dispatch delle chiamate per resettare gli errori
     */
  }, []);

  const title = t('organizationParams');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<OrganizzazioneServerSMTP>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<OrganizzazioneServerSMTP> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ? f.removable : !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort ? f.sort : "asc",
          emptyValue: f.defaultValue ?? 'N/A',
        }

        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as OrganizzazioneServerSMTPKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }

        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }

        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        if (f.type && f.type === "image") {
          obj.render = (rowData: OrganizzazioneServerSMTP) => {
            const handleclick = () => {
              setLogo(rowData?.logoOrganizzazione)
              handleOpen()
            }
            return <Button
              onClick={handleclick}
              size='small'
              variant='outlined'
              color='primary'>
              {t('show')}
            </Button>
          }
        }

        return obj;
      })
    )
  }, [allFieldsState, excludedFieldInTable, t]);

  const validOrganizzazioni = useAppSelector(state => state.organizzazioni.validContratti);
  const statusValidOrganizzazioni = useAppSelector(state => state.organizzazioni.statusValidOrganizzazione);

  const errorBE = useAppSelector(state => state.organizzazioni.error)

  const [data, setData] = useState<Array<OrganizzazioneServerSMTP>>([]);

  const formCallback = useCallback((formObject: OrganizzazioneServerSMTP | null, field: string | boolean, optionalArgs: OptionalArgs<OrganizzazioneServerSMTP>): ChecksCallbackReturn | void => {
    const { setFields, } = optionalArgs;

    if (formObject) {
      const SMTPServiceName = 'smtpServiceName';
      const SMTPHost = 'smtpHost';
      const SMTPPort = 'smtpPort';
      const SMTPAccountName = 'smtpAccountName';
      const SMTPAccountPassword = 'smtpAccountPassword';

      if (formObject[SMTPServiceName] || formObject[SMTPHost] || formObject[SMTPPort]) {
        setFields(state => {
          return state.map(elem => {
            if ([SMTPServiceName, SMTPHost, SMTPPort].includes(elem.field)) {
              return {
                ...elem,
                required: true,
              }
            } else return elem;
          })
        })
      }

      if (formObject[SMTPAccountName] || formObject[SMTPAccountPassword]) {
        setFields(state => {
          return state.map(elem => {
            if ([SMTPAccountName, SMTPAccountPassword].includes(elem.field)) {
              return {
                ...elem,
                required: true,
              }
            } else return elem;
          })
        })
      }
    }
  }, []);

  useEffect(() => {
    setData(validOrganizzazioni as OrganizzazioneServerSMTP[]);
  }, [validOrganizzazioni]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  return (
    <>

      <Switch>
        <Route path={organizzazioniPath} exact>
          <CrudMaterialTableWithoutLogicDelete
            abilitazione={abilitazione}
            title={title}
            columns={columns}
            data={data}
            columnsButton={true}
            elementIdProps={elementIdProps}
            elementRenderProps={elementRenderProps}
            fetchAllValid={fetchAll}
            statusValid={statusValidOrganizzazioni}
            errorBE={errorBE}
            logoUri={logoUri}
            resetErrorCallback={resetError}
            insertCallback={insertCallback}
            updateCallback={updateDetailCallback}
            detailCallback={updateDetailCallback}
            exportType={ExportType.NONE}
            extraOptions={{
              maxBodyHeight: 460,
            }}
            physicalDel={physicalDel} />

        </Route>
        <Route path={organizzazioniPath + componentInsertPath} exact>
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={organizzazioniPath}
            tabsUri={organizzazioniPath + componentInsertPath}
            tabsView={false}
            buttonTitle={t("organizationParams")}
            info1={t("")}
            tabs={[
              {
                label: t(""),
                tabPath: "",
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={organizzazioniPath}
                        action={insert}
                        status={statusValidOrganizzazioni}
                        error={errorBE}
                        update={false}
                        obj={{}}
                        fields={allFieldsState}
                        checksCallback={formCallback}
                        translate={t}
                      />
                    </Grid>

                  </Grid>
                ),
                componentElse: <Redirect to={organizzazioniPath} />,
              },
            ]}
          />

        </Route>
        <Route path={organizzazioniPath + componentTabsPath} exact>
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={organizzazioniPath}
            tabsUri={organizzazioniPath + componentTabsPath}
            tabsView={false}
            buttonTitle={t("organizationParams")}
            info1={t("")}
            tabs={[
              {
                label: t("organizationParams"),
                tabPath: "",
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={organizzazioniPath}
                        action={update}
                        status={statusValidOrganizzazioni}
                        error={errorBE}
                        update={true}
                        obj={obj}
                        checksCallback={formCallback}
                        fields={allFieldsState}
                        translate={t}
                      />
                    </Grid>

                  </Grid>
                ),
                componentElse: <Redirect to={organizzazioniPath} />,
              },
            ]}
          />

        </Route>
      </Switch>
      <Modal
        className={clsx(classes.container)}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper className={clsx(classes.paper)}>
          <Grid container>
            <Grid item xs={9} />
            <Grid item xs={3}>
              <Box display='flex' justifyContent='right'>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>

            </Grid>
          </Grid>
          <Box
            display='flex'
            justifyContent='center'
            minWidth={800}
            maxWidth={800}
            mx='auto'
            p={10}
          >

            <img className={clsx(classes.image)}
              src={logo?.startsWith('data')
                ? logo
                : ![null, undefined, ''].includes(logo)
                  ? 'data:image/png;base64,' + logo
                  : ''
              }

              alt={t('logo')}
            />
          </Box>
        </Paper>
      </Modal>
    </>

  )
}
export default OrganizzazioniW;

