import { Column } from "@material-table/core";
import { Grid, } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import i18n from "../../../i18n";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import { Fields } from "../../../models/Fields";
import { allFields, elementIdProps, elementRenderProps, NomiHost, NomiHostKeys } from "../../../models/NomiHost";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { fetchAll, insert, update, physicalDel, resetError as resetErrorNomiHost } from "../../../store/slices/nomiHostSlice";
import { componentInsertPath, componentTabsPath } from "../../../utils/innerFuncPaths";
import { nomiHostPath, nomiHostOrganizzazioniPath } from "../../../utils/utilconst";
import GeneralForm from "../forms/GeneralForm";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import { Autenticazione2FALookup, ExportType } from "../../../utils/utildata";
import NomiHostOrganizzazioniW from "./NomiHostOrganizzazioniW";

const NomiHostW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);

  const abilitazione = useAppSelector(state => getAbilitazione(state, nomiHostPath));

  const [obj, setObj] = useState<NomiHost | null>(null);

  const insertCallback = () => {
    history.push(nomiHostPath + componentInsertPath);
  };

  const updateDetailCallback = (nomiHost: NomiHost) => {
    setObj(nomiHost)
    history.push(nomiHostPath + componentTabsPath)
  }

  const formLookups = useMemo(() => {
    return {
      autenticazione2FA: Autenticazione2FALookup,
    };
  }, [])

  const title = t('nameHostParam');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<NomiHost>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<NomiHost> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ? f.removable : !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort ? f.sort : "asc",
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as NomiHostKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        if (f.lookupField) {
          switch (f.field) {
            case 'autenticazione2FA':
              obj.lookup = Autenticazione2FALookup
              break;
          }
        }

        return obj;
      })
    )
  }, [allFieldsState, excludedFieldInTable, t]);

  const validNomiHost = useAppSelector(state => state.nomiHost.validNomiHost);
  const statusValidNomiHost = useAppSelector(state => state.nomiHost.statusValidNomiHost);

  const errorBE = useAppSelector(state => state.nomiHost.error)

  const resetError = useCallback(() => {
    dispatch(resetErrorNomiHost());
  }, [dispatch]);

  const [data, setData] = useState<Array<object>>([]);

  useEffect(() => {
    setData(validNomiHost);
  }, [validNomiHost]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  return (
    <>
      <Switch>
        <Route path={nomiHostPath} exact>
          <CrudMaterialTableWithoutLogicDelete
            abilitazione={abilitazione}
            title={title}
            columns={columns}
            data={data}
            elementIdProps={elementIdProps}
            elementRenderProps={elementRenderProps}
            fetchAllValid={fetchAll}
            statusValid={statusValidNomiHost}
            errorBE={errorBE}
            logoUri={logoUri}
            resetErrorCallback={resetError}
            insertCallback={insertCallback}
            updateCallback={updateDetailCallback}
            detailCallback={updateDetailCallback}
            exportType={ExportType.NONE}
            extraOptions={{
              maxBodyHeight: 460,
            }}
            physicalDel={physicalDel} />
        </Route>
        <Route path={nomiHostPath + componentInsertPath} >
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={nomiHostPath}
            tabsUri={nomiHostPath + componentInsertPath}
            tabsView={true}
            buttonTitle={t("nameHostParam")}
            info1={t("")}
            tabs={[
              {
                label: t("nameHostParam"),
                tabPath: nomiHostPath,
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={nomiHostPath}
                        action={insert}
                        status={statusValidNomiHost}
                        error={errorBE}
                        update={false}
                        obj={{
                          "spidAbilitato": 0,
                          "autenticazione2FA": 0
                        }}
                        fields={allFieldsState}
                        lookups={formLookups}
                        translate={t}
                      />
                    </Grid>
                  </Grid>
                ),
                componentElse: <Redirect to={nomiHostPath} />,
              },
            ]}
          />
        </Route>
        <Route path={nomiHostPath + componentTabsPath} >
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={nomiHostPath}
            tabsUri={nomiHostPath + componentTabsPath}
            tabsView={true}
            buttonTitle={t("nameHostParam")}
            info1={t("")}
            tabs={[
              {
                label: t("nameHostParam"),
                tabPath: nomiHostPath,
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={nomiHostPath}
                        action={update}
                        status={statusValidNomiHost}
                        error={errorBE}
                        update={true}
                        obj={obj}
                        fields={allFieldsState}
                        lookups={formLookups}
                        translate={t}
                      />
                    </Grid>

                  </Grid>
                ),
                componentElse: <Redirect to={nomiHostPath} />,
              },
              {
                label: t("organizationParams"),
                tabPath: nomiHostOrganizzazioniPath,
                abilitazione: Abilitazione.READ,
                componentIf: obj?.idNomeHost && obj?.nomeHost &&
                  <NomiHostOrganizzazioniW
                    idNomeHost={obj.idNomeHost}
                    nomeHost={obj.nomeHost}
                  />,
                componentElse: <Redirect to={nomiHostPath} />
              }
            ]}
          />
        </Route>
      </Switch>
    </>
  )
}

export default NomiHostW;