import { Fields } from "./Fields";

export type ApplicazioniSoftwareProfiliFunzioniKeys = keyof ApplicazioniSoftwareProfiliFunzioni;

export const elementIdProps: ApplicazioniSoftwareProfiliFunzioniKeys[] = ["idApplicazioneSoftware", "idApplicazioneSoftwareProfilo", "idApplicazioneSoftwareFunzione"];
export const elementRenderProps: ApplicazioniSoftwareProfiliFunzioniKeys[] = ["applicazioneSoftwareProfiloDescrizione"];

export const allFieldsFunzione: Fields[] = [
    {
        field: "applicazioneSoftwareDescrizione", type: 'string', titleKey: "applicationSoftwareDescription", required: true, show: true,
    },
    {
        field: "applicazioneSoftwareFunzioneDescrizione", type: 'string', titleKey: "applicazioneSoftwareFunctionDescription", required: true, show: true,
    },
    {
        field: "applicazioneSoftwareProfiloDescrizione", type: 'string', titleKey: "applicazioneSoftwareProfiloDescription", required: false, show: true, showOn: 'table',
    },
    {
        field: "idApplicazioneSoftwareProfilo", titleKey: "applicazioneSoftwareProfiloDescription", required: true, show: true, lookupField: true, showOn: 'form', editable: "always"
    },
    {
        field: "homepage", type: 'boolean', titleKey: "homePage", required: false, show: true,
    },
    {
        field: "nascosto", type: 'boolean', titleKey: "hiddenParam", required: false, show: true,
    },
    {
        field: "abilitazioni", titleKey: "enablinngParam", required: true, show: true, lookupField: true,
    },
];

export const allFieldsProfilo: Fields[] = [
    {
        field: "applicazioneSoftwareDescrizione", type: 'string', titleKey: "applicationSoftwareDescription", required: true, show: true,
    },
    {
        field: "applicazioneSoftwareProfiloDescrizione", type: 'string', titleKey: "applicazioneSoftwareProfiloDescription", required: false, show: true,
    },
    {
        field: "applicazioneSoftwareFunzioneDescrizione", type: 'string', titleKey: "applicazioneSoftwareFunctionDescription", required: false, show: true, showOn: 'table',
    },
    {
        field: "idApplicazioneSoftwareFunzione", titleKey: "applicazioneSoftwareFunctionDescription", required: true, show: true, lookupField: true, showOn: 'form', editable: "always"
    },
    {
        field: "homepage", type: 'boolean', titleKey: "homePage", required: false, show: true,
    },
    {
        field: "nascosto", type: 'boolean', titleKey: "hiddenParam", required: false, show: true,
    },
    {
        field: "abilitazioni", titleKey: "enablinngParam", required: true, show: true, lookupField: true,
    },
];

export interface ApplicazioniSoftwareProfiliFunzioni {
    idApplicazioneSoftwareProfilo: number,
    applicazioneSoftwareProfiloDescrizione: string,
    idApplicazioneSoftware: number,
    applicazioneSoftwareDescrizione: string,
    idApplicazioneSoftwareFunzione: number,
    applicazioneSoftwareFunzioneDescrizione: string,
    abilitazioni: number,
    homepage: boolean,
    nascosto: boolean,
    version: number
}