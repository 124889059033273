import { Fields } from "./Fields";

export type ApplicazioniSoftwareFunzioniGuidaKeys = keyof ApplicazioniSoftwareFunzioniGuida;

export const elementIdProps: ApplicazioniSoftwareFunzioniGuidaKeys[] = ["idApplicazioneSoftware", "idApplicazioneSoftwareFunzione", "lingua"];
export const elementRenderProps: ApplicazioniSoftwareFunzioniGuidaKeys[] = ["testo"];

export const allFields: Fields[] = [
  {
    field: "lingua", type: 'string', titleKey: "lingua", required: true, show: true, editable: "always", colsNum: 2
  },
  {
    field: "testo", type: 'string', titleKey: "testo", required: true, show: true, isTextArea: true, isRichText: true, colsNum: 12
  },
];

export interface ApplicazioniSoftwareFunzioniGuida {
  idApplicazioneSoftware: number,
  idApplicazioneSoftwareFunzione: number,
  lingua: string,
  testo: string,
  version: number,
}