import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import i18n from "../i18n";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { fetchById as fetchApplicazioneSoftware } from "../store/slices/applicazioniSoftwareSlice";
import { fetchRefreshToken, logoutAction, setCookieOrganizationAction, setSessionProfilAction, setLogoUri, fetchOrganizzazioneInfo } from "../store/slices/authInfoSlice";
import { clearFunzionalita, fetchFunzionalita } from "../store/slices/funzionalitaSlice";
import { cookieCheckTime, cookieOrg, cookiePortal, cookieProfil, forbiddenPath, localStorageKeyLoggedApp, localStorageValueLoggedApp, refreshTokenTime, sessionLogo } from "../utils/utilconst";
import { getCookie } from "../utils/utilfunctions";

export const useApp = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [timer2, setTimer2] = useState<NodeJS.Timeout>();
  const [hasIdAppSoftwareCookie, setHasIdAppSoftwareCookie] = useState<boolean>(false);

  const organization = useAppSelector(state => state.authInfo.organization);
  const idProfil = useAppSelector(state => state.authInfo.idProfil);
  const lingua = useAppSelector(state => state.guida.lingua);

  const token = useAppSelector(state => state.authInfo.token);
  const homeUri = useAppSelector(state => state.funzionalita.homeuri);
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const nomeOrganizzazione = useAppSelector(state => state.authInfo.organizationName);
  const appSoftware = useAppSelector(state => state.applicazioniSoftware.ApplicazioniSoftware);

  useEffect(() => {
    if (!hasIdAppSoftwareCookie) {
      const profil = getCookie(cookieProfil);
      const token = getCookie(cookiePortal);

      if (profil || token) {
        setHasIdAppSoftwareCookie(true);
      } else if (!window.location.pathname.includes(forbiddenPath)) {
        history.replace(forbiddenPath);
      }
    }
  }, [hasIdAppSoftwareCookie, history]);

  /**
   * Aggiorna title della pagina
   */
  useEffect(() => {
    document.title = appSoftware?.descrizione ?? '';
  }, [appSoftware?.descrizione]);

  useEffect(() => {
    const favIcon = document?.getElementById('favicon') as HTMLAnchorElement;
    if (favIcon && appSoftware?.favIcon)
      favIcon.href = appSoftware.favIcon
  }, [appSoftware?.favIcon])

  useEffect(() => {
    if (!hasIdAppSoftwareCookie) return;

    window.localStorage.setItem(localStorageKeyLoggedApp, localStorageValueLoggedApp);
    window.onstorage = () => {
      let r = window.localStorage.getItem(localStorageKeyLoggedApp);
      if (r !== localStorageValueLoggedApp) {
        dispatch(logoutAction());
      }
    };
    return () => { window.onstorage = null };
  }, [dispatch, hasIdAppSoftwareCookie]);

  /*Se è presente un jwt effettua il controllo della presenza di questo anche nei cookie ogni 3 secondi*/
  useEffect(() => {
    if (!hasIdAppSoftwareCookie) return;

    setTimer2(
      setInterval(function () {
        const cookieToken = getCookie(cookiePortal);
        if (!cookieToken) dispatch(logoutAction());
      }, cookieCheckTime)
    );
  }, [dispatch, hasIdAppSoftwareCookie]);

  useEffect(() => {
    return () => {
      clearInterval(timer2);
    }
  }, [timer2])

  useEffect(() => {
    if (!hasIdAppSoftwareCookie) return;

    const logo = sessionStorage.getItem(sessionLogo);
    if (!nomeOrganizzazione) {
      const _cookiePortal = getCookie(cookiePortal);
      if (_cookiePortal)
        dispatch(fetchOrganizzazioneInfo({
          IDOrg: Number(getCookie(cookieOrg)),
          token: _cookiePortal,
        }));
    }
    else if (logo)
      dispatch(setLogoUri(logo));
  }, [dispatch, hasIdAppSoftwareCookie, nomeOrganizzazione])

  useEffect(() => {
    if (!hasIdAppSoftwareCookie) return;

    if (token === null) {
      dispatch(clearFunzionalita());
    }
  }, [token, dispatch, hasIdAppSoftwareCookie]);

  useEffect(() => {
    if (!hasIdAppSoftwareCookie) return;

    if (!organization)
      dispatch(setCookieOrganizationAction());
  }, [organization, dispatch, hasIdAppSoftwareCookie]);

  useEffect(() => {
    if (hasIdAppSoftwareCookie) {
      if (!idProfil || getCookie(cookieProfil))
        dispatch(setSessionProfilAction());
    }
  }, [idProfil, dispatch, hasIdAppSoftwareCookie]);

  useEffect(() => {
    let num: number | null = null;

    if (!hasIdAppSoftwareCookie) return;

    if (token && idProfil) {
      num = Axios.interceptors.request.use((config: AxiosRequestConfig) => {
        return {
          ...config,
          headers: {
            ...config.headers,
            'Authorization': 'Bearer ' + token,
            'IdOrganizzazione': organization,
            'lang': i18n.language,
          }
        };
      });
      dispatch(fetchFunzionalita({ idAppSoft: idProfil[0], idAppSoftProfil: idProfil[1] }))

      dispatch(fetchApplicazioneSoftware({
        idappsoftware: Number(idProfil[0]),
      }));
    }

    return () => {
      if (null !== num)
        Axios.interceptors.request.eject(num);
    }
  }, [token, organization, idProfil, dispatch, lingua, hasIdAppSoftwareCookie]);


  const translation = useAppSelector(state => state.applicazioniSoftware.translation);

  useEffect(() => {
    if (!hasIdAppSoftwareCookie) return;

    if (Object.keys(translation).length > 0) {
      /**
       * Aggiornare tutte le translation di tutte le lingue
       */
      Object.keys(translation).forEach(lang => {
        i18n.addResourceBundle(lang, 'translation', translation[lang], true, true)
      })
    }
  }, [hasIdAppSoftwareCookie, translation])

  useEffect(() => {
    if (!hasIdAppSoftwareCookie) return;

    setTimer(t => {
      t && clearInterval(t);
      return setInterval(() => {
        dispatch(fetchRefreshToken());
      }, refreshTokenTime)
    });
  }, [token, dispatch, hasIdAppSoftwareCookie]);

  useEffect(() => {
    return () => {
      clearInterval(timer);
    }
  }, [timer]);

  useEffect(() => {
    if (!hasIdAppSoftwareCookie) return;

    Axios.interceptors.response.use((response: AxiosResponse) => {
      return response;
    }, function (error: AxiosError) {
      const message = (error.response && error.response.data.messaggio) ? error.response.data.messaggio : error.message;
      return Promise.reject(message);
    });
  }, [dispatch, hasIdAppSoftwareCookie]);

  return { homeUri, logoUri, nomeOrganizzazione, hasIdAppSoftwareCookie };
}