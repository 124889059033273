import { Button, createStyles, Dialog, Grid, IconButton, makeStyles, ThemeProvider, Typography } from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles';
import { red } from "@material-ui/core/colors";
import CloseIcon from '@material-ui/icons/Close';
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";


const redSecondaryTheme = createTheme({
  palette: {
    secondary: red,
  },
});

const useStyles = makeStyles((theme) =>
  createStyles({
    padding: {
      padding: 24
    },
    closeButton: {
      position: 'absolute',
      right: 24,
      top: 24,
      color: theme.palette.grey[500],
    }
  })
);

interface DialogConfirmProps {
  open: {
    isOpen: boolean,
    setIsOpen: Dispatch<SetStateAction<boolean>>
  },
  clearErrorMessage?: () => void,
  setResult: Dispatch<SetStateAction<boolean>>,
  title?: string,
  body: string
}

export default function DialogConfirm(props: DialogConfirmProps) {
  const { open, title, body, setResult, clearErrorMessage } = props;
  const { isOpen, setIsOpen } = open;

  const classes = useStyles();
  const { t } = useTranslation();
 

  const backButtonYesClick = async () => {
    handleClose();
    setResult(true);
  };

  const backButtonNoClick = async () => {
    handleClose();
    setResult(false);
  };

  const handleClose = () => {
    clearErrorMessage && clearErrorMessage();
    setIsOpen(false);
  };

  return (
    <ThemeProvider theme={redSecondaryTheme}>
      <Dialog onClose={backButtonNoClick} aria-labelledby="goback-dialog-title" open={isOpen}>
        <div className={classes.padding}>
          <IconButton size="small" aria-label="close" className={classes.closeButton} onClick={backButtonNoClick}>
            <CloseIcon />
          </IconButton>
          <Grid container justifyContent="space-between" alignItems="flex-end" spacing={2}>
            {
              title &&
              <Grid item xs={12}>
                <Typography variant="h5" id="goback-dialog-title">
                  {title}
                </Typography>
              </Grid>
            }
            <Grid item xs={12}>
              <Typography color="secondary" variant="h6" id="goback-dialog-title">
                {body}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="contained" onClick={backButtonNoClick}>{t('no')}</Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="contained" color="secondary" onClick={backButtonYesClick}>{t('yes')}</Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </ThemeProvider>
  );
}

