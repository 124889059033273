import { Fields } from "./Fields";

export type ApplicazioniSoftwareFunzioniOperazioniKeys = keyof ApplicazioniSoftwareFunzioniOperazioni;

export const elementIdProps: ApplicazioniSoftwareFunzioniOperazioniKeys[] = ["idApplicazioneSoftware", "idApplicazioneSoftwareFunzione", "abilitazioni"];
export const elementRenderProps: ApplicazioniSoftwareFunzioniOperazioniKeys[] = ["applicazioneSoftwareFunzioneDescrizione"];

export const allFields: Fields[] = [
  {
    field: "applicazioneSoftwareDescrizione", type: 'string', titleKey: "applicationSoftwareDescription", required: true, show: true, editable: "never"
  },
  {
    field: "applicazioneSoftwareFunzioneDescrizione", type: 'string', titleKey: "applicazioneSoftwareFunctionDescription", required: true, show: true, editable: "never"
  },
  {
    field: "abilitazioni", titleKey: "enablinngParam", required: true, show: true, lookupField: true,
  },
];

export interface ApplicazioniSoftwareFunzioniOperazioni {
  idApplicazioneSoftware: number,
  applicazioneSoftwareDescrizione: string,
  idApplicazioneSoftwareFunzione: number,
  applicazioneSoftwareFunzioneDescrizione: string,
  abilitazioni: number,
  version: number,
}