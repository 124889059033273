import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, getAll, getAllByIds, upd } from '../../services/services.service'
import { ApplicazioniSoftwareFunzioniOperazioni, ApplicazioniSoftwareFunzioniOperazioniKeys, elementIdProps } from '../../models/ApplicazioniSoftwareFunzioniOperazioni';
import { createLookup } from '../../utils/utilfunctions';

const microservice = "applicazionisoftwarefunzionioperazioni";
const entity = "applicazionisoftwarefunzionioperazioni";

interface applicazioniSoftwareFunzioniOperazioniState {
  statusValidapplicazioniSoftwareFunzioniOperazioni: StatusEnum,
  validApplicazioniSoftwareFunzioniOperazioni: ApplicazioniSoftwareFunzioniOperazioni[],
  ApplicazioniSoftwareFunzioniOperazioni: ApplicazioniSoftwareFunzioniOperazioni | null,
  lookup: Lookup,
  error: string | null
}

const initialState: applicazioniSoftwareFunzioniOperazioniState = {
  statusValidapplicazioniSoftwareFunzioniOperazioni: StatusEnum.Succeeded,
  validApplicazioniSoftwareFunzioniOperazioni: [],
  ApplicazioniSoftwareFunzioniOperazioni: null,
  lookup: {},
  error: null
}

// @GetMapping(value = "/cerca/applicazionisoftwarefunzionioperazioni/all/{idApplicazioneSoftware}/{idApplicazioneSoftwareFunzione}")
export const fetchById = createAsyncThunk(microservice + '/fetchById', async (obj: { idApplicazioneSoftware: number, idApplicazioneSoftwareFunzione: number }) => {
  const ids = [obj.idApplicazioneSoftware, obj.idApplicazioneSoftwareFunzione]
  const response = await getAllByIds(microservice, entity, ids);
  return response.data as ApplicazioniSoftwareFunzioniOperazioni[];
});

// @GetMapping("cerca/applicazionisoftwarefunzionioperazioni/all")
export const fetchAll = createAsyncThunk(microservice + '/fetchAll', async () => {
  const response = await getAll(microservice, entity,);
  return response.data as ApplicazioniSoftwareFunzioniOperazioni[];
});

// inserisci/applicazionisoftwarefunzionioperazioni
export const insert = createAsyncThunk(microservice + '/insert', async (appsoftware: ApplicazioniSoftwareFunzioniOperazioni) => {
  const response = await create(appsoftware, microservice, entity);
  return response.data as ApplicazioniSoftwareFunzioniOperazioni;
});

// modifica/applicazionisoftwarefunzionioperazioni
export const update = createAsyncThunk(microservice + '/update', async (appsoftware: ApplicazioniSoftwareFunzioniOperazioni) => {
  const response = await upd(appsoftware, microservice, entity);
  return response.data as ApplicazioniSoftwareFunzioniOperazioni;
});

// delete/applicazionisoftwarefunzionioperazioni/id/
export const physicalDel = createAsyncThunk(microservice + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const applicazioniSoftwareFunzioniOperazioniSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    cleanLookup: (state: applicazioniSoftwareFunzioniOperazioniState) => {
      state.lookup = {};
    },
    reset: (state: applicazioniSoftwareFunzioniOperazioniState) => {
      return initialState;
    },
    resetError: (state: applicazioniSoftwareFunzioniOperazioniState) => {
      state.error = initialState.error;
      state.statusValidapplicazioniSoftwareFunzioniOperazioni = initialState.statusValidapplicazioniSoftwareFunzioniOperazioni;
    }
  },
  extraReducers: builder => {

    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidapplicazioniSoftwareFunzioniOperazioni = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioniSoftwareFunzioniOperazioni = StatusEnum.Failed;
      state.validApplicazioniSoftwareFunzioniOperazioni = [];
      state.lookup = {};
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<ApplicazioniSoftwareFunzioniOperazioni[]>) => {
      state.statusValidapplicazioniSoftwareFunzioniOperazioni = StatusEnum.Succeeded;
      state.validApplicazioniSoftwareFunzioniOperazioni = payload ?? [];
      state.lookup = createLookup(payload, "idApplicazioneSoftwareFunzione", ["applicazioneSoftwareFunzioneDescrizione"])
    })

    // fetchAll
    builder.addCase(fetchAll.pending, (state) => {
      state.statusValidapplicazioniSoftwareFunzioniOperazioni = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioniSoftwareFunzioniOperazioni = StatusEnum.Failed;
      state.validApplicazioniSoftwareFunzioniOperazioni = [];
      state.lookup = {};
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<ApplicazioniSoftwareFunzioniOperazioni[]>) => {
      state.statusValidapplicazioniSoftwareFunzioniOperazioni = StatusEnum.Succeeded;
      state.validApplicazioniSoftwareFunzioniOperazioni = payload ?? [];
      state.lookup = createLookup(payload, "idApplicazioneSoftwareFunzione", ["applicazioneSoftwareFunzioneDescrizione"])
    })

    //   // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidapplicazioniSoftwareFunzioniOperazioni = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioniSoftwareFunzioniOperazioni = StatusEnum.Failed;
      state.validApplicazioniSoftwareFunzioniOperazioni = [];
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<ApplicazioniSoftwareFunzioniOperazioni>) => {
      state.statusValidapplicazioniSoftwareFunzioniOperazioni = StatusEnum.Succeeded;
      state.ApplicazioniSoftwareFunzioniOperazioni = payload;
      state.validApplicazioniSoftwareFunzioniOperazioni = state.validApplicazioniSoftwareFunzioniOperazioni.concat([payload]);
    })

    //   // update
    builder.addCase(update.pending, (state) => {
      state.statusValidapplicazioniSoftwareFunzioniOperazioni = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioniSoftwareFunzioniOperazioni = StatusEnum.Failed;
      state.ApplicazioniSoftwareFunzioniOperazioni = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<ApplicazioniSoftwareFunzioniOperazioni>) => {
      state.statusValidapplicazioniSoftwareFunzioniOperazioni = StatusEnum.Succeeded;
      state.validApplicazioniSoftwareFunzioniOperazioni = state.validApplicazioniSoftwareFunzioniOperazioni.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.ApplicazioniSoftwareFunzioniOperazioni = payload;
    })

    //   // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusValidapplicazioniSoftwareFunzioniOperazioni = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioniSoftwareFunzioniOperazioni = StatusEnum.Failed;
      state.validApplicazioniSoftwareFunzioniOperazioni = [];
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidapplicazioniSoftwareFunzioniOperazioni = StatusEnum.Succeeded;
      state.validApplicazioniSoftwareFunzioniOperazioni = state.validApplicazioniSoftwareFunzioniOperazioni.filter(el => payload.ids.some(idObj => el[idObj.name as ApplicazioniSoftwareFunzioniOperazioniKeys] !== idObj.id));
    })
  }
});

export const { cleanLookup, reset, resetError } = applicazioniSoftwareFunzioniOperazioniSlice.actions;
export default applicazioniSoftwareFunzioniOperazioniSlice.reducer;