import { configureStore } from '@reduxjs/toolkit'
import { authInfoSlice } from './slices/authInfoSlice';
import { routingSlice } from './slices/routingSlice';
import { dialogsSlice } from './slices/dialogsSlice';
import { snackbarsSlice } from './slices/snackBarSlice';
import { funzionalitaSlice } from './slices/funzionalitaSlice';
import { appbarSlice } from './slices/appbarSlice';
import { guidaSlice } from './slices/guidaSlice';
import {organizzazioneSlice} from './slices/organizzazioneSlice';
import {contrattiSlice} from './slices/contrattiSlice';
import {applicazioneSoftwareSlice} from './slices/applicazioniSoftwareSlice';
import {applicazioneSoftwareProfiloSlice} from './slices/applicazioniSoftwareProfiliSlice';
import {applicazioniSoftwareFunzioniSlice}  from './slices/applicazioniSoftwareFunzioniSlice';
import {utentiSlice} from './slices/utentiSlice';
import { utentiAbilitazioniSlice } from './slices/utentiAbilitazioniSlice';
import {applicazioniSoftwareProfiliFunzioniSlice} from './slices/applicazioniSoftwareProfiliFunzioniSlice';
import {applicazioniSoftwareFunzioniOperazioniSlice} from './slices/applicazioniSoftwareFunzioniOperazioniSlice';
import {applicazioniSoftwareFunzioniGuidaSlice} from './slices/applicazioniSoftwareFunzioniGuidaSlice';
import { nomiHostSlice } from './slices/nomiHostSlice';
import {nomiHostOrganizzazioniSlice} from './slices/nomiHostOrganizzazioniSlice';
import {funzioniComponentiSlice} from './slices/funzioniComponentiSlice';
import {campiComponentiSlice} from './slices/campiComponentiSlice';
import {componentiFunzioniSlice} from './slices/componentiFunzioniSlice';
import {dizionarioDatiSlice} from './slices/applicazioniSoftwareDizionarioDatiSlice';
import { lookupSlice } from './slices/lookpuSlice';




const store = configureStore({
  reducer: {
    appbar: appbarSlice.reducer,
    authInfo: authInfoSlice.reducer,
    funzionalita: funzionalitaSlice.reducer,
    routing: routingSlice.reducer,
    dialogs: dialogsSlice.reducer,
    snackbars: snackbarsSlice.reducer,
    guida: guidaSlice.reducer,
    nomiHost : nomiHostSlice.reducer,
    organizzazioni: organizzazioneSlice.reducer,
    contratti: contrattiSlice.reducer,
    applicazioniSoftware: applicazioneSoftwareSlice.reducer,
    applicazioniSoftwareProfilo: applicazioneSoftwareProfiloSlice.reducer,
    dizionarioDati : dizionarioDatiSlice.reducer,
    applicazioniSoftwareFunzioni: applicazioniSoftwareFunzioniSlice.reducer,
    applicazioniSoftwareProfiliFunzioni: applicazioniSoftwareProfiliFunzioniSlice.reducer,
    applicazioniSoftwareFunzioniOperazioni: applicazioniSoftwareFunzioniOperazioniSlice.reducer,
    applicazioniSoftwareFunzioniGuida: applicazioniSoftwareFunzioniGuidaSlice.reducer,
    nomiHostOrganizzazioni : nomiHostOrganizzazioniSlice.reducer,
    funzioniComponenti : funzioniComponentiSlice.reducer,
    campiComponenti : campiComponentiSlice.reducer,
    componentiFunzioni : componentiFunzioniSlice.reducer,
    lookup : lookupSlice.reducer,
    utenti: utentiSlice.reducer,
    utentiAbilitazioni: utentiAbilitazioniSlice.reducer
  }
})
export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch