import Axios from 'axios';
import { portaleBEUrl } from "../utils/utilconst";
import { find } from "../utils/serviceConst";
import { Ids } from "../models/Utils";
import Funz from '../models/Funzione';

export function loadMenu(idAppSoft: string, idAppSoftProf: string) {
  return Axios.get<Funz[]>(portaleBEUrl + "/applicazionisoftwarefunzioni/abilitazioni", {
    headers: {
      "idAppSoft": idAppSoft,
      "idAppSoftProf": idAppSoftProf
    }
  });
}

export function getById(ids: Ids<string>[], microservice: string, entity: string) {
  let call = portaleBEUrl + "/" + microservice + find + "/" + entity + "/id";
  ids.forEach(id => call += ("/" + id.id));
  return Axios.get<Object>(call);
}