import { Column } from "@material-table/core";
import { Box, Button, createStyles, Grid, IconButton, makeStyles, Modal, Paper, Theme } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import i18n from "../../../i18n";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import { Fields } from "../../../models/Fields";
import { allFields, ApplicazioneSoftwareProfilo, ApplicazioneSoftwareProfiloKeys, elementIdProps, elementRenderProps } from "../../../models/ApplicazioniSoftwareProfili";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { insert, update, physicalDel, resetError as resetErrorapplicazioniSoftwareFunzioni, } from "../../../store/slices/applicazioniSoftwareProfiliSlice";
import { componentInsertPath, componentTabsPath } from "../../../utils/innerFuncPaths";
import { applicazioniSoftwareFunzioniProfiliPath, funzioniProfiliPath, } from "../../../utils/utilconst";
import GeneralForm from "../forms/GeneralForm";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import SimpleIdSelects from "../selects/SimpleIdSelects";
import { isUrlRoot } from "../../../utils/utilfunctions";
import { fetchAll as fetchAllApplicazioneSoftware } from "../../../store/slices/applicazioniSoftwareSlice";
import { fetchAllProfiliByIdAppSoftware } from "../../../store/slices/applicazioniSoftwareProfiliSlice";
import ProfiliFunzioniW from "./ProfiliFunzioniW";
import { ExportType } from "../../../utils/utildata";
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 20,
      maxWidth: 1000,
      marginLeft: 'auto',
      marginRight: 'auto',
      overflow: 'auto',
    },
    paper: {
      maxHeight: '85vh'
    },
    image: {
      height: 200,
    }
  }),
);

const ProfiliW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const abilitazione = useAppSelector(state => getAbilitazione(state, applicazioniSoftwareFunzioniProfiliPath));

  const [obj, setObj] = useState<ApplicazioneSoftwareProfilo | null>(null);

  const classes = useStyles();

  const [logo, setLogo] = useState<string>()
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setIsOpen(true);
  }

  const handleClose = () => {
    setIsOpen(false);
    setLogo(undefined);
  }

  const insertCallback = () => {
    history.push(applicazioniSoftwareFunzioniProfiliPath + componentInsertPath);
  };

  const updateDetailCallback = (applicazioniSoftwareFunzioni: ApplicazioneSoftwareProfilo) => {
    setObj(applicazioniSoftwareFunzioni)
    history.push(applicazioniSoftwareFunzioniProfiliPath + componentTabsPath)
  }

  const applicazioniSoftwareFunzioniName = useMemo(() => t("applicationsSoftwareParam"), [t]);
  const applicazioniSoftwareFunzioniLabel = useMemo(() => t("applicationsSoftwareParam"), [t]);

  const applicazioniSoftwareFunzioniLookup = useAppSelector(state => state.applicazioniSoftwareFunzioni.lookup)

  const lookupSoftware = useAppSelector((state) => state.applicazioniSoftware.lookup);

  const [states, setStates] = useState<{ [selectName: string]: number | string | null }>({
    [applicazioniSoftwareFunzioniName]: null,
  });

  useEffect(() => {
    setStates({
      [applicazioniSoftwareFunzioniName]: null,
    });
  }, [applicazioniSoftwareFunzioniName]);

  const formLookups = useMemo(() => {
    return { idApplicazioneSoftwareFunzioneParent: applicazioniSoftwareFunzioniLookup };
  }, [applicazioniSoftwareFunzioniLookup])

  const isCurrentPageNotIndex = !isUrlRoot(history.location.pathname, applicazioniSoftwareFunzioniProfiliPath);

  const fixedProps = useMemo(() => {
    return { idApplicazioneSoftware: states[applicazioniSoftwareFunzioniName] }
  }, [applicazioniSoftwareFunzioniName, states])

  /*chiamata per lookup di ApplicazioneSoftware */
  useEffect(() => {
    dispatch(fetchAllApplicazioneSoftware());
  }, [dispatch]);

  const resetError = useCallback(() => {
    dispatch(resetErrorapplicazioniSoftwareFunzioni())
  }, [dispatch]);

  const title = t('applicationSoftwareProfile');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<ApplicazioneSoftwareProfilo>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<ApplicazioneSoftwareProfilo> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ? f.removable : !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort ? f.sort : "asc",
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as ApplicazioneSoftwareProfiloKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        if (f.type && f.type === "image") {
          obj.render = (rowData: ApplicazioneSoftwareProfilo) => {
            const handleclick = () => {
              setLogo(rowData?.profiloIcona)
              handleOpen()
            }
            return <Button
              onClick={handleclick}
              size='small'
              variant='outlined'
              color='primary'>
              {t('show')}
            </Button>
          }
        }

        return obj;
      })
    )
  }, [allFieldsState, excludedFieldInTable, t]);

  const validApplicazioneSoftwareProfilo = useAppSelector(state => state.applicazioniSoftwareProfilo.validApplicazioneSoftwareProfilo);
  const statusValidapplicazioneSoftwareProfilo = useAppSelector(state => state.applicazioniSoftwareProfilo.statusValidapplicazioneSoftwareProfilo);

  const errorBE = useAppSelector(state => state.applicazioniSoftwareProfilo.error)

  const [data, setData] = useState<Array<object>>([]);

  useEffect(() => {
    setData(validApplicazioneSoftwareProfilo);
  }, [validApplicazioneSoftwareProfilo]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  return (
    <>
      <Paper elevation={2}>
        <Box p={4}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <SimpleIdSelects
                selectsArray={[
                  {
                    name: applicazioniSoftwareFunzioniName,
                    lookup: lookupSoftware,
                    label: applicazioniSoftwareFunzioniLabel,
                    disabled: isCurrentPageNotIndex,
                    colsNum: 4,
                  },
                ]}
                states={states}
                setStates={setStates}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box marginTop={2}>
        {
          states[applicazioniSoftwareFunzioniName] &&
          <Switch>
            <Route path={applicazioniSoftwareFunzioniProfiliPath} exact>
              <CrudMaterialTableWithoutLogicDelete
                abilitazione={abilitazione}
                title={title}
                columns={columns}
                data={data}
                elementIdProps={elementIdProps}
                elementRenderProps={elementRenderProps}
                fetchAllValid={fetchAllProfiliByIdAppSoftware}
                statusValid={statusValidapplicazioneSoftwareProfilo}
                errorBE={errorBE}
                logoUri={logoUri}
                resetErrorCallback={resetError}
                insertCallback={insertCallback}
                updateCallback={updateDetailCallback}
                detailCallback={updateDetailCallback}
                exportType={ExportType.NONE}
                fixedProps={fixedProps}
                extraOptions={{
                  maxBodyHeight: 460,
                }}
                physicalDel={physicalDel} />
            </Route>
            <Route path={applicazioniSoftwareFunzioniProfiliPath + componentInsertPath} exact>
              <InnerComponentViews
                abilitazione={abilitazione}
                mainUri={applicazioniSoftwareFunzioniProfiliPath}
                tabsUri={applicazioniSoftwareFunzioniProfiliPath + componentInsertPath}
                tabsView={false}
                buttonTitle={t("applicationsSoftwareParam")}
                info1={t("")}
                tabs={[
                  {
                    label: t(""),
                    tabPath: "",
                    abilitazione: Abilitazione.READ_UPDATE,
                    componentIf: (
                      <Grid container spacing={2}>
                        <Grid item xs xl>
                          <GeneralForm
                            readOnly={false}
                            language={i18n.language}
                            componentPath={applicazioniSoftwareFunzioniProfiliPath}
                            action={insert}
                            status={statusValidapplicazioneSoftwareProfilo}
                            error={errorBE}
                            update={false}
                            obj={{}}
                            fields={allFieldsState}
                            fixedProps={fixedProps}
                            lookups={formLookups}
                            translate={t}
                          />
                        </Grid>

                      </Grid>
                    ),
                    componentElse: <Redirect to={applicazioniSoftwareFunzioniProfiliPath} />,
                  },
                ]}
              />
            </Route>
            <Route path={applicazioniSoftwareFunzioniProfiliPath + componentTabsPath}>
              <InnerComponentViews
                abilitazione={abilitazione}
                mainUri={applicazioniSoftwareFunzioniProfiliPath}
                tabsUri={applicazioniSoftwareFunzioniProfiliPath + componentTabsPath}
                tabsView={true}
                buttonTitle={t("profileParam")}
                info1={obj?.descrizione ?? ''}
                tabs={[
                  {
                    label: t("profileParam"),
                    tabPath: applicazioniSoftwareFunzioniProfiliPath,
                    abilitazione: Abilitazione.READ_UPDATE,
                    componentIf: (
                      <Grid container spacing={2}>
                        <Grid item xs xl>
                          <GeneralForm
                            readOnly={false}
                            language={i18n.language}
                            componentPath={applicazioniSoftwareFunzioniProfiliPath}
                            action={update}
                            status={statusValidapplicazioneSoftwareProfilo}
                            error={errorBE}
                            update={true}
                            obj={obj}
                            fields={allFieldsState}
                            fixedProps={fixedProps}
                            lookups={formLookups}
                            translate={t}
                          />
                        </Grid>
                      </Grid>
                    ),
                    componentElse: <Redirect to={applicazioniSoftwareFunzioniProfiliPath} />,
                  },
                  {
                    label: t("functionParam"),
                    tabPath: funzioniProfiliPath,
                    abilitazione: Abilitazione.READ,
                    componentIf: obj?.idApplicazioneSoftware && obj?.idApplicazioneSoftwareProfilo && obj?.applicazioneSoftwareDescrizione && obj?.descrizione &&
                      <ProfiliFunzioniW
                        idApplicazioneSoftware={obj.idApplicazioneSoftware}
                        idApplicazioneSoftwareProfilo={obj.idApplicazioneSoftwareProfilo}
                        applicazioneSoftwareDescrizione={obj.applicazioneSoftwareDescrizione}
                        applicazioneSoftwareProfiloDescrizione={obj.descrizione}
                      />,
                    componentElse: <Redirect to={applicazioniSoftwareFunzioniProfiliPath} />
                  },
                ]}
              />
            </Route>
          </Switch>
        }
      </Box>
      <Modal
        className={clsx(classes.container)}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper className={clsx(classes.paper)}>
          <Grid container>
            <Grid item xs={9} />
            <Grid item xs={3}>
              <Box display='flex' justifyContent='right'>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>

            </Grid>
          </Grid>
          <Box
            display='flex'
            justifyContent='center'
            minWidth={800}
            maxWidth={800}
            mx='auto'
            p={10}
          >
            <img className={clsx(classes.image)}
              src={logo?.startsWith('data')
                ? logo
                : ![null, undefined, ''].includes(logo)
                  ? 'data:image/png;base64,' + logo
                  : ''
              }
              alt={t('logo')}
            />
          </Box>
        </Paper>
      </Modal>
    </>
  )
}
export default ProfiliW;

