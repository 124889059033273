import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, getAll, getById, getByIds, upd } from '../../services/services.service'
import { ApplicazioniSoftwareProfiliFunzioni, ApplicazioniSoftwareProfiliFunzioniKeys, elementIdProps } from '../../models/ApplicazioniSoftwareProfiliFunzioni';
import { createLookup } from '../../utils/utilfunctions';

const microservice = "applicazionisoftwarefunzionioperazioni";
const entity = "applicazionisoftwareprofilifunzioni";

interface applicazioniSoftwareProfiliFunzioniState {
  statusValidapplicazioniSoftwareProfiliFunzioni: StatusEnum,
  validApplicazioniSoftwareProfiliFunzioni: ApplicazioniSoftwareProfiliFunzioni[],
  ApplicazioniSoftwareProfiliFunzioni: ApplicazioniSoftwareProfiliFunzioni | null,
  lookup: Lookup,
  error: string | null
}

const initialState: applicazioniSoftwareProfiliFunzioniState = {
  statusValidapplicazioniSoftwareProfiliFunzioni: StatusEnum.Succeeded,
  validApplicazioniSoftwareProfiliFunzioni: [],
  ApplicazioniSoftwareProfiliFunzioni: null,
  lookup: {},
  error: null
}

// @GetMapping(value = "/cerca/applicazionisoftwareprofilifunzioni/{idApplicazioneSoftware}/{idApplicazioneSoftwareFunzione}")
export const fetchProfiliByIds = createAsyncThunk(microservice + '/fetchProfiliByIds', async (obj: { idApplicazioneSoftware: number, idApplicazioneSoftwareFunzione: number }) => {
  const ids = ["funzioni", obj.idApplicazioneSoftware, obj.idApplicazioneSoftwareFunzione]
  const response = await getByIds(microservice, entity, ids);
  return response.data as ApplicazioniSoftwareProfiliFunzioni[];
});

// @GetMapping(value = "/cerca/applicazionisoftwareprofilifunzioni/{idApplicazioneSoftware}/{idApplicazioneSoftwareProfilo}")
export const fetchFunzioniByIds = createAsyncThunk(microservice + '/fetchFunzioniByIds', async (obj: { idApplicazioneSoftware: number, idApplicazioneSoftwareProfilo: number }) => {
  const ids = ["profili", obj.idApplicazioneSoftware, obj.idApplicazioneSoftwareProfilo]
  const response = await getByIds(microservice, entity, ids);
  return response.data as ApplicazioniSoftwareProfiliFunzioni[];
});

// @GetMapping(value = "/cerca/applicazionisoftwareprofilifunzioni/id/{idApplicazioneSoftware}/{idApplicazioneSoftwareProfilo}/{idApplicazioneSoftwareFunzione}")
export const fetchById = createAsyncThunk(microservice + '/fetchById', async (obj: { idApplicazioneSoftware: number, idApplicazioneSoftwareProfilo: number, idApplicazioneSoftwareFunzione: number }) => {
  const ids = [obj.idApplicazioneSoftware, obj.idApplicazioneSoftwareProfilo, obj.idApplicazioneSoftwareFunzione]
  const response = await getById(microservice, entity, ids,);
  return response.data as ApplicazioniSoftwareProfiliFunzioni[];
});

//	@GetMapping("cerca/applicazionisoftwareprofilifunzioni/all")
export const fetchAll = createAsyncThunk(microservice + '/fetchAll', async () => {
  const response = await getAll(microservice, entity,);
  return response.data as ApplicazioniSoftwareProfiliFunzioni[];
});

// inserisci/applicazioniSoftware
export const insert = createAsyncThunk(microservice + '/insert', async (appsoftware: ApplicazioniSoftwareProfiliFunzioni) => {
  const response = await create(appsoftware, microservice, entity);
  return response.data as ApplicazioniSoftwareProfiliFunzioni;
});

// modifica/applicazioniSoftware
export const update = createAsyncThunk(microservice + '/update', async (appsoftware: ApplicazioniSoftwareProfiliFunzioni) => {
  const response = await upd(appsoftware, microservice, entity);
  return response.data as ApplicazioniSoftwareProfiliFunzioni;
});

// delete/applicazioniSoftware/id/{idappsoftware}
export const physicalDel = createAsyncThunk(microservice + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const applicazioniSoftwareProfiliFunzioniSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    cleanLookup: (state: applicazioniSoftwareProfiliFunzioniState) => {
      state.lookup = {};
    },
    reset: (state: applicazioniSoftwareProfiliFunzioniState) => {
      return initialState;
    },
    resetError: (state: applicazioniSoftwareProfiliFunzioniState) => {
      state.error = initialState.error;
      state.statusValidapplicazioniSoftwareProfiliFunzioni = initialState.statusValidapplicazioniSoftwareProfiliFunzioni;
    }
  },
  extraReducers: builder => {
    // fetchProfiliByIds
    builder.addCase(fetchProfiliByIds.pending, (state) => {
      state.statusValidapplicazioniSoftwareProfiliFunzioni = StatusEnum.Loading;
    })
    builder.addCase(fetchProfiliByIds.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioniSoftwareProfiliFunzioni = StatusEnum.Failed;
      state.validApplicazioniSoftwareProfiliFunzioni = [];
      state.lookup = {};
    })
    builder.addCase(fetchProfiliByIds.fulfilled, (state, { payload }: PayloadAction<ApplicazioniSoftwareProfiliFunzioni[]>) => {
      state.statusValidapplicazioniSoftwareProfiliFunzioni = StatusEnum.Succeeded;
      state.validApplicazioniSoftwareProfiliFunzioni = payload ?? [];
      state.lookup = createLookup(payload, "idApplicazioneSoftware", ["descrizione"])
    })

    // fetchFunzioniByIds
    builder.addCase(fetchFunzioniByIds.pending, (state) => {
      state.statusValidapplicazioniSoftwareProfiliFunzioni = StatusEnum.Loading;
    })
    builder.addCase(fetchFunzioniByIds.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioniSoftwareProfiliFunzioni = StatusEnum.Failed;
      state.validApplicazioniSoftwareProfiliFunzioni = [];
      state.lookup = {};
    })
    builder.addCase(fetchFunzioniByIds.fulfilled, (state, { payload }: PayloadAction<ApplicazioniSoftwareProfiliFunzioni[]>) => {
      state.statusValidapplicazioniSoftwareProfiliFunzioni = StatusEnum.Succeeded;
      state.validApplicazioniSoftwareProfiliFunzioni = payload ?? [];
      state.lookup = createLookup(payload, "idApplicazioneSoftware", ["descrizione"])
    })

    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidapplicazioniSoftwareProfiliFunzioni = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioniSoftwareProfiliFunzioni = StatusEnum.Failed;
      state.validApplicazioniSoftwareProfiliFunzioni = [];
      state.lookup = {};
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<ApplicazioniSoftwareProfiliFunzioni[]>) => {
      state.statusValidapplicazioniSoftwareProfiliFunzioni = StatusEnum.Succeeded;
      state.validApplicazioniSoftwareProfiliFunzioni = payload ?? [];
      state.lookup = createLookup(payload, "idApplicazioneSoftware", ["descrizione"])
    })

    // fetchAll
    builder.addCase(fetchAll.pending, (state) => {
      state.statusValidapplicazioniSoftwareProfiliFunzioni = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioniSoftwareProfiliFunzioni = StatusEnum.Failed;
      state.validApplicazioniSoftwareProfiliFunzioni = [];
      state.lookup = {};
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<ApplicazioniSoftwareProfiliFunzioni[]>) => {
      state.statusValidapplicazioniSoftwareProfiliFunzioni = StatusEnum.Succeeded;
      state.validApplicazioniSoftwareProfiliFunzioni = payload ?? [];
      state.lookup = createLookup(payload, "idApplicazioneSoftware", ["descrizione"])
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidapplicazioniSoftwareProfiliFunzioni = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioniSoftwareProfiliFunzioni = StatusEnum.Failed;
      state.validApplicazioniSoftwareProfiliFunzioni = [];
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<ApplicazioniSoftwareProfiliFunzioni>) => {
      state.statusValidapplicazioniSoftwareProfiliFunzioni = StatusEnum.Succeeded;
      state.ApplicazioniSoftwareProfiliFunzioni = payload;
      state.validApplicazioniSoftwareProfiliFunzioni = state.validApplicazioniSoftwareProfiliFunzioni?.concat([payload]) ?? state.validApplicazioniSoftwareProfiliFunzioni;
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidapplicazioniSoftwareProfiliFunzioni = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioniSoftwareProfiliFunzioni = StatusEnum.Failed;
      state.ApplicazioniSoftwareProfiliFunzioni = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<ApplicazioniSoftwareProfiliFunzioni>) => {
      state.statusValidapplicazioniSoftwareProfiliFunzioni = StatusEnum.Succeeded;
      state.validApplicazioniSoftwareProfiliFunzioni = state.validApplicazioniSoftwareProfiliFunzioni.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.ApplicazioniSoftwareProfiliFunzioni = payload;
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusValidapplicazioniSoftwareProfiliFunzioni = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioniSoftwareProfiliFunzioni = StatusEnum.Failed;
      state.validApplicazioniSoftwareProfiliFunzioni = [];
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidapplicazioniSoftwareProfiliFunzioni = StatusEnum.Succeeded;
      state.validApplicazioniSoftwareProfiliFunzioni = state.validApplicazioniSoftwareProfiliFunzioni.filter(el => payload.ids.some(idObj => el[idObj.name as ApplicazioniSoftwareProfiliFunzioniKeys] !== idObj.id));
    })
  }
});

export const { cleanLookup, reset, resetError } = applicazioniSoftwareProfiliFunzioniSlice.actions;
export default applicazioniSoftwareProfiliFunzioniSlice.reducer;