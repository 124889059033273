import { Column } from "@material-table/core";
import { Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import i18n from "../../../i18n";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import { Fields } from "../../../models/Fields";
import { allFields, CampiComponenti, CampiComponentiKeys, elementIdProps, elementRenderProps } from "../../../models/CampiComponenti";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { componentInsertPath, componentTabsPath } from "../../../utils/innerFuncPaths";
import { campiComponentiPath, funzioniComponentiPath } from "../../../utils/utilconst";
import GeneralForm from "../forms/GeneralForm";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import { ExportType, ModificabileLookup, MostraSoloInLookup, TipoCampoLookup } from "../../../utils/utildata";
import { fetchAllValidCampiComponenti, insert, update, physicalDel, } from "../../../store/slices/campiComponentiSlice";

interface CampiComponentiProps {
  idApplicazioneSoftware: number,
  idApplicazioneSoftwareComponente: number,
}

const DettaglioComponentiW = ({ idApplicazioneSoftware, idApplicazioneSoftwareComponente }: CampiComponentiProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const abilitazione = useAppSelector(state => getAbilitazione(state, funzioniComponentiPath));
  const [obj, setObj] = useState<CampiComponenti | null>(null);

  const solaLettura = "solaLettura";
  const lookup = "lookup";
  const etichetta = "etichetta";
  const completamentoAutomatico = "completamentoAutomatico";
  const richiesto = "richiesto";
  const removibile = "removibile";
  const mostra = "mostra";
  const righeMultiple = "righeMultiple";

  const tipoCampi = "tipo";
  const mostraSoloIn = 'mostraSoloIn';
  const editable = "modificabile";

  const insertCallback = () => {
    const initValue: Partial<CampiComponenti> = {
      idApplicazioneSoftware: idApplicazioneSoftware,
      idApplicazioneSoftwareComponente: idApplicazioneSoftwareComponente,
    };

    setObj(initValue as CampiComponenti);

    history.push(funzioniComponentiPath + componentTabsPath + campiComponentiPath + componentInsertPath);
  };

  const updateCallback = (campiComponenti: CampiComponenti) => {
    setObj(campiComponenti)
    history.push(funzioniComponentiPath + componentTabsPath + campiComponentiPath + componentTabsPath);
  }

  const resetError = useCallback(() => {
    /**
     * dispatch delle chiamate per resettare gli errori
     */
  }, []);

  const defaultCampi = useMemo(() => {
    return {
      [solaLettura]: false,
      [lookup]: false,
      [etichetta]: false,
      [completamentoAutomatico]: false,
      [richiesto]: false,
      [removibile]: false,
      [mostra]: false,
      [righeMultiple]: false,

    }
  }, [])

  const title = t('fieldsParam');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<CampiComponenti>>>([]);

  const fixedProps = useMemo(() => {
    return {

      idApplicazioneSoftware: idApplicazioneSoftware,
      idApplicazioneSoftwareComponente: idApplicazioneSoftwareComponente
    }
  }, [idApplicazioneSoftware, idApplicazioneSoftwareComponente])


  const formLookup = useMemo(() => {
    return {
      tipo: TipoCampoLookup,
      mostraSoloIn: MostraSoloInLookup,
      modificabile: ModificabileLookup,
    }
  }, [])

  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<CampiComponenti> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ? f.removable : !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort ? f.sort : "asc",
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as CampiComponentiKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        if (f.lookupField)
          switch (f.field) {
            case tipoCampi:
              obj.lookup = TipoCampoLookup;
              break;
            case mostraSoloIn:
              obj.lookup = MostraSoloInLookup;
              break;
            case editable:
              obj.lookup = ModificabileLookup;
          }


        return obj;
      })

    )
  }, [allFieldsState, excludedFieldInTable, t]);

  const valdiCampiComponenti = useAppSelector(state => state.campiComponenti.validCampiComponenti);

  const statusValidCampiComponenti = useAppSelector(state => state.campiComponenti.statusValidCampiComponenti);

  const errorBE = useAppSelector(state => state.applicazioniSoftwareProfiliFunzioni.error)

  const [data, setData] = useState<Array<CampiComponenti>>([]);



  useEffect(() => {
    setData(valdiCampiComponenti);
  }, [valdiCampiComponenti]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  return (
    <>

      <Switch>
        <Route path={funzioniComponentiPath + componentTabsPath + campiComponentiPath + componentInsertPath} exact>
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={funzioniComponentiPath + componentTabsPath + campiComponentiPath}
            tabsUri={funzioniComponentiPath + componentTabsPath + campiComponentiPath + componentInsertPath}
            tabsView={false}
            buttonTitle={t("fieldsParam")}
            info1={t("")}
            tabs={[
              {
                label: t(""),
                tabPath: "",
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={funzioniComponentiPath + componentTabsPath + campiComponentiPath}
                        action={insert}
                        status={statusValidCampiComponenti}
                        error={errorBE}
                        update={false}
                        lookups={formLookup}
                        obj={defaultCampi}
                        fields={allFieldsState}
                        fixedProps={fixedProps}

                        translate={t}
                      />
                    </Grid>

                  </Grid>
                ),
                componentElse: <Redirect to={campiComponentiPath} />,
              },
            ]}
          />

        </Route>
        <Route path={funzioniComponentiPath + componentTabsPath + campiComponentiPath + componentTabsPath} exact>
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={funzioniComponentiPath + componentTabsPath + campiComponentiPath}
            tabsUri={funzioniComponentiPath + componentTabsPath + campiComponentiPath + componentTabsPath}
            tabsView={false}
            buttonTitle={t("fieldsParam")}
            info1={t("")}
            tabs={[
              {
                label: t("fieldsParam"),
                tabPath: "",
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={funzioniComponentiPath + componentTabsPath + campiComponentiPath}
                        action={update}
                        status={statusValidCampiComponenti}
                        error={errorBE}
                        update={true}
                        lookups={formLookup}
                        obj={obj}
                        fields={allFieldsState}
                        fixedProps={fixedProps}

                        translate={t}
                      />
                    </Grid>
                  </Grid>
                ),
                componentElse: <Redirect to={campiComponentiPath} />,
              },
            ]}
          />
        </Route>
        <Route path='*'>
          <CrudMaterialTableWithoutLogicDelete
            abilitazione={abilitazione}
            title={title}
            columns={columns}
            columnsButton={true}
            data={data}
            elementIdProps={elementIdProps}
            elementRenderProps={elementRenderProps}
            fetchAllValid={fetchAllValidCampiComponenti}
            fixedProps={fixedProps}
            statusValid={statusValidCampiComponenti}
            errorBE={errorBE}
            logoUri={logoUri}
            resetErrorCallback={resetError}
            insertCallback={insertCallback}
            updateCallback={updateCallback}
            exportType={ExportType.NONE}
            extraOptions={{
              maxBodyHeight: 460,
            }}
            physicalDel={physicalDel} />
        </Route>

      </Switch>
    </>
  )
}
export default DettaglioComponentiW;

