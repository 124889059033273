import { Column } from "@material-table/core";
import { Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import i18n from "../../../i18n";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import { Fields } from "../../../models/Fields";
import { allFieldsProfilo, ApplicazioniSoftwareProfiliFunzioniKeys, elementIdProps, elementRenderProps } from "../../../models/ApplicazioniSoftwareProfiliFunzioni";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { insert, update, physicalDel, fetchFunzioniByIds, } from "../../../store/slices/applicazioniSoftwareProfiliFunzioniSlice";
import { componentInsertPath, componentTabsPath } from "../../../utils/innerFuncPaths";
import { funzioniProfiliPath, applicazioniSoftwareFunzioniProfiliPath } from "../../../utils/utilconst";
import GeneralForm, { OptionalArgs } from "../forms/GeneralForm";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import { ApplicazioniSoftwareProfiliFunzioni } from "../../../models/ApplicazioniSoftwareProfiliFunzioni";
import { AbilitazioneLookup, AbilitazioneLookupKeys, ExportType } from "../../../utils/utildata";
import { fetchById as fetchAbilitazioniByIdAppSoftwareAndIdAppSoftwareFunzione } from "../../../store/slices/applicazioniSoftwareFunzioniOperazioniSlice";
import { fetchAllByIdApplicazioneSoftware } from "../../../store/slices/applicazioniSoftwareFunzioniSlice";
import { fetchAll as fetchAllApplicazioneSoftware, } from "../../../store/slices/applicazioniSoftwareSlice";
import { Lookup } from "../../../models/Utils";

interface FunzioniProfiliProps {
  idApplicazioneSoftwareProfilo: number,
  idApplicazioneSoftware: number,
  applicazioneSoftwareDescrizione: string,
  applicazioneSoftwareProfiloDescrizione: string,
}

const ProfiliFunzioniW = ({ idApplicazioneSoftwareProfilo, idApplicazioneSoftware, applicazioneSoftwareDescrizione, applicazioneSoftwareProfiloDescrizione }: FunzioniProfiliProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const abilitazione = useAppSelector(state => getAbilitazione(state, applicazioniSoftwareFunzioniProfiliPath));
  const [obj, setObj] = useState<ApplicazioniSoftwareProfiliFunzioni | null>(null);

  const insertCallback = () => {
    const initValue: Partial<ApplicazioniSoftwareProfiliFunzioni> = {
      idApplicazioneSoftware: idApplicazioneSoftware,
      applicazioneSoftwareDescrizione: applicazioneSoftwareDescrizione,
      idApplicazioneSoftwareProfilo: idApplicazioneSoftwareProfilo,
      applicazioneSoftwareProfiloDescrizione: applicazioneSoftwareProfiloDescrizione,
    }

    setObj(initValue as ApplicazioniSoftwareProfiliFunzioni)
    history.push(applicazioniSoftwareFunzioniProfiliPath + componentTabsPath + funzioniProfiliPath + componentInsertPath);
  };

  const resetError = useCallback(() => {
    /**
     * dispatch delle chiamate per resettare gli errori
     */
  }, []);

  const updateDetailCallback = (applicazioniSoftwareProfiliFunzioni: ApplicazioniSoftwareProfiliFunzioni) => {
    setObj(applicazioniSoftwareProfiliFunzioni)
    history.push(applicazioniSoftwareFunzioniProfiliPath + componentTabsPath + funzioniProfiliPath + componentTabsPath)
  }

  const title = t('applicationSoftwareFunction');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFieldsProfilo);
  const [columns, setColumns] = useState<Array<Column<ApplicazioniSoftwareProfiliFunzioni>>>([]);

  const fixedProps = useMemo(() => {
    return {
      idApplicazioneSoftwareProfilo: idApplicazioneSoftwareProfilo,
      idApplicazioneSoftware: idApplicazioneSoftware,
    }
  }, [idApplicazioneSoftware, idApplicazioneSoftwareProfilo])

  useEffect(() => {
    dispatch(fetchAllApplicazioneSoftware());
    dispatch(fetchAllByIdApplicazioneSoftware({
      idApplicazioneSoftware: idApplicazioneSoftware
    }))
  }, [dispatch, idApplicazioneSoftware, idApplicazioneSoftwareProfilo])

  const validApplicazioniSoftwareProfiliFunzioni = useAppSelector(state => state.applicazioniSoftwareProfiliFunzioni.validApplicazioniSoftwareProfiliFunzioni);
  const statusValidApplicazioniSoftwareProfiliFunzioni = useAppSelector(state => state.applicazioniSoftwareProfiliFunzioni.statusValidapplicazioniSoftwareProfiliFunzioni);
  const errorBE = useAppSelector(state => state.applicazioniSoftwareProfiliFunzioni.error)
  const applicazioneSoftwareFunzioneLookup = useAppSelector(state => state.applicazioniSoftwareFunzioni.lookup);
  const [data, setData] = useState<Array<ApplicazioniSoftwareProfiliFunzioni>>([]);

  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<ApplicazioniSoftwareProfiliFunzioni> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ? f.removable : !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort ? f.sort : "asc",
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as ApplicazioniSoftwareProfiliFunzioniKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        if (f.lookupField)
          switch (f.field) {
            case 'abilitazioni':
              obj.lookup = AbilitazioneLookup;
              break;
          }
        return obj;
      })
    )
  }, [allFieldsState, applicazioneSoftwareFunzioneLookup, excludedFieldInTable, t]);

  const formCallback = useCallback((formObject: ApplicazioniSoftwareProfiliFunzioni | null, field: string, optionalArgs: OptionalArgs<ApplicazioniSoftwareProfiliFunzioni>) => {
    if (formObject) {
      if (field === "idApplicazioneSoftwareFunzione" && formObject["idApplicazioneSoftwareFunzione"]) {
        dispatch(fetchAbilitazioniByIdAppSoftwareAndIdAppSoftwareFunzione({
          idApplicazioneSoftware: idApplicazioneSoftware,
          idApplicazioneSoftwareFunzione: formObject["idApplicazioneSoftwareFunzione"]
        }))
      }

      setObj(state => {
        return {
          ...state,
          ...formObject
        }
      })
    }
  },
    [dispatch, idApplicazioneSoftware],
  );

  const formLookups = useMemo(() => {
    const abilitazioniLookupFiltered: Lookup = {}
    const funzioniLookupFiltered: Lookup = {}
    /**
     * Filtro delle abilitazioni presenti in funzioni operazioni
     */
    Object.keys(AbilitazioneLookup).forEach((elem: unknown) => {
      abilitazioniLookupFiltered[elem as AbilitazioneLookupKeys] = AbilitazioneLookup[elem as AbilitazioneLookupKeys];
    })
    /**
     * Filtro delle funzioni che non sono ancora abilitate ad un profilo
     */
    Object.keys(applicazioneSoftwareFunzioneLookup).forEach(elem => {
      funzioniLookupFiltered[elem] = applicazioneSoftwareFunzioneLookup[elem];
    })
    return {
      abilitazioni: abilitazioniLookupFiltered,
      idApplicazioneSoftwareFunzione: funzioniLookupFiltered,
    }
  }, [applicazioneSoftwareFunzioneLookup])


  useEffect(() => {
    setData(validApplicazioniSoftwareProfiliFunzioni);
  }, [validApplicazioniSoftwareProfiliFunzioni]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  return (
    <>
      <Switch>
        <Route path={applicazioniSoftwareFunzioniProfiliPath + componentTabsPath + funzioniProfiliPath + componentInsertPath} exact>
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={applicazioniSoftwareFunzioniProfiliPath + componentTabsPath + funzioniProfiliPath}
            tabsUri={applicazioniSoftwareFunzioniProfiliPath + componentTabsPath + funzioniProfiliPath + componentInsertPath}
            tabsView={false}
            buttonTitle={t("applicationsSoftwareParam")}
            info1={t("")}
            tabs={[
              {
                label: t(""),
                tabPath: "",
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={applicazioniSoftwareFunzioniProfiliPath + componentTabsPath + funzioniProfiliPath}
                        action={insert}
                        status={statusValidApplicazioniSoftwareProfiliFunzioni}
                        error={errorBE}
                        update={false}
                        obj={obj}
                        fields={allFieldsState}
                        lookups={formLookups}
                        fixedProps={fixedProps}
                        checksCallback={formCallback}
                        translate={t}
                      />
                    </Grid>

                  </Grid>
                ),
                componentElse: <Redirect to={funzioniProfiliPath} />,
              },
            ]}
          />
        </Route>
        <Route path={applicazioniSoftwareFunzioniProfiliPath + componentTabsPath + funzioniProfiliPath + componentTabsPath} exact>
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={applicazioniSoftwareFunzioniProfiliPath + componentTabsPath + funzioniProfiliPath}
            tabsUri={applicazioniSoftwareFunzioniProfiliPath + componentTabsPath + funzioniProfiliPath + componentTabsPath}
            tabsView={false}
            buttonTitle={t("applicationSoftwareProfileFunction")}
            info1={t("")}
            tabs={[
              {
                label: t("applicationSoftwareProfileFunction"),
                tabPath: "",
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={applicazioniSoftwareFunzioniProfiliPath + componentTabsPath + funzioniProfiliPath}
                        action={update}
                        status={statusValidApplicazioniSoftwareProfiliFunzioni}
                        error={errorBE}
                        update={true}
                        obj={obj}
                        fields={allFieldsState}
                        fixedProps={fixedProps}
                        lookups={formLookups}
                        translate={t}
                        checksCallback={formCallback}
                      />
                    </Grid>
                  </Grid>
                ),
                componentElse: <Redirect to={funzioniProfiliPath} />,
              },
            ]}
          />
        </Route>
        <Route path='*'>
          <CrudMaterialTableWithoutLogicDelete
            abilitazione={abilitazione}
            title={title}
            columns={columns}
            data={data}
            elementIdProps={elementIdProps}
            elementRenderProps={elementRenderProps}
            fetchAllValid={fetchFunzioniByIds}
            fixedProps={fixedProps}
            statusValid={statusValidApplicazioniSoftwareProfiliFunzioni}
            errorBE={errorBE}
            logoUri={logoUri}
            resetErrorCallback={resetError}
            insertCallback={insertCallback}
            updateCallback={updateDetailCallback}
            detailCallback={updateDetailCallback}
            exportType={ExportType.NONE}
            extraOptions={{
              maxBodyHeight: 460,
            }}
            physicalDel={physicalDel} />
        </Route>
      </Switch>
    </>
  )
}

export default ProfiliFunzioniW;