import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, getAllByIds, upd } from '../../services/services.service'
import { createLookup } from '../../utils/utilfunctions';
import { DizionarioDati, DizionarioDatiKeys, elementIdProps } from '../../models/ApplicazioniSoftwareDizionarioDati';

const microservice = "appsoftware";
const entity = "appsoftwaredizionariodati";

interface DizionarioDatiState {
  statusValidDizionarioDati: StatusEnum,
  validDizionarioDati: DizionarioDati[],
  DizionarioDati: DizionarioDati | null,
  lookup: Lookup,
  error: string | null
}

const initialState: DizionarioDatiState = {
  statusValidDizionarioDati: StatusEnum.Succeeded,
  validDizionarioDati: [],
  DizionarioDati: null,
  lookup: {},
  error: null
}

// @GetMapping("cerca/appsoftwaredizionariodati/all/{idApplicazioneSoftware})
export const fetchByIdDizionarioDati = createAsyncThunk(microservice + '/fetchByIdDizionarioDati', async (obj: { idApplicazioneSoftware: number, }) => {
  let ids = [obj.idApplicazioneSoftware,];
  const response = await getAllByIds(microservice, entity, ids);
  return response.data as DizionarioDati[];
});

// inserisci/applicazioniSoftware
export const insert = createAsyncThunk(microservice + '/insert', async (appsoftware: DizionarioDati) => {
  const response = await create(appsoftware, microservice, entity);
  return response.data as DizionarioDati;
});

// modifica/applicazioniSoftware
export const update = createAsyncThunk(microservice + '/update', async (appsoftware: DizionarioDati) => {
  const response = await upd(appsoftware, microservice, entity);
  return response.data as DizionarioDati;
});

// delete/applicazioniSoftware/id/{idappsoftware}
export const physicalDel = createAsyncThunk(microservice + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const dizionarioDatiSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    cleanLookup: (state: DizionarioDatiState) => {
      state.lookup = {};
    },
    reset: (state: DizionarioDatiState) => {
      return initialState;
    },
    resetError: (state: DizionarioDatiState) => {
      state.error = initialState.error;
      state.statusValidDizionarioDati = initialState.statusValidDizionarioDati;
    }
  },
  extraReducers: builder => {

    // fetchAllProfiliByIdAppSoftware
    builder.addCase(fetchByIdDizionarioDati.pending, (state) => {
      state.statusValidDizionarioDati = StatusEnum.Loading;
    })
    builder.addCase(fetchByIdDizionarioDati.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidDizionarioDati = StatusEnum.Failed;
      state.validDizionarioDati = [];
      state.lookup = {};
    })
    builder.addCase(fetchByIdDizionarioDati.fulfilled, (state, { payload }: PayloadAction<DizionarioDati[]>) => {
      state.statusValidDizionarioDati = StatusEnum.Succeeded;
      state.validDizionarioDati = payload ?? [];
      state.lookup = createLookup(payload, "idApplicazioneSoftwareProfilo", ["descrizione"])
    })

    //   // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidDizionarioDati = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidDizionarioDati = StatusEnum.Failed;
      state.validDizionarioDati = [];
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<DizionarioDati>) => {
      state.statusValidDizionarioDati = StatusEnum.Succeeded;
      state.DizionarioDati = payload;
      state.validDizionarioDati = state.validDizionarioDati.concat([payload]);
    })

    //   // update
    builder.addCase(update.pending, (state) => {
      state.statusValidDizionarioDati = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidDizionarioDati = StatusEnum.Failed;
      state.DizionarioDati = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<DizionarioDati>) => {
      state.statusValidDizionarioDati = StatusEnum.Succeeded;
      state.validDizionarioDati = state.validDizionarioDati.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.DizionarioDati = payload;
    })

    //   // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusValidDizionarioDati = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidDizionarioDati = StatusEnum.Failed;
      state.validDizionarioDati = [];
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidDizionarioDati = StatusEnum.Succeeded;
      state.validDizionarioDati = state.validDizionarioDati.filter(el => payload.ids.some(idObj => el[idObj.name as DizionarioDatiKeys] !== idObj.id));
    })
  }
});

export const { cleanLookup, reset, resetError } = dizionarioDatiSlice.actions;
export default dizionarioDatiSlice.reducer;