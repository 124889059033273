import { Column } from "@material-table/core";
import { Box, Button, createStyles, Grid, IconButton, makeStyles, Modal, Paper, Theme } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import i18n from "../../../i18n";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import { Fields } from "../../../models/Fields";
import { allFields, ApplicazioneSoftware, ApplicazioneSoftwareKeys, elementIdProps, elementRenderProps } from "../../../models/ApplicazioniSoftware";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { insert, update, physicalDel, fetchAll } from "../../../store/slices/applicazioniSoftwareSlice";
import { componentInsertPath, componentTabsPath } from "../../../utils/innerFuncPaths";
import { applicazionisoftwareProfiloPath, appSoftwarePath, dizionaridatiPath } from "../../../utils/utilconst";
import GeneralForm from "../forms/GeneralForm";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import ApplicazioniSoftwareProfiliW from "./ApplicazioniSoftwareProfiliW";
import { ExportType } from "../../../utils/utildata";
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import DizionarioDatiW from "./DizionarioDatiW";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 20,
      maxWidth: 1000,
      marginLeft: 'auto',
      marginRight: 'auto',
      overflow: 'auto',
    },
    paper: {
      maxHeight: '85vh'
    },
    image: {
      height: 200,
    }
  }),
);

const ApplicazioniSoftwareW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);

  const classes = useStyles();

  const abilitazione = useAppSelector(state => getAbilitazione(state, appSoftwarePath));

  const [obj, setObj] = useState<ApplicazioneSoftware | null>(null);

  const insertCallback = () => {
    history.push(appSoftwarePath + componentInsertPath);
  };

  const updateDetailCallback = (applicazioniSoftware: ApplicazioneSoftware) => {
    setObj(applicazioniSoftware)
    history.push(appSoftwarePath + componentTabsPath)
  }

  const [logo, setLogo] = useState<string | null>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setIsOpen(true);
  }

  const handleClose = () => {
    setIsOpen(false);
    setLogo(null);
  }


  const resetError = useCallback(() => {
    /**
     * dispatch delle chiamate per resettare gli errori
     */
  }, []);

  const title = t('applicationsSoftwareParam');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<ApplicazioneSoftware>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<ApplicazioneSoftware> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ? f.removable : !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort ? f.sort : "asc",
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as ApplicazioneSoftwareKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;

        }

        if (f.type && f.type === "image") {
          obj.render = (rowData: ApplicazioneSoftware) => {
            const handleclick = () => {
              setLogo(rowData[f.field as ApplicazioneSoftwareKeys] as string);
              handleOpen();
            }
            return <Button
              onClick={handleclick}
              size='small'
              variant='outlined'
              color='primary'>
              {t('show')}
            </Button>
          }
        }


        return obj;
      })

    )
  }, [allFieldsState, excludedFieldInTable, t]);

  const validApplicazioniSoftware = useAppSelector(state => state.applicazioniSoftware.validApplicazioniSoftware);

  const statusValidApplicazioniSoftware = useAppSelector(state => state.applicazioniSoftware.statusValidApplicazioniSoftware);

  const errorBE = useAppSelector(state => state.applicazioniSoftware.error)

  const [data, setData] = useState<Array<object>>([]);

  useEffect(() => {
    setData(validApplicazioniSoftware);
  }, [validApplicazioniSoftware]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  return (
    <>

      <Switch>
        <Route path={appSoftwarePath} exact>
          <CrudMaterialTableWithoutLogicDelete
            abilitazione={abilitazione}
            title={title}
            columns={columns}
            data={data}
            elementIdProps={elementIdProps}
            elementRenderProps={elementRenderProps}
            fetchAllValid={fetchAll}
            statusValid={statusValidApplicazioniSoftware}
            errorBE={errorBE}
            logoUri={logoUri}
            resetErrorCallback={resetError}
            insertCallback={insertCallback}
            updateCallback={updateDetailCallback}
            detailCallback={updateDetailCallback}
            exportType={ExportType.NONE}
            extraOptions={{
              maxBodyHeight: 460,
            }}
            physicalDel={physicalDel} />

        </Route>
        <Route path={appSoftwarePath + componentInsertPath} exact>
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={appSoftwarePath}
            tabsUri={appSoftwarePath + componentInsertPath}
            tabsView={false}
            buttonTitle={t("applicationsSoftwareParam")}
            info1={''}
            tabs={[
              {
                label: t(""),
                tabPath: "",
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={appSoftwarePath}
                        action={insert}
                        status={statusValidApplicazioniSoftware}
                        error={errorBE}
                        update={false}
                        obj={{}}
                        fields={allFieldsState}
                        translate={t}
                      />
                    </Grid>

                  </Grid>
                ),
                componentElse: <Redirect to={appSoftwarePath} />,
              },
            ]}
          />

        </Route>
        <Route path={appSoftwarePath + componentTabsPath}>
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={appSoftwarePath}
            tabsUri={appSoftwarePath + componentTabsPath}
            tabsView={true}
            buttonTitle={t("applicationsSoftwareParam")}
            info1={obj?.descrizione ?? ''}
            tabs={[
              {
                label: t("applicationsSoftwareParam"),
                tabPath: appSoftwarePath,
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={appSoftwarePath}
                        action={update}
                        status={statusValidApplicazioniSoftware}
                        error={errorBE}
                        update={true}
                        obj={obj}
                        fields={allFieldsState}
                        translate={t}
                      />
                    </Grid>
                  </Grid>
                ),
                componentElse: <Redirect to={appSoftwarePath} />,
              },
              {
                label: t("profilesParam"),
                tabPath: applicazionisoftwareProfiloPath,
                abilitazione: Abilitazione.READ,
                componentIf: obj?.idApplicazioneSoftware && obj?.applicazioniSoftwareProfili &&
                  <ApplicazioniSoftwareProfiliW
                    idApplicazioneSoftware={obj.idApplicazioneSoftware}
                    applicazioniSoftwareProfili={obj.applicazioniSoftwareProfili}
                  />,
                componentElse: <Redirect to={appSoftwarePath} />
              },
              {
                label: t("dataDictionary"),
                tabPath: dizionaridatiPath,
                abilitazione: Abilitazione.READ,
                componentIf: obj?.idApplicazioneSoftware && obj?.applicazioniSoftwareDizionarioDati &&
                  <DizionarioDatiW
                    idApplicazioneSoftware={obj.idApplicazioneSoftware}
                    dizionarioDati={obj.applicazioniSoftwareDizionarioDati}
                  />,
                componentElse: <Redirect to={appSoftwarePath} />
              },
            ]}
          />
        </Route>
      </Switch>
      <Modal
        className={clsx(classes.container)}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper className={clsx(classes.paper)}>
          <Grid container>
            <Grid item xs={9} />
            <Grid item xs={3}>
              <Box display='flex' justifyContent='right'>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>

            </Grid>
          </Grid>
          <Box
            display='flex'
            justifyContent='center'
            minWidth={800}
            maxWidth={800}
            mx='auto'
            p={10}
          >

            <img className={clsx(classes.image)}
              src={logo?.startsWith('data')
                ? logo
                : ![null, undefined, ''].includes(logo)
                  ? 'data:image/png;base64,' + logo
                  : ''
              }

              alt={t('logo')}
            />
          </Box>
        </Paper>
      </Modal>
    </>
  )
}
export default ApplicazioniSoftwareW;

