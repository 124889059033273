import { validateInputTextFixedLen, validateInputTextMinMaxLen, validateTelephoneCellularNumber } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type OrganizzazioneKeys = keyof Organizzazione;
export type OrganizzazioneServerSMTPKeys = keyof OrganizzazioneServerSMTP;

export const elementIdProps: OrganizzazioneKeys[] = ["idOrganizzazione"];
export const elementRenderProps: OrganizzazioneKeys[] = ["nome"];

export const allFields: Fields[] = [
  {
    field: "nome", type: "string", required: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 250, false, helperText, true), keyTradValidation: "size3to250ParamValidation",
    show: true, titleKey: "nameParam", colsNum: 6
  },
  {
    field: "indirizzo", type: "string", required: false,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 1000, false, helperText, false), keyTradValidation: "size3to1000ParamValidation",
    show: true, titleKey: "addressParam", colsNum: 6
  },
  {
    field: "civico", type: "string", required: false,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 1, 10, false, helperText, false), keyTradValidation: "size1to10ParamValidation",
    show: true, titleKey: "streetNumberParam", colsNum: 6
  },
  {
    field: "citta", type: "string", required: false,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 1, 250, false, helperText, false), keyTradValidation: "size1to255ParamValidation",
    show: true, titleKey: "residenceCityParam", colsNum: 6
  },
  {
    field: "provincia", type: "string", required: false,
    validate: (x: string, helperText: string) => validateInputTextFixedLen(x, 2, helperText, false), keyTradValidation: "size2to2ParamValidation",
    show: true, titleKey: "domicileDistrictParam", colsNum: 6
  },
  {
    field: "cap", type: "string", required: false,
    validate: (x: string, helperText: string) => validateInputTextFixedLen(x, 5, helperText, false), keyTradValidation: "size5to5ParamValidation",
    show: true, titleKey: "residenceCapParam", colsNum: 6
  },
  {
    field: "nazione", type: "string", required: false,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 0, 250, false, helperText, false), keyTradValidation: "size0to250ParamValidation",
    show: true, titleKey: "nationParam", colsNum: 6
  },
  {
    field: "telefono1", type: "string", required: false,
    validate: (x: string | undefined, helperText: string) => validateTelephoneCellularNumber(x, helperText, false), keyTradValidation: "cellTelNumberParamValidation",
    show: true, titleKey: "telephoneParam1", colsNum: 6
  },
  {
    field: "telefono2", type: "string", required: false,
    validate: (x: string | undefined, helperText: string) => validateTelephoneCellularNumber(x, helperText, false), keyTradValidation: "cellTelNumberParamValidation",
    show: true, titleKey: "telephoneParam2", colsNum: 6
  },
  {
    field: "logoOrganizzazione", type: "image", required: false, show: true, titleKey: "logo", colsNum: 6
  },
  {
    field: "smtpServiceName", type: "string", required: false,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 0, 255, false, helperText, false), keyTradValidation: "size0to255ParamValidation",
    show: true, titleKey: "SMTPServiceName", group: "Server SMTP (Invio EMail)",
  },
  {
    field: "smtpHost", type: "string", required: false,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 0, 255, false, helperText, false), keyTradValidation: "size0to255ParamValidation",
    show: true, titleKey: "SMTPHost", group: "Server SMTP (Invio EMail)",
  },
  {
    field: "smtpPort", type: "string", required: false,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 0, 255, false, helperText, false), keyTradValidation: "size0to255ParamValidation",
    show: true, titleKey: "SMTPPort", group: "Server SMTP (Invio EMail)",
  },
  {
    field: "smtpAccountName", type: "string", required: false,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 0, 255, false, helperText, false), keyTradValidation: "size0to255ParamValidation",
    show: true, titleKey: "SMTPAccountName", group: "Server SMTP (Invio EMail)",
  },
  {
    field: "smtpAccountPassword", type: "string", required: false,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 0, 255, false, helperText, false), keyTradValidation: "size0to255ParamValidation",
    show: true, titleKey: "SMTPAccountPassword", group: "Server SMTP (Invio EMail)", isPassword: true,
  },
];

export interface Organizzazione {
  idOrganizzazione: number;
  nome: string;
  indirizzo: string;
  civico: string;
  citta: string;
  provincia: string;
  cap: string;
  nazione: string;
  telefono1: string;
  telefono2: string;
  logoOrganizzazione: string;
  version: number;

  organizzazioniNomiHost?: OrganizzazioniNomiHostDTO[];
}

export interface OrganizzazioniNomiHostDTO {
  idOrganizzazioneNomeHost: number;
  idOrganizzazione: number;
  nomeHost: string;
  skinTheme: string;
  samlIdPUrl: string;
  samlIssuerId: string;
  samlCertificate: string;
  version: number;
}

export interface OrganizzazioneServerSMTP extends Organizzazione {
  smtpServiceName: string,
  smtpHost: string,
  smtpPort: number,
  smtpAccountName: string,
  smtpAccountPassword: string,
}