import { Column } from "@material-table/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, } from "react-router-dom";
import { Fields } from "../../../models/Fields";
import { allFields, elementIdProps, elementRenderProps, NomiHostOrganizzazioni, NomiHostOrganizzazioniKeys, } from "../../../models/NomiHostOrganizzazioni";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { insert, physicalDel, fetchAll, } from "../../../store/slices/nomiHostOrganizzazioniSlice";
import { nomiHostPath } from "../../../utils/utilconst";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import { ExportType } from "../../../utils/utildata";
import { fetchAll as fetchAllOrganizzazioni, } from "../../../store/slices/organizzazioneSlice";

interface NomiHostOrganizzazioniProps {
  idNomeHost: number;
  nomeHost: string;
}

const NomiHostOrganizzazioniW = ({ idNomeHost, nomeHost }: NomiHostOrganizzazioniProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const abilitazione = useAppSelector(state => getAbilitazione(state, nomiHostPath));

  useEffect(() => {
    dispatch(fetchAllOrganizzazioni());
  }, [dispatch])

  const resetError = useCallback(() => {
    /**
     * dispatch delle chiamate per resettare gli errori
     */
  }, []);

  const organizzazioniLookup = useAppSelector(state => state.organizzazioni.lookup);

  const fixedProps = useMemo(() => {
    return {
      idNomeHost: idNomeHost,
    }
  }, [idNomeHost])

  const title = t('organizationParams');

  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);

  const [columns, setColumns] = useState<Array<Column<NomiHostOrganizzazioni>>>([]);

  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<NomiHostOrganizzazioni> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ? f.removable : !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort ? f.sort : "asc",
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as NomiHostOrganizzazioniKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        if (f.lookupField) {
          switch (f.field) {
            case 'idOrganizzazione':
              obj.lookup = organizzazioniLookup
              break;
          }
        }

        return obj;
      })
    )
  }, [allFieldsState, organizzazioniLookup, t]);

  const validnomiHostOrganizzazione = useAppSelector(state => state.nomiHostOrganizzazioni.validNomiHostOrganizzazione);
  const statusValidnomiHostOrganizzazione = useAppSelector(state => state.nomiHostOrganizzazioni.statusValidnomiHostOrganizzazione);

  const errorBE = useAppSelector(state => state.organizzazioni.error)

  const [data, setData] = useState<Array<object>>([]);

  useEffect(() => {
    setData(validnomiHostOrganizzazione);
  }, [validnomiHostOrganizzazione]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  return (
    <>
      <Switch>
        <Route path='*'>
          <CrudMaterialTableWithoutLogicDelete
            abilitazione={abilitazione}
            title={title}
            columns={columns}
            data={data}
            elementIdProps={elementIdProps}
            elementRenderProps={elementRenderProps}
            fetchAllValid={fetchAll}
            fixedProps={fixedProps}
            statusValid={statusValidnomiHostOrganizzazione}
            errorBE={errorBE}
            logoUri={logoUri}
            resetErrorCallback={resetError}
            insert={insert}
            physicalDel={physicalDel}
            exportType={ExportType.NONE}
            extraOptions={{
              maxBodyHeight: 460,
            }}
          />
        </Route>
      </Switch>
    </>
  )
}

export default NomiHostOrganizzazioniW;