import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import RefreshIcon from "@material-ui/icons/Refresh";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { saveAs } from "file-saver";
import { Abilitazione } from "../../../../models/AbilitazioneEnum";
import { useAppDispatch } from "../../../../store/hooks";
import { AsyncThunk } from "@reduxjs/toolkit";
import { Ids, StatusEnum } from "../../../../models/Utils";
import { Grid, Typography, Theme } from "@material-ui/core";
import { Components, MTableBodyRow, MTableCell, MTableEditField, MTableToolbar } from "@material-table/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import itIT from "date-fns/locale/it";
import enGB from "date-fns/locale/en-GB";

import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DescriptionIcon from "@material-ui/icons/Description";

import { getDateDDMMYYYY } from '../../../../utils/utilfunctions';
import { PDFExtraData } from '../../../../models/Utils';
import { ExportType } from "../../../../utils/utildata";
import { format } from "date-fns";
import { FixedProps } from "../../../../utils/data.types";

interface GeneralMessage {
  text: string;
  color: string;
}

const useCrudMaterialTable = <T extends Record<string, unknown>>(
  abilitazione: number,
  theme: Theme,
  title: string,
  logoUri: string | null,
  elementIdProps: string[],
  elementRenderProps: string[],
  fetchAllValid:
    | AsyncThunk<T[], void, {}>
    | AsyncThunk<T[], T, {}>
    | AsyncThunk<T, T, {}>,
  physicalDel: AsyncThunk<{ ids: Ids<any>[] }, Ids<any>[], {}>,
  columnsButton: boolean,
  statusValid: string,
  errorBE: string | null,
  insert?: AsyncThunk<T, T, {}>,
  update?: AsyncThunk<T, T, {}>,
  localizedDatePicker?: boolean,
  nullableTextFields?: boolean,
  fixedProps?: FixedProps,
  exportDataExtra?: PDFExtraData,
  exportType?: ExportType,
  isExportLandscape?: boolean,
  insertCallback?: () => void,
  updateCallback?: (obj: T) => void,
  detailCallback?: (obj: T) => void,
  resetErrorCallback?: () => void,
  autofetch: boolean = true,
  isLoading: boolean = false
) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [generalMessage, setGeneralMessage] = useState<GeneralMessage | null>(null);
  const [wrapValidResolve, setWrapValidResolve] = useState<(() => void) | null>(null);
  const [wrapValidReject, setWrapValidReject] = useState<(() => void) | null>(null);
  const [actions, setActions] = useState<any[]>([]);
  const [editable, setEditable] = useState<Object>({});
  const [components, setComponents] = useState<Object>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [logoImage, setLogoImage] = useState<HTMLImageElement | null>(null);
  const materialTableRef = useRef();

  useEffect(() => {
    return () => {
      setErrorMessage(null);
      setWrapValidResolve(null);
      setWrapValidReject(null);
      setActions([]);
      setEditable({});
      setComponents({});
      setLoading(false);
      setLogoImage(null);
    };
  }, []);

  useEffect(() => {
    if (wrapValidResolve && statusValid === StatusEnum.Succeeded) {
      setErrorMessage(null);
      setTimeout(() => {
        wrapValidResolve();
      }, 1000);
      setWrapValidResolve(null);
    } else if (wrapValidReject && statusValid === StatusEnum.Failed) {
      setErrorMessage(errorBE);
      wrapValidReject();
      setWrapValidReject(null);
    }
  }, [wrapValidResolve, statusValid, dispatch, errorBE, wrapValidReject]);

  useEffect(() => {
    const im = new Image();
    im.onload = () => {
      setLogoImage(im);
    };

    if (logoUri)
      im.src = logoUri;
  }, [logoUri]);

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading]);

  useEffect(() => {
    const refreshData = (resolve: (value: unknown) => void, reject: (value: unknown) => void) => {
      setErrorMessage(null);
      setWrapValidResolve(() => resolve);
      setWrapValidReject(() => reject);
      if (autofetch)
        if (fixedProps) {
          let fav = fetchAllValid as AsyncThunk<T[], T, {}>;
          dispatch(fav(fixedProps as T));
        } else {
          let fav = fetchAllValid as AsyncThunk<T[], void, {}>;
          dispatch(fav());
        }
    };
    setLoading(true);
    new Promise((resolve, reject) => {
      refreshData(resolve, reject);
    }).then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [abilitazione, fetchAllValid, fixedProps, dispatch, t, autofetch]);

  const localization = useMemo(() => {
    return {
      body: {
        emptyDataSourceMessage: t("emptyDataSourceMessage"),
        addTooltip: t("addTooltip"),
        deleteTooltip: t("deleteTooltip"),
        editTooltip: t("editTooltip"),
        filterRow: {
          filterTooltip: t("filterTooltip"),
        },
        editRow: {
          deleteText: t("rowDefDeleteText"),
          cancelTooltip: t("cancelTooltip"),
          saveTooltip: t("saveTooltip"),
        },
      },
      grouping: {
        placeholder: t("groupingPlaceholder"),
        groupedBy: t("groupedBy"),
      },
      header: {
        actions: t("actions"),
      },
      pagination: {
        labelDisplayedRows: t("labelDisplayedRows"),
        labelRowsSelect: t("labelRowsSelect"),
        labelRowsPerPage: t("labelRowsPerPage"),
        firstPageLabel: t("firstPageLabel"),
        firstTooltip: t("firstTooltip"),
        previousPageLabel: t("previousPageLabel"),
        previousTooltip: t("previousTooltip"),
        nextPageLabel: t("nextPageLabel"),
        nextTooltip: t("nextTooltip"),
        lastPageLabel: t("lastPageLabel"),
        lastTooltip: t("lastTooltip"),
      },
      toolbar: {
        addRemoveColumns: t("addRemoveColumns"),
        nRowsSelected: t("nRowsSelected"),
        showColumnsTitle: t("showColumnsTitle"),
        showColumnsAriaLabel: t("showColumnsAriaLabel"),
        exportTitle: t("exportTitle"),
        exportAriaLabel: t("exportAriaLabel"),
        exportName: t("exportName"),
        searchTooltip: t("searchTooltip"),
        searchPlaceholder: t("searchPlaceholder"),
      },
    };
  }, [t]);

  const resetErrorStatus = useCallback(() => {
    resetErrorCallback && resetErrorCallback();
    setGeneralMessage(null);
    setWrapValidResolve(() => () => { });
    setWrapValidReject(() => () => { });
  }, [resetErrorCallback])

  const operationCancel = useCallback(() => {
    setGeneralMessage({
      text: t('cancelOpLabel'),
      color: 'green',
    });
    setTimeout(() => {
      setGeneralMessage(null);
    }, 5000);
  }, [t]);

  useEffect(() => {
    const refreshData = (resolve: (value: unknown) => void, reject: (value: unknown) => void) => {
      setErrorMessage(null);
      setWrapValidResolve(() => resolve);
      setWrapValidReject(() => reject);
      if (fixedProps) {
        let fav = fetchAllValid as AsyncThunk<T[], T, {}>;
        dispatch(fav(fixedProps as T));
      } else {
        let fav = fetchAllValid as AsyncThunk<T[], void, {}>;
        dispatch(fav());
      }
    };

    const insertData = (obj: Object, resolve: (value: unknown) => void, reject: (value: unknown) => void) => {
      setErrorMessage(null);
      setWrapValidResolve(() => resolve);
      setWrapValidReject(() => reject);
      let dispObj: Record<string, unknown> = {};
      for (const [key, value] of Object.entries(obj)) {
        let val = value;
        if (typeof value === "string") val = val.trim();
        if (val !== null && val !== undefined && val !== "") dispObj[key] = val;
      }
      if (fixedProps) {
        for (const [key, value] of Object.entries(fixedProps)) {
          dispObj[key] = value;
        }
      }
      insert && dispatch(insert(dispObj as T));
    };

    const updateData = (obj: Object, resolve: (value: unknown) => void, reject: (value: unknown) => void) => {
      setErrorMessage(null);
      setWrapValidResolve(() => resolve);
      setWrapValidReject(() => reject);
      let dispObj: Record<string, unknown> = {};
      for (const [key, value] of Object.entries(obj)) {
        let val = value;
        if (typeof value === "string") val = val.trim();
        if (val !== null && val !== undefined && val !== "") dispObj[key] = val;
      }
      if (fixedProps) {
        for (const [key, value] of Object.entries(fixedProps)) {
          dispObj[key] = value;
        }
      }
      update && dispatch(update(dispObj as T));
    };

    const physicalDeleteData = (ids: Ids<string>[], resolve: (value: unknown) => void, reject: (value: unknown) => void) => {
      setErrorMessage(null);
      setWrapValidResolve(() => resolve);
      setWrapValidReject(() => reject);
      dispatch(physicalDel(ids));
    };

    let act = [];
    act.push({
      icon: RefreshIcon,
      isFreeAction: true,
      tooltip: t("refreshData"),
      onClick: () => {
        setLoading(true);
        new Promise((resolve, reject) => {
          refreshData(resolve, reject);
        }).then(() => setLoading(false))
          .catch(() => setLoading(false));
      },
    });

    if (abilitazione >= Abilitazione.READ_UPDATE) {
      if (insertCallback) {
        act.push({
          icon: AddIcon,
          isFreeAction: true,
          tooltip: t("addTooltip"),
          onClick: () => {
            resetErrorStatus();
            insertCallback();
          },
        });
      }
      if (updateCallback) {
        act.push({
          icon: EditIcon,
          tooltip: t("editAndDetailTooltip"),
          onClick: (_: unknown, rowData: T) => {
            resetErrorStatus();
            updateCallback(rowData);
          },
        });
      }
    } else if (abilitazione >= Abilitazione.READ) {
      if (detailCallback) {
        act.push({
          icon: DescriptionIcon,
          tooltip: t("detailTooltip"),
          onClick: (_: unknown, rowData: T) => {
            resetErrorStatus()
            detailCallback(rowData);
          },
        });
      }
    }
    setActions(act);

    let edit: Record<string, any> = {};
    if (abilitazione >= Abilitazione.READ_UPDATE) {
      if (!insertCallback && insert) {
        edit["onRowAdd"] = (newData: T) => {
          return new Promise((resolve, reject) => {
            insertData(newData, resolve, reject);
          });
        }
        edit["onRowAddCancelled"] = () => {
          resetErrorStatus();
          operationCancel();
        };
      }
      if (!updateCallback && update) {
        edit["onRowUpdate"] = (newData: T) => {
          return new Promise((resolve, reject) => {
            updateData(newData, resolve, reject);
          });
        };
        edit["onRowUpdateCancelled"] = () => {
          resetErrorStatus();
          operationCancel();
        };
      }
    }
    if (abilitazione >= Abilitazione.READ_UPDATE_LOGICDELETE_DEFDELETE_RESTORE) {
      edit["onRowDelete"] = (oldData: Record<string, string>) =>
        new Promise((resolve, reject) => {
          const ids: Ids<string>[] = [];
          elementIdProps.forEach((propId) =>
            ids.push({ name: propId, id: oldData[propId] })
          );
          physicalDeleteData(ids, resolve, reject);
        });
    }
    setEditable(edit);
  }, [abilitazione, elementIdProps, elementRenderProps, t, dispatch, insert, update, physicalDel, fetchAllValid, fixedProps, insertCallback, updateCallback, detailCallback, resetErrorStatus, operationCancel]);

  useEffect(() => {
    const comp: Components = {};

    comp["Cell"] = (props) => {
      if (localizedDatePicker && props.columnDef.type === "date") {
        const formattedDate = props.value ? getDateDDMMYYYY(new Date(props.value)) : '';
        const updatedProps = { ...props, "value": formattedDate }
        return <MTableCell {...updatedProps} />
      }
      if (localizedDatePicker && props.columnDef.type === "datetime") {
        const formattedDate = props.value ? format(new Date(props.value), 'dd/MM/yyyy HH:mm:ss') : '';
        const updatedProps = { ...props, "value": formattedDate }
        return <MTableCell {...updatedProps} />
      }
      else return <MTableCell {...props} />
    }

    comp["Toolbar"] = (props) => {
      const INSERT_ICON = 1;
      if ((insertCallback || insert) && props.actions[INSERT_ICON]) {
        const preInsertCallback = props.actions[INSERT_ICON].onClick;

        props.actions[INSERT_ICON].onClick = () => {
          resetErrorStatus();
          preInsertCallback();
        }
      }

      return <>
        <MTableToolbar {...props} />
        {errorMessage && errorMessage.length > 0 && (
          <Grid container justifyContent="center">
            <Typography color="secondary" variant="body1">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        {generalMessage && generalMessage.text && generalMessage.color && generalMessage.text.length > 0 && (
          <Grid container justifyContent="center">
            <Typography style={{ color: generalMessage.color }} variant="body1">
              {generalMessage.text}
            </Typography>
          </Grid>
        )}
      </>
    };

    comp["EditField"] = (props) => {
      if (localizedDatePicker && props.columnDef.type === "date") {
        return (
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={i18n.language === "it-IT" || i18n.language === "it" ? itIT : enGB}
          >
            <KeyboardDatePicker
              okLabel={t('insertLabel')}
              clearLabel={t("clearLabel")}
              cancelLabel={t("cancelLabel")}
              clearable
              margin="none"
              format="dd/MM/yyyy"
              value={props.value ? props.value : null}
              error={props.error}
              helperText={props.helperText}
              onChange={(e) => {
                if (e) {
                  let month =
                    e.getMonth() < 9
                      ? "0" + (e.getMonth() + 1)
                      : e.getMonth() + 1;
                  let day = e.getDate() < 10 ? "0" + e.getDate() : e.getDate();
                  props.onChange(
                    e.getFullYear() + "-" + month + "-" + day + "T00:00:00"
                  );
                } else props.onChange(null);
              }}
              KeyboardButtonProps={{ "aria-label": "change date" }}
            />
          </MuiPickersUtilsProvider>
        );
      } else if (
        nullableTextFields &&
        props.columnDef.type === "string" &&
        (props.value === null || props.value === undefined)
      ) {
        let editProps = { ...props, value: "" };
        return <MTableEditField {...editProps} />;
      } else return <MTableEditField {...props} />;
    };

    comp['Row'] = (props) => {
      const MODIFY_ICON = 2 - (insertCallback || insert ? 0 : 1);
      const DELETE_ICON = 3 - (insertCallback || insert ? 0 : 1);;

      if (props.actions[MODIFY_ICON] && props.actions[DELETE_ICON]) {
        // Prendere l'oggetto per update e delete
        const updateButton: Record<string, (e: unknown, rowData: unknown) => void> = updateCallback ? props.actions[MODIFY_ICON] : props.actions[MODIFY_ICON]();
        const deleteButton: Record<string, (e: unknown, rowData: unknown) => void> = props.actions[DELETE_ICON]();

        // Prendere l'onClick callback di ogni buttone
        const preUpdateCallback: (e: unknown, rowData: unknown) => void = updateButton['onClick'];
        const preDeleteCallback: (e: unknown, rowData: unknown) => void = deleteButton['onClick'];

        // La nuova callback contiene il reset dello stato nello store e la callback originale
        updateButton['onClick'] = (e: unknown, rowData: unknown) => {
          resetErrorStatus();
          preUpdateCallback(e, rowData);
        }

        deleteButton['onClick'] = (e: unknown, rowData: unknown) => {
          resetErrorStatus();
          preDeleteCallback(e, rowData);
        }

        // Reimpostare le action
        props.actions[MODIFY_ICON] = updateCallback ? updateButton : () => updateButton;
        props.actions[DELETE_ICON] = () => deleteButton;
      }

      return <MTableBodyRow {...props} />
    }

    setComponents(comp);
  }, [dispatch, errorMessage, generalMessage, i18n.language, insert, insertCallback, localizedDatePicker, nullableTextFields, resetErrorStatus, t, theme.palette.secondary.main, updateCallback]);

  const exportMenuOptions = useMemo(() => [
    {
      label: t("exportPDF"),
      exportFunc: (cols: Record<string, any>[], data: Record<string, any>) => {
        let dataArray = data as Array<Array<any>>;
        let modData = dataArray.map((da) => {
          return Object.values(da).map((el) => {
            const pattern: RegExp = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}$/;

            if (pattern.test(el)) {
              return getDateDDMMYYYY(new Date(el));
            }

            if (typeof el === "boolean") {
              return el ? t("yes") : t("no");
            } else return el;
          });
        });
        const imgHeight = 25;
        const doc = new jsPDF({ orientation: isExportLandscape ? "landscape" : "portrait" });
        if (logoImage) {
          const imgWidth = (logoImage.width * imgHeight) / logoImage.height;
          doc.addImage(logoImage, "PNG", 15, 15, imgWidth, imgHeight);
        }
        doc.text(
          title,
          15,
          imgHeight + 22
        );

        // cols: Array<Columns>
        // data: Array<Array>

        // fixedProps header table
        if (exportDataExtra && exportDataExtra.head)
          autoTable(doc, {
            startY: imgHeight + 27,
            head: [exportDataExtra.head.title],
            body: [exportDataExtra.head.value],
            headStyles: {
              fillColor: theme.palette.secondary.main,
            }
          });

        // data
        autoTable(doc, {
          startY: exportDataExtra ? undefined : imgHeight + 27,
          head: [cols],
          body: modData,
          headStyles: {
            fillColor: theme.palette.primary.main,
          },
          theme: "grid",
        });

        if (exportDataExtra && exportDataExtra.extra)
          exportDataExtra.extra.forEach(extra =>
            autoTable(doc, {
              head: [extra.title],
              body: extra.value,
              headStyles: {
                fillColor: theme.palette.primary.main,
              }
            })
          )


        doc.save(title + ".pdf");
      },
    },
    {
      label: t("exportCSV"),
      exportFunc: (cols, data: Record<string, any>[]) => {
        const sep = '|'
        let csvData = "sep=" + sep + "\n";
        // headers
        cols.forEach((col, ind) => {
          csvData += col.title;
          if (ind !== cols.length - 1) csvData += sep;
          else csvData += "\n";
        });
        // data
        data?.forEach((row, ind) => {
          const values = Object.values(row);
          values?.forEach((el, ind) => {
            csvData += el;
            if (ind !== cols.length - 1) csvData += sep;
          });
          if (ind !== data.length - 1) csvData += "\n";
        });
        var myFile = new File([csvData], title + ".csv", {
          type: "text/csv;charset=utf-8",
        });
        saveAs(myFile);
      },
    },
  ], [exportDataExtra, isExportLandscape, logoImage, t, theme.palette.primary.main, theme.palette.secondary.main, title]);

  const options = useMemo(() => {
    return {
      toolbarButtonAlignment: "left" as "left",
      addRowPosition: 'first' as 'first',
      columnsButton: columnsButton,
      exportAllData: true,
      thirdSortClick: false,
      exportMenu: exportType === ExportType.NONE
        ? []
        : exportType === ExportType.PDF || exportType === ExportType.CSV
          ? [exportMenuOptions[exportType]]
          : exportMenuOptions,
    };
  }, [columnsButton, exportMenuOptions, exportType]);

  return {
    loading,
    materialTableRef,
    localization,
    editable,
    actions,
    options,
    components,
  };
};
export default useCrudMaterialTable;
