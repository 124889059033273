import { Fields } from "./Fields";

export type ComponentiFunzioniKeys = keyof ComponentiFunzioni;

export const elementIdProps: ComponentiFunzioniKeys[] = ["idApplicazioneSoftware", "idApplicazioneSoftwareFunzione", "idApplicazioneSoftwareComponente",];
export const elementRenderProps: ComponentiFunzioniKeys[] = ["uriPath"];

export const allFields: Fields[] = [
  {
    field: "idApplicazioneSoftwareComponente", titleKey: "idApplicazioneSoftwareComponente", required: true, show: true, lookupField: true, editable: 'onAdd',
  },
  {
    field: "uriPath", type: 'string', titleKey: "uriPath", required: true, show: true,
  },
];

export interface ComponentiFunzioni {
  idApplicazioneSoftware: number;
  idApplicazioneSoftwareFunzione: number;
  idApplicazioneSoftwareComponente: number;
  uriPath: string;
  version: number;
}