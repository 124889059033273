import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, getAll, getAllByIds, getByIds, upd } from '../../services/services.service'
import { ApplicazioniSoftwareFunzioni, ApplicazioniSoftwareFunzioniKeys, elementIdProps } from '../../models/ApplicazioniSoftwareFunzioni';
import { createLookup } from '../../utils/utilfunctions';

const microservice = "applicazionisoftwarefunzioni";
const entity = "appsoftwarefunzioni";

interface ApplicazioniSoftwareFunzioniState {
  statusValidapplicazioneSoftwareProfilo: StatusEnum,
  validApplicazioniSoftwareFunzioni: ApplicazioniSoftwareFunzioni[],
  ApplicazioniSoftwareFunzioni: ApplicazioniSoftwareFunzioni | null,
  lookup: Lookup,
  error: string | null
}

const initialState: ApplicazioniSoftwareFunzioniState = {
  statusValidapplicazioneSoftwareProfilo: StatusEnum.Succeeded,
  validApplicazioniSoftwareFunzioni: [],
  ApplicazioniSoftwareFunzioni: null,
  lookup: {},
  error: null
}

//@GetMapping("cerca/appsoftwarefunzioni/id/{idApplicazioneSoftware}/{idApplicazioneSoftwareFunzione}")
export const fetchById = createAsyncThunk(microservice + '/fetchById', async (obj: { idApplicazioniSoftware: number }) => {
  let ids = [obj.idApplicazioniSoftware];
  const response = await getByIds(microservice, entity, ids);
  return response.data as ApplicazioniSoftwareFunzioni;
});

//@GetMapping("cerca/appsoftwarefunzioni/all/{idApplicazioneSoftware}")
export const fetchAllByIdApplicazioneSoftware = createAsyncThunk(microservice + '/fetchAllbyidApplicazioneSoftware', async (obj: { idApplicazioneSoftware: number }) => {
  let ids = [obj.idApplicazioneSoftware];
  const response = await getAllByIds(microservice, entity, ids);
  return response.data as ApplicazioniSoftwareFunzioni[];
});

//@GetMapping("cerca/appSoftwareFunzioni/all")
export const fetchAll = createAsyncThunk(microservice + '/fetchAll', async () => {
  const response = await getAll(microservice, entity);
  return response.data as ApplicazioniSoftwareFunzioni[];
});

// inserisci/applicazioniSoftwareFunzioni
export const insert = createAsyncThunk(microservice + '/insert', async (appsoftware: ApplicazioniSoftwareFunzioni) => {
  const response = await create(appsoftware, microservice, entity);
  return response.data as ApplicazioniSoftwareFunzioni;
});

// modifica/applicazioniSoftwareFunzioni
export const update = createAsyncThunk(microservice + '/update', async (appsoftware: ApplicazioniSoftwareFunzioni) => {
  appsoftware.applicazioneSoftwareFunzioniGuida = [];
  appsoftware.applicazioneSoftwareFunzioneChildren = [];
  appsoftware.applicazioneSoftwareFunzioneParent = null;
  const response = await upd(appsoftware, microservice, entity);
  return response.data as ApplicazioniSoftwareFunzioni;
});

// delete/applicazioniSoftwareFunzioni/id/{idappsoftware}
export const physicalDel = createAsyncThunk(microservice + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const applicazioniSoftwareFunzioniSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    cleanLookup: (state: ApplicazioniSoftwareFunzioniState) => {
      state.lookup = {};
    },
    reset: (state: ApplicazioniSoftwareFunzioniState) => {
      return initialState;
    },
    resetError: (state: ApplicazioniSoftwareFunzioniState) => {
      state.error = initialState.error;
      state.statusValidapplicazioneSoftwareProfilo = initialState.statusValidapplicazioneSoftwareProfilo;
    }
  },
  extraReducers: builder => {

    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Failed;
      state.ApplicazioniSoftwareFunzioni = null;
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<ApplicazioniSoftwareFunzioni>) => {
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Succeeded;
      state.ApplicazioniSoftwareFunzioni = payload;
    })

    // fetchAll
    builder.addCase(fetchAll.pending, (state) => {
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Failed;
      state.validApplicazioniSoftwareFunzioni = [];
      state.lookup = {};
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<ApplicazioniSoftwareFunzioni[]>) => {
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Succeeded;
      state.validApplicazioniSoftwareFunzioni = payload ?? [];
      state.lookup = createLookup(payload, "idApplicazioneSoftwareFunzione", ["descrizione"])
    })

    // fetchAllbyidApplicazioneSoftware
    builder.addCase(fetchAllByIdApplicazioneSoftware.pending, (state) => {
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Loading;
    })
    builder.addCase(fetchAllByIdApplicazioneSoftware.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Failed;
      state.validApplicazioniSoftwareFunzioni = [];
      state.lookup = {};
    })
    builder.addCase(fetchAllByIdApplicazioneSoftware.fulfilled, (state, { payload }: PayloadAction<ApplicazioniSoftwareFunzioni[]>) => {
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Succeeded;
      state.validApplicazioniSoftwareFunzioni = payload ?? [];
      state.lookup = createLookup(payload, "idApplicazioneSoftwareFunzione", ["descrizione"])
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Failed;
      state.ApplicazioniSoftwareFunzioni = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<ApplicazioniSoftwareFunzioni>) => {
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Succeeded;
      state.ApplicazioniSoftwareFunzioni = payload;
      state.validApplicazioniSoftwareFunzioni = state.validApplicazioniSoftwareFunzioni.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Failed;
      state.ApplicazioniSoftwareFunzioni = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<ApplicazioniSoftwareFunzioni>) => {
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Succeeded;
      state.validApplicazioniSoftwareFunzioni = state.validApplicazioniSoftwareFunzioni.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.ApplicazioniSoftwareFunzioni = payload;
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Failed;
      state.validApplicazioniSoftwareFunzioni = [];
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Succeeded;
      state.validApplicazioniSoftwareFunzioni = state.validApplicazioniSoftwareFunzioni.filter(el => payload.ids.some(idObj => el[idObj.name as ApplicazioniSoftwareFunzioniKeys] !== idObj.id));
    })
  }
});

export const { cleanLookup, reset, resetError } = applicazioniSoftwareFunzioniSlice.actions;
export default applicazioniSoftwareFunzioniSlice.reducer;