import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, getAll, getAllByIds, upd } from '../../services/services.service'
import { UtentiAbilitazioni, elementIdProps, UtentiAbilitazioniKeys, } from '../../models/UtentiAbilitazioni';
import { createLookup } from '../../utils/utilfunctions';

const microservice = "utentiabilitazioni";
const entity = "utentiabilitazioni";

interface UtentiAblitazioniState {
  statusValidUtentiAbilitazioni: StatusEnum,
  validUtentiAbilitazioni: UtentiAbilitazioni[],
  utenti: UtentiAbilitazioni | null,
  lookup: Lookup,
  error: string | null
}

const initialState: UtentiAblitazioniState = {
  statusValidUtentiAbilitazioni: StatusEnum.Succeeded,
  validUtentiAbilitazioni: [],
  utenti: null,
  lookup: {},
  error: null
}

// @GetMapping("cerca/utentiabilitazioni/all/{idUtenti}")
export const fetchById = createAsyncThunk(microservice + '/fetchById', async (obj: { idUtente: number }) => {
  let ids = [obj.idUtente];
  const response = await getAllByIds(microservice, entity, ids);
  return response.data as UtentiAbilitazioni[];
});

// @GetMapping("cerca/utentiabilitazioni/all")
export const fetchAll = createAsyncThunk(microservice + '/fetchAll', async () => {
  const response = await getAll(microservice, entity);
  return response.data as UtentiAbilitazioni[];
});

// @PostMapping("inserisci/utentiabilitazioni")
export const insert = createAsyncThunk(microservice + '/insert', async (utenti: UtentiAbilitazioni) => {
  const response = await create(utenti, microservice, entity);
  return response.data as UtentiAbilitazioni;
});

// @PutMapping("modifica/utentiabilitazioni")
export const update = createAsyncThunk(microservice + '/update', async (utenti: UtentiAbilitazioni) => {
  const response = await upd(utenti, microservice, entity);
  return response.data as UtentiAbilitazioni;
});

// @DeleteMapping("delete/utentiabilitazioni/id/{idUtente}/{idOrganizzazione}/{idContratto}/{idApplicazioneSoftware}/{idApplicazioneSoftwareProfilo}/{abilitazioneInizio}")
export const physicalDel = createAsyncThunk(microservice + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const utentiAbilitazioniSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    cleanLookup: (state: UtentiAblitazioniState) => {
      return {
        ...state,
        lookup: {}
      };
    },
    reset: (state: UtentiAblitazioniState) => {
      return initialState;
    },
    resetError: (state: UtentiAblitazioniState) => {
      return {
        ...state,
        error: initialState.error,
        statusValidUtentiAbilitazioni: initialState.statusValidUtentiAbilitazioni
      };
    }
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidUtentiAbilitazioni = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUtentiAbilitazioni = StatusEnum.Failed;
      state.validUtentiAbilitazioni = [];
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<UtentiAbilitazioni[]>) => {
      state.statusValidUtentiAbilitazioni = StatusEnum.Succeeded;
      state.validUtentiAbilitazioni = payload;
    })

    // fetchAll
    builder.addCase(fetchAll.pending, (state) => {
      state.statusValidUtentiAbilitazioni = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUtentiAbilitazioni = StatusEnum.Failed;
      state.validUtentiAbilitazioni = [];
      state.lookup = {};
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<UtentiAbilitazioni[]>) => {
      state.statusValidUtentiAbilitazioni = StatusEnum.Succeeded;
      state.validUtentiAbilitazioni = payload ?? [];
      state.lookup = createLookup(payload, "idUtente", ["nomeUtente"])
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidUtentiAbilitazioni = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUtentiAbilitazioni = StatusEnum.Failed;
      state.utenti = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<UtentiAbilitazioni>) => {
      state.statusValidUtentiAbilitazioni = StatusEnum.Succeeded;
      state.utenti = payload;
      state.validUtentiAbilitazioni = state.validUtentiAbilitazioni.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidUtentiAbilitazioni = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUtentiAbilitazioni = StatusEnum.Failed;
      state.utenti = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<UtentiAbilitazioni>) => {
      state.statusValidUtentiAbilitazioni = StatusEnum.Succeeded;
      state.validUtentiAbilitazioni = state.validUtentiAbilitazioni.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.utenti = payload;
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusValidUtentiAbilitazioni = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUtentiAbilitazioni = StatusEnum.Failed;
      state.validUtentiAbilitazioni = [];
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidUtentiAbilitazioni = StatusEnum.Succeeded;
      state.validUtentiAbilitazioni = state.validUtentiAbilitazioni.filter(el => payload.ids.some(idObj => el[idObj.name as UtentiAbilitazioniKeys] !== idObj.id));
    })
  }
});

export const { cleanLookup, reset, resetError } = utentiAbilitazioniSlice.actions;
export default utentiAbilitazioniSlice.reducer;