import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, getAll, getNotExtendedEntityById, upd } from '../../services/services.service'
import { Organizzazione, elementIdProps, OrganizzazioneKeys, } from '../../models/Organizzazione';
import { createLookup } from '../../utils/utilfunctions';

const microservice = "organizzazioni";
const entity = "organizzazioni";

interface OrganizzazioneState {
  statusValidOrganizzazione: StatusEnum,
  validOrganizzazione: Organizzazione[],
  validContratti: Organizzazione[],
  organizzazione: Organizzazione | null,
  lookup: Lookup,
  error: string | null
}

const initialState: OrganizzazioneState = {
  statusValidOrganizzazione: StatusEnum.Succeeded,
  validOrganizzazione: [],
  validContratti: [],
  organizzazione: null,
  lookup: {},
  error: null
}

// @GetMapping("cerca/organizzazioni/id/{idOrganizzazione}")
export const fetchById = createAsyncThunk(entity + '/fetchById', async (obj: { idOrganizationInfo: number }) => {
  let ids = [obj.idOrganizationInfo];
  const response = await getNotExtendedEntityById(microservice, entity, ids);
  return response.data as Organizzazione;
});


// @GetMapping("cerca/organizzazioni/all")
export const fetchAll = createAsyncThunk(entity + '/fetchAll', async () => {
  const response = await getAll(microservice, entity);
  return response.data as Organizzazione[];
});


// @PostMapping("inserisci/organizzazioni")
export const insert = createAsyncThunk(entity + '/insert', async (OrganizationInfo: Organizzazione) => {
  const response = await create(OrganizationInfo, microservice, entity);
  return response.data as Organizzazione;
});

// @PutMapping("modifica/organizzazioni")
export const update = createAsyncThunk(entity + '/update', async (organizationInfo: Organizzazione) => {
  delete organizationInfo.organizzazioniNomiHost;

  const response = await upd(organizationInfo, microservice, entity);
  return response.data as Organizzazione;
});

// @DeleteMapping("delete/organizzazioni/id/{idOrganizationInfo}")
export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const organizzazioneSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    cleanLookup: (state: OrganizzazioneState) => {
      state.lookup = {};
    },
    reset: (state: OrganizzazioneState) => {
      return initialState;
    },
    resetError: (state: OrganizzazioneState) => {
      state.error = initialState.error;
      state.statusValidOrganizzazione = initialState.statusValidOrganizzazione;
    }
  },
  extraReducers: builder => {

    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidOrganizzazione = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidOrganizzazione = StatusEnum.Failed;
      state.organizzazione = null;
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<Organizzazione>) => {
      state.statusValidOrganizzazione = StatusEnum.Succeeded;
      state.organizzazione = payload;
    })

    // fetchAll
    builder.addCase(fetchAll.pending, (state) => {
      state.statusValidOrganizzazione = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidOrganizzazione = StatusEnum.Failed;
      state.validContratti = [];
      state.lookup = {};
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<Organizzazione[]>) => {
      state.statusValidOrganizzazione = StatusEnum.Succeeded;
      state.validContratti = payload ?? [];
      state.lookup = createLookup(payload, "idOrganizzazione", ["nome"])
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidOrganizzazione = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidOrganizzazione = StatusEnum.Failed;
      state.organizzazione = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<Organizzazione>) => {
      state.statusValidOrganizzazione = StatusEnum.Succeeded;
      state.organizzazione = payload;
      state.validContratti = state.validContratti.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidOrganizzazione = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidOrganizzazione = StatusEnum.Failed;
      state.organizzazione = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<Organizzazione>) => {
      state.statusValidOrganizzazione = StatusEnum.Succeeded;
      state.validContratti = state.validContratti.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.organizzazione = payload;
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusValidOrganizzazione = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidOrganizzazione = StatusEnum.Failed;
      state.validContratti = [];
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidOrganizzazione = StatusEnum.Succeeded;
      state.validContratti = state.validContratti.filter(el => payload.ids.some(idObj => el[idObj.name as OrganizzazioneKeys] !== idObj.id));
    })
  }
});

export const { cleanLookup, reset, resetError } = organizzazioneSlice.actions;
export default organizzazioneSlice.reducer;