import { Fields } from "./Fields";

export type FunzioniComponentiKeys = keyof FunzioniComponenti;

export const elementIdProps: FunzioniComponentiKeys[] = ["idApplicazioneSoftware", "idApplicazioneSoftwareComponente"];
export const elementRenderProps: FunzioniComponentiKeys[] = ["nomeEntita"];

export const allFields: Fields[] = [
  {
    field: "idApplicazioneSoftware", titleKey: "idApplicazioneSoftware", required: false, show: false,
  },
  {
    field: "idApplicazioneSoftware", titleKey: "idApplicazioneSoftware", required: false, show: false,
  },
  {
    field: "idApplicazioneSoftwareComponente", titleKey: "idApplicazioneSoftwareComponente", required: false, show: false,
  },
  {
    field: "titoloCodice", type: 'string', titleKey: "titleCodice", required: true, show: true,
  },
  {
    field: "nomeEntita", type: 'string', titleKey: "nameEntity", required: true, show: true,
  },
  {
    field: "tipoComponente", titleKey: "typeComponent", required: true, show: true, lookupField: true,
  },
  {
    field: "tipoComponenteAltezza", type: 'numeric', titleKey: "typeComponentheight", required: true, show: true,
  },
  {
    field: "origineDatiMicroservizio", type: 'string', titleKey: "originDateMicroservice", required: true, show: true,
  },
  {
    field: "origineDatiEntita", type: 'string', titleKey: "originDateEntity", required: true, show: true,
  },
  {
    field: "updateUriPath", type: 'string', titleKey: "updateUriPath", required: false, show: true,
  },
  {
    field: "insertUriPath", type: 'string', titleKey: "insertUriPath", required: false, show: true,
  },
  {
    field: "uriPath", type: 'string', titleKey: "uriPath", required: true, show: true,
  },
];

export interface FunzioniComponenti {
  idApplicazioneSoftware: number,
  idApplicazioneSoftwareComponente: number,
  titoloCodice: string,
  nomeEntita: string,
  tipoComponente: string,
  tipoComponenteAltezza: number,
  origineDatiMicroservizio: string,
  origineDatiEntita: string,
  uriPath: string,
  updateUriPath: string,
  insertUriPath: string,
  version: number,
}