import { Column } from "@material-table/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import i18n from "../../../i18n";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import { Fields } from "../../../models/Fields";
import { DizionarioDati, allFields, elementIdProps, elementRenderProps, DizionarioDatiKeys } from "../../../models/ApplicazioniSoftwareDizionarioDati";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { insert, update, physicalDel, fetchByIdDizionarioDati } from "../../../store/slices/applicazioniSoftwareDizionarioDatiSlice";
import { componentInsertPath, componentTabsPath } from "../../../utils/innerFuncPaths";
import { dizionaridatiPath, appSoftwarePath } from "../../../utils/utilconst";
import GeneralForm from "../forms/GeneralForm";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import { ExportType } from "../../../utils/utildata";

interface ApplicazioneSoftwareProfiliProps {
  idApplicazioneSoftware: number,
  dizionarioDati: DizionarioDati[]
}

const DizionarioDatiW = ({ idApplicazioneSoftware, dizionarioDati }: ApplicazioneSoftwareProfiliProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const abilitazione = useAppSelector(state => getAbilitazione(state, appSoftwarePath));
  const [obj, setObj] = useState<DizionarioDati | null>(null);

  const fixedProps = useMemo(() => {
    return {
      idApplicazioneSoftware: idApplicazioneSoftware,
    }
  }, [idApplicazioneSoftware])

  const insertCallback = () => {
    history.push(appSoftwarePath + componentTabsPath + dizionaridatiPath + componentInsertPath);
  };

  const updateDetailCallback = (dizionarioDati: DizionarioDati) => {
    setObj(dizionarioDati)
    history.push(appSoftwarePath + componentTabsPath + dizionaridatiPath + componentTabsPath)
  }

  const resetError = useCallback(() => {
    /**
     * dispatch delle chiamate per resettare gli errori
     */
  }, []);

  const validDizionarioDati = useAppSelector(state => state.dizionarioDati.validDizionarioDati);

  const statusValidDizionarioDati = useAppSelector(state => state.dizionarioDati.statusValidDizionarioDati);

  useEffect(() => {
    setData(validDizionarioDati);
  }, [validDizionarioDati]);

  const title = t('dataDictionary');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<DizionarioDati>>>([]);

  useEffect(() => {
    if (fixedProps.idApplicazioneSoftware) {
      dispatch(fetchByIdDizionarioDati({
        idApplicazioneSoftware: fixedProps.idApplicazioneSoftware as number,

      }));
    }
  }, [dispatch, fixedProps.idApplicazioneSoftware]);

  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<DizionarioDati> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ? f.removable : !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort ? f.sort : "asc",
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as DizionarioDatiKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;

        }
        return obj;
      })

    )
  }, [allFieldsState, excludedFieldInTable, t]);

  const errorBE = useAppSelector(state => state.dizionarioDati.error)

  const [data, setData] = useState<Array<DizionarioDati>>([]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  return (
    <>
      <Switch>
        <Route path={appSoftwarePath + componentTabsPath + dizionaridatiPath + componentInsertPath} exact>
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={appSoftwarePath + componentTabsPath + dizionaridatiPath}
            tabsUri={appSoftwarePath + componentTabsPath + dizionaridatiPath + componentInsertPath}
            tabsView={false}
            buttonTitle={t("dataDictionary")}
            info1={t("")}
            tabs={[
              {
                label: t(""),
                tabPath: "",
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={appSoftwarePath + componentTabsPath + dizionaridatiPath}
                        action={insert}
                        status={statusValidDizionarioDati}
                        error={errorBE}
                        update={false}
                        obj={{}}
                        fields={allFieldsState}
                        fixedProps={fixedProps}
                        translate={t}
                      />
                    </Grid>

                  </Grid>
                ),
                componentElse: <Redirect to={dizionaridatiPath} />,
              },
            ]}
          />

        </Route>
        <Route path={appSoftwarePath + componentTabsPath + dizionaridatiPath + componentTabsPath} exact>
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={appSoftwarePath + componentTabsPath + dizionaridatiPath}
            tabsUri={appSoftwarePath + componentTabsPath + dizionaridatiPath + componentTabsPath}
            tabsView={false}
            buttonTitle={t("dataDictionary")}
            info1={''}
            tabs={[
              {
                label: t("dataDictionary"),
                tabPath: "",
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={appSoftwarePath + componentTabsPath + dizionaridatiPath}
                        action={update}
                        status={statusValidDizionarioDati}
                        error={errorBE}
                        update={true}
                        obj={obj}
                        fields={allFieldsState}
                        fixedProps={fixedProps}
                        translate={t}
                      />
                    </Grid>
                  </Grid>
                ),
                componentElse: <Redirect to={dizionaridatiPath} />,
              },
            ]}
          />
        </Route>
        <Route path='*'>
          <CrudMaterialTableWithoutLogicDelete
            abilitazione={abilitazione}
            title={title}
            columns={columns}
            data={data}
            elementIdProps={elementIdProps}
            elementRenderProps={elementRenderProps}
            fetchAllValid={fetchByIdDizionarioDati}
            fixedProps={fixedProps}
            statusValid={statusValidDizionarioDati}
            errorBE={errorBE}
            logoUri={logoUri}
            resetErrorCallback={resetError}
            insertCallback={insertCallback}
            updateCallback={updateDetailCallback}
            detailCallback={updateDetailCallback}
            exportType={ExportType.NONE}
            extraOptions={{
              maxBodyHeight: 460,
            }}
            physicalDel={physicalDel} />
        </Route>

      </Switch>

    </>
  )
}
export default DizionarioDatiW;

