interface MyThemes {
  [key: string]: Object
}

/**
 * Override themes object to create a customized theme for the whole project
 */
export const themes: MyThemes =
{
  asl:
  {
    palette: {
      action: {
        selected: '#0B4787'
      },
      background: {
        paper: "#fff",
        default: "#fafafa"
      },
      primary: {
        main: "#0e59a8",
      },
      secondary: {
        main: "#e83519",
      },
      error: {
        main: "#d32f2f",
      },
    }
  }
};
export default themes;