import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UriToTitle {
  [uri:string]:string
}
interface RoutingState {
  title: string,
  uri: string
  guida: boolean,

}

// Define the initial state using that type
export const initialState: RoutingState = {
  title: '',
  uri: '',
  guida: true,
}

export const routingSlice = createSlice({
  name: 'routing',
  initialState,
  reducers: {
    navigateTo: (state, {payload}: PayloadAction<{uri:string,title:string, guida:boolean}>) => {
      state.uri = payload.uri;
      state.title = payload.title;
      state.guida = payload.guida;
    }
  }
})

export const { navigateTo } = routingSlice.actions

export default routingSlice.reducer