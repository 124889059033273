import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, getAll, getAllByIds, getAllValid, upd } from '../../services/services.service'
import { ComponentiFunzioni, ComponentiFunzioniKeys, elementIdProps } from '../../models/ComponentiFunzioni';
import { createLookup } from '../../utils/utilfunctions';

const microservice = "applicazionisoftwarefunzioni";
const entity = "appsoftwarefunzionicomponenti";

interface ComponentiFunzioniState {
  statusValidComponentiFunzioni: StatusEnum,
  validComponentiFunzioni: ComponentiFunzioni[],
  ComponentiFunzioni: ComponentiFunzioni | null,
  lookup: Lookup,
  error: string | null
}

const initialState: ComponentiFunzioniState = {
  statusValidComponentiFunzioni: StatusEnum.Succeeded,
  validComponentiFunzioni: [],
  ComponentiFunzioni: null,
  lookup: {},
  error: null
}

// @GetMapping(value = "/cerca/applicazionesoftwarecomponenti/all/valid/{idApplicazioneSoftware}{idApplicazioneSoftwareFunzione}"
export const fetchAllComponentiFunzioni = createAsyncThunk(entity + '/fetchAllComponentiFunzioni', async (obj: { idApplicazioneSoftware: number, idApplicazioneSoftwareFunzione: number, }) => {
  let ids = [obj.idApplicazioneSoftware, obj.idApplicazioneSoftwareFunzione];
  const response = await getAllByIds(microservice, entity, ids);
  return response.data as ComponentiFunzioni[];
});

//@GetMapping("cerca/appsoftware/all")
export const fetchAll = createAsyncThunk(entity + '/fetchAll', async () => {
  const response = await getAll(microservice, entity);
  return response.data as ComponentiFunzioni[];
});

//@GetMapping("cerca/appsoftware/allValid")
export const fetchAllValid = createAsyncThunk(entity + '/fetchAllValid', async () => {
  const response = await getAllValid(microservice, entity);
  return response.data as ComponentiFunzioni[];
});

// inserisci/appsoftware
export const insert = createAsyncThunk(entity + '/insert', async (appsoftwarefunzionicomponenti: ComponentiFunzioni) => {
  const response = await create(appsoftwarefunzionicomponenti, microservice, entity);
  return response.data as ComponentiFunzioni;
});

// modifica/appsoftware
export const update = createAsyncThunk(entity + '/update', async (appsoftwarefunzionicomponenti: ComponentiFunzioni) => {
  const response = await upd(appsoftwarefunzionicomponenti, microservice, entity);
  return response.data as ComponentiFunzioni;
});

// delete/appsoftware/id/{idappsoftware}
export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const componentiFunzioniSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    cleanLookup: (state: ComponentiFunzioniState) => {
      state.lookup = {};
    },
    reset: (state: ComponentiFunzioniState) => {
      return initialState;
    },
    resetError: (state: ComponentiFunzioniState) => {
      state.error = initialState.error;
      state.statusValidComponentiFunzioni = initialState.statusValidComponentiFunzioni;
    }
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchAllComponentiFunzioni.pending, (state) => {
      state.statusValidComponentiFunzioni = StatusEnum.Loading;
    })
    builder.addCase(fetchAllComponentiFunzioni.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidComponentiFunzioni = StatusEnum.Failed;
      state.ComponentiFunzioni = null;
    })
    builder.addCase(fetchAllComponentiFunzioni.fulfilled, (state, { payload }: PayloadAction<ComponentiFunzioni[]>) => {
      state.statusValidComponentiFunzioni = StatusEnum.Succeeded;
      state.validComponentiFunzioni = payload;
    })

    // fetchAll
    builder.addCase(fetchAll.pending, (state) => {
      state.statusValidComponentiFunzioni = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidComponentiFunzioni = StatusEnum.Failed;
      state.validComponentiFunzioni = [];
      state.lookup = {};
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<ComponentiFunzioni[]>) => {
      state.statusValidComponentiFunzioni = StatusEnum.Succeeded;
      state.validComponentiFunzioni = payload ?? [];
      state.lookup = createLookup(payload, "idApplicazioneSoftware", ["descrizione"])
    })

    // fetchAllValid
    builder.addCase(fetchAllValid.pending, (state) => {
      state.statusValidComponentiFunzioni = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValid.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidComponentiFunzioni = StatusEnum.Failed;
      state.validComponentiFunzioni = [];
      state.lookup = {};
    })
    builder.addCase(fetchAllValid.fulfilled, (state, { payload }: PayloadAction<ComponentiFunzioni[]>) => {
      state.statusValidComponentiFunzioni = StatusEnum.Succeeded;
      state.validComponentiFunzioni = payload ?? [];
      state.lookup = createLookup(payload, "idApplicazioneSoftware", ["descrizione"])
    })

    //   // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidComponentiFunzioni = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidComponentiFunzioni = StatusEnum.Failed;
      state.ComponentiFunzioni = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<ComponentiFunzioni>) => {
      state.statusValidComponentiFunzioni = StatusEnum.Succeeded;
      state.ComponentiFunzioni = payload;
      state.validComponentiFunzioni = state.validComponentiFunzioni.concat([payload]);
    })

    //   // update
    builder.addCase(update.pending, (state) => {
      state.statusValidComponentiFunzioni = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidComponentiFunzioni = StatusEnum.Failed;
      state.ComponentiFunzioni = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<ComponentiFunzioni>) => {
      state.statusValidComponentiFunzioni = StatusEnum.Succeeded;
      state.validComponentiFunzioni = state.validComponentiFunzioni.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.ComponentiFunzioni = payload;
    })

    //   // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusValidComponentiFunzioni = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidComponentiFunzioni = StatusEnum.Failed;
      state.validComponentiFunzioni = [];
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidComponentiFunzioni = StatusEnum.Succeeded;
      state.validComponentiFunzioni = state.validComponentiFunzioni.filter(el => payload.ids.some(idObj => el[idObj.name as ComponentiFunzioniKeys] !== idObj.id));
    })
  }
});

export const { cleanLookup, reset, resetError } = componentiFunzioniSlice.actions;
export default componentiFunzioniSlice.reducer;