import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, getAll, getNotExtendedEntityById, upd } from '../../services/services.service'
import { Contratto, ContrattoKeys, elementIdProps, } from '../../models/Contratti';
import { createLookup } from '../../utils/utilfunctions';

const microservice = "contratti";
const entity = "contratti";

interface ContrattiState {
  statusValidContratti: StatusEnum,
  validContratti: Contratto[],
  contratto: Contratto | null,
  lookup: Lookup,
  error: string | null
}

const initialState: ContrattiState = {
  statusValidContratti: StatusEnum.Succeeded,
  validContratti: [],
  contratto: null,
  lookup: {},
  error: null
}

//@GetMapping("cerca/contratti/id/{idConratto}")
export const fetchById = createAsyncThunk(microservice + '/fetchById', async (obj: { idContratto: number }) => {
  let ids = [obj.idContratto];
  const response = await getNotExtendedEntityById(microservice, entity, ids);
  return response.data as Contratto;
});


//@GetMapping("cerca/contratti/all")
export const fetchAll = createAsyncThunk(microservice + '/fetchAll', async () => {
  const response = await getAll(microservice, entity);
  return response.data as Contratto[];
});


// inserisci/contratto
export const insert = createAsyncThunk(microservice + '/insert', async (contratto: Contratto) => {
  const response = await create(contratto, microservice, entity);
  return response.data as Contratto;
});

// modifica/contratto
export const update = createAsyncThunk(microservice + '/update', async (contratto: Contratto) => {
  const response = await upd(contratto, microservice, entity);
  return response.data as Contratto;
});

// delete/contratto/id/{idContratto}
export const physicalDel = createAsyncThunk(microservice + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const contrattiSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    cleanLookup: (state: ContrattiState) => {
      state.lookup = {};
    },
    reset: (state: ContrattiState) => {
      return initialState;
    },
    resetError: (state: ContrattiState) => {
      state.error = initialState.error;
      state.statusValidContratti = initialState.statusValidContratti;
    }
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidContratti = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidContratti = StatusEnum.Failed;
      state.contratto = null;
      state.lookup = {};
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<Contratto>) => {
      state.statusValidContratti = StatusEnum.Succeeded;
      state.contratto = payload;
      state.lookup = createLookup([payload], "idContratto", ["organizzazioneNome", "applicazioneSoftwareDescrizione", "dataInizio", "dataFine"], null, " - ");
    })

    // fetchAll
    builder.addCase(fetchAll.pending, (state) => {
      state.statusValidContratti = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidContratti = StatusEnum.Failed;
      state.validContratti = [];
      state.lookup = {};
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<Contratto[]>) => {
      state.statusValidContratti = StatusEnum.Succeeded;
      state.validContratti = payload ?? [];
      state.lookup = createLookup(payload, "idContratto", ["organizzazioneNome", "applicazioneSoftwareDescrizione", "dataInizio", "dataFine"], null, " - ");
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidContratti = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidContratti = StatusEnum.Failed;
      state.contratto = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<Contratto>) => {
      state.statusValidContratti = StatusEnum.Succeeded;
      state.contratto = payload;
      state.validContratti = state.validContratti.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidContratti = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidContratti = StatusEnum.Failed;
      state.contratto = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<Contratto>) => {
      state.statusValidContratti = StatusEnum.Succeeded;
      state.validContratti = state.validContratti.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.contratto = payload;
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusValidContratti = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidContratti = StatusEnum.Failed;
      state.validContratti = [];
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidContratti = StatusEnum.Succeeded;
      state.validContratti = state.validContratti.filter(el => payload.ids.some(idObj => el[idObj.name as ContrattoKeys] !== idObj.id));
    })
  }
});

export const { cleanLookup, reset, resetError } = contrattiSlice.actions;
export default contrattiSlice.reducer;