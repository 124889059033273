import { Column } from "@material-table/core";
import { Grid, } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import i18n from "../../../i18n";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import { Fields } from "../../../models/Fields";
import { allFields, UtentiAbilitazioni, elementIdProps, elementRenderProps, UtentiAbilitazioniKeys } from "../../../models/UtentiAbilitazioni";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { fetchAllProfiliByIdAppSoftware } from "../../../store/slices/applicazioniSoftwareProfiliSlice";
import { fetchAll as fetchAllContratti, } from "../../../store/slices/contrattiSlice";
import { fetchAll as fetchAllOrganizzazioni, } from "../../../store/slices/organizzazioneSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { insert, update, physicalDel, fetchById } from "../../../store/slices/utentiAbilitazioniSlice";
import { componentInsertPath, componentTabsPath } from "../../../utils/innerFuncPaths";
import { utentiAbilitazioniPath, utentiPath } from "../../../utils/utilconst";
import GeneralForm, { OptionalArgs } from "../forms/GeneralForm";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import { fetchAll as fetchAllApplicazioniSoftware } from "../../../store/slices/applicazioniSoftwareSlice";
import { createLookup } from "../../../utils/utilfunctions";
import { ExportType } from "../../../utils/utildata";

interface UtentiAbilitazioniProps {
  idUtente: number,
}

const UtentiAbilitazioniW = ({ idUtente }: UtentiAbilitazioniProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const abilitazione = useAppSelector(state => getAbilitazione(state, utentiPath));
  const [obj, setObj] = useState<UtentiAbilitazioni | null>(null);

  const fixedProps = useMemo(() => {
    return {
      idUtente: idUtente
    }
  }, [idUtente])

  const insertCallback = () => {
    fetchLookups();
    history.push(utentiPath + componentTabsPath + utentiAbilitazioniPath + componentInsertPath);
  };

  const updateDetailCallback = (utenti: UtentiAbilitazioni) => {
    setObj(utenti)
    fetchLookups();
    dispatch(fetchAllProfiliByIdAppSoftware({ idApplicazioneSoftware: utenti?.idApplicazioneSoftware }));
    history.push(utentiPath + componentTabsPath + utentiAbilitazioniPath + componentTabsPath)
  }

  const fetchLookups = () => {
    dispatch(fetchAllContratti());
    dispatch(fetchAllOrganizzazioni());
    dispatch(fetchAllApplicazioniSoftware());
  }

  const resetError = useCallback(() => {
    /**
     * dispatch delle chiamate per resettare gli errori
     */
  }, []);

  const contrattiLookup = useAppSelector(state => state.contratti.lookup);
  const organizzazioniLookup = useAppSelector(state => state.organizzazioni.lookup);
  const applicazioniSoftwareProfiloLookup = useAppSelector(state => state.applicazioniSoftwareProfilo.lookup);
  const applicazioneSoftwareLookup = useAppSelector(state => state.applicazioniSoftware.lookup);

  const validUtentiAbilitazioni = useAppSelector(state => state.utentiAbilitazioni.validUtentiAbilitazioni);
  const statusValidUtentiAbilitazioni = useAppSelector(state => state.utentiAbilitazioni.statusValidUtentiAbilitazioni);
  const errorBE = useAppSelector(state => state.utentiAbilitazioni.error)

  const contrattiLookupTable = useMemo(() => {
    return createLookup(validUtentiAbilitazioni, "idContratto", ["nomeOrganizzazione", "applicazioneSoftwareDescrizione", "abilitazioneInizio", "abilitazioneFine",], null, " - ")
  }, [validUtentiAbilitazioni])

  const validContratti = useAppSelector(state => state.contratti.validContratti);
  const idContratto = "idContratto";

  const formLookups = useMemo(() => {
    return {
      idContratto: contrattiLookup,
      idOrganizzazione: organizzazioniLookup,
      idApplicazioneSoftwareProfilo: applicazioniSoftwareProfiloLookup,
      idApplicazioneSoftware: applicazioneSoftwareLookup,
    }
  }, [contrattiLookup, organizzazioniLookup, applicazioniSoftwareProfiloLookup, applicazioneSoftwareLookup])

  const formCallback = useCallback((formObject: UtentiAbilitazioni, field: string, optionalArgs: OptionalArgs<UtentiAbilitazioni>) => {
    const { setInternalObj } = optionalArgs;

    if (formObject) {
      if (field === idContratto && formObject.idContratto) {
        const contratto = validContratti.find(elem => {
          return elem.idContratto.toString() === formObject.idContratto.toString()
        })

        if (contratto) {
          dispatch(fetchAllProfiliByIdAppSoftware({ idApplicazioneSoftware: contratto.idApplicazioneSoftware }))
          setInternalObj(state => {
            const newValue: Partial<UtentiAbilitazioni> = {
              ...state,
              idUtente: idUtente,
              idOrganizzazione: contratto.idOrganizzazione,
              idApplicazioneSoftware: contratto.idApplicazioneSoftware,
              idApplicazioneSoftwareProfilo: undefined,
            }

            return newValue as UtentiAbilitazioni;
          })
        }
      }
    }
  }, [dispatch, idUtente, validContratti]);

  const title = t('UsersParams');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<UtentiAbilitazioni>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<UtentiAbilitazioni> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ? f.removable : !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort ? f.sort : "asc",
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as UtentiAbilitazioniKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        /*+++*/
        switch (f.field) {
          case idContratto:
            obj.lookup = contrattiLookupTable;
            break;
        }
        /*+++*/
        return obj;
      })
    )
  }, [allFieldsState, contrattiLookupTable, excludedFieldInTable, organizzazioniLookup, t]);

  const [data, setData] = useState<Array<UtentiAbilitazioni>>([]);
  useEffect(() => {
    setData(validUtentiAbilitazioni);
  }, [validUtentiAbilitazioni]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
      resetError();
    };
  }, [resetError]);

  return (
    <>
      <Switch>
        <Route path={utentiPath + componentTabsPath + utentiAbilitazioniPath + componentInsertPath} exact>
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={utentiPath + componentTabsPath + utentiAbilitazioniPath}
            tabsUri={utentiPath + componentTabsPath + utentiAbilitazioniPath + componentInsertPath}
            tabsView={false}
            buttonTitle={t("UsersParams")}
            info1={t("")}
            tabs={[
              {
                label: t("UsersParams"),
                tabPath: "",
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={utentiPath + componentTabsPath + utentiAbilitazioniPath}
                        action={insert}
                        status={statusValidUtentiAbilitazioni}
                        error={errorBE}
                        update={false}
                        fields={allFieldsState}
                        lookups={formLookups}
                        checksCallback={formCallback}
                        translate={t}
                      />
                    </Grid>
                  </Grid>
                ),
                componentElse: <Redirect to={utentiAbilitazioniPath} />,
              },
            ]}
          />
        </Route>
        <Route path={utentiPath + componentTabsPath + utentiAbilitazioniPath + componentTabsPath} exact>
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={utentiPath + componentTabsPath + utentiAbilitazioniPath}
            tabsUri={utentiPath + componentTabsPath + utentiAbilitazioniPath + componentTabsPath}
            tabsView={false}
            buttonTitle={t("UsersParams")}
            info1={t("")}
            tabs={[
              {
                label: t("UsersParams"),
                tabPath: "",
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={utentiPath + componentTabsPath + utentiAbilitazioniPath}
                        action={update}
                        status={statusValidUtentiAbilitazioni}
                        error={errorBE}
                        update={true}
                        obj={obj}
                        fields={allFieldsState}
                        lookups={formLookups}
                        translate={t}
                      />
                    </Grid>
                  </Grid>
                ),
                componentElse: <Redirect to={utentiAbilitazioniPath} />,
              },
            ]}
          />
        </Route>
        <Route path='*'>
          <CrudMaterialTableWithoutLogicDelete
            abilitazione={abilitazione}
            title={title}
            columns={columns}
            data={data}
            elementIdProps={elementIdProps}
            elementRenderProps={elementRenderProps}
            fetchAllValid={fetchById}
            fixedProps={fixedProps}
            statusValid={statusValidUtentiAbilitazioni}
            errorBE={errorBE}
            logoUri={logoUri}
            resetErrorCallback={resetError}
            insertCallback={insertCallback}
            updateCallback={updateDetailCallback}
            detailCallback={updateDetailCallback}
            exportType={ExportType.NONE}
            extraOptions={{
              maxBodyHeight: 460,
            }}
            physicalDel={physicalDel}
            localizedDatePicker={true}
          />
        </Route>
      </Switch>
    </>
  )
}

export default UtentiAbilitazioniW;