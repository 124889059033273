import AccountCircle from "@material-ui/icons/AccountCircle";
import { IconButton, Tooltip } from "@material-ui/core";
import { getCookie } from "../../../utils/utilfunctions";

interface Utente {
  nome: string,
  cognome: string,
  nomeUtente: string,
  organizzazioneDescrizione: string,
  applicazioneSoftwareDescrizione: string,
  profiloDescrizione: string,
}

const ButtonUser = () => {
  const _utente = getCookie('utente')
  const utente: Utente = _utente ? JSON.parse(_utente) : null;

  return <>
    <Tooltip title={
      <div style={{
        fontSize: 15,
        padding: 5,
        lineHeight: 2
      }
      }>
        {utente?.nomeUtente}<br></br>
        Nominativo : {utente?.nome + ' ' + utente?.cognome}<br></br>
        Organizzazione : {utente?.organizzazioneDescrizione}<br></br>
        Profilo : {utente?.profiloDescrizione}<br></br>
      </div>
    }
    >
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
    </Tooltip>

  </>
}
export default ButtonUser;

