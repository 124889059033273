import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, getAllByIds, upd } from '../../services/services.service'
import { ApplicazioneSoftwareProfilo, ApplicazioneSoftwareProfiloKeys, elementIdProps } from '../../models/ApplicazioniSoftwareProfili';
import { createLookup } from '../../utils/utilfunctions';

const microservice = "appsoftware";
const entity = "applicazionisoftwareprofili";

interface ApplicazioneSoftwareProfiloState {
  statusValidapplicazioneSoftwareProfilo: StatusEnum,
  validApplicazioneSoftwareProfilo: ApplicazioneSoftwareProfilo[],
  contratto: ApplicazioneSoftwareProfilo | null,
  ApplicazioneSoftwareProfilo: ApplicazioneSoftwareProfilo | null,
  lookup: Lookup,
  error: string | null
}

const initialState: ApplicazioneSoftwareProfiloState = {
  statusValidapplicazioneSoftwareProfilo: StatusEnum.Succeeded,
  validApplicazioneSoftwareProfilo: [],
  contratto: null,
  ApplicazioneSoftwareProfilo: null,
  lookup: {},
  error: null
}

//@GetMapping("cerca/ApplicazioneSoftwareProfilo/all/appSoftware/{idApplicazioneSoftware")
export const fetchAllProfiliByIdAppSoftware = createAsyncThunk(microservice + '/fetchallProfiliByIdAppSoftware', async (obj: { idApplicazioneSoftware: number }) => {
  let ids = ['appsoftware', obj.idApplicazioneSoftware];
  const response = await getAllByIds(microservice, entity, ids);
  return response.data as ApplicazioneSoftwareProfilo[];
});

// inserisci/applicazioniSoftware
export const insert = createAsyncThunk(microservice + '/insert', async (appsoftware: ApplicazioneSoftwareProfilo) => {
  const response = await create(appsoftware, microservice, entity);
  return response.data as ApplicazioneSoftwareProfilo;
});

// modifica/applicazioniSoftware
export const update = createAsyncThunk(microservice + '/update', async (appsoftware: ApplicazioneSoftwareProfilo) => {
  const response = await upd(appsoftware, microservice, entity);
  return response.data as ApplicazioneSoftwareProfilo;
});

// delete/applicazioniSoftware/id/{idappsoftware}
export const physicalDel = createAsyncThunk(microservice + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const applicazioneSoftwareProfiloSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    cleanLookup: (state: ApplicazioneSoftwareProfiloState) => {
      state.lookup = {};
    },
    reset: (state: ApplicazioneSoftwareProfiloState) => {
      return initialState;
    },
    resetError: (state: ApplicazioneSoftwareProfiloState) => {
      state.error = initialState.error;
      state.statusValidapplicazioneSoftwareProfilo = initialState.statusValidapplicazioneSoftwareProfilo;
    }
  },
  extraReducers: builder => {
    // fetchAllProfiliByIdAppSoftware
    builder.addCase(fetchAllProfiliByIdAppSoftware.pending, (state) => {
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Loading;
    })
    builder.addCase(fetchAllProfiliByIdAppSoftware.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Failed;
      state.validApplicazioneSoftwareProfilo = [];
      state.lookup = {};
    })
    builder.addCase(fetchAllProfiliByIdAppSoftware.fulfilled, (state, { payload }: PayloadAction<ApplicazioneSoftwareProfilo[]>) => {
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Succeeded;
      state.validApplicazioneSoftwareProfilo = payload ?? [];
      state.lookup = createLookup(payload, "idApplicazioneSoftwareProfilo", ["descrizione"])
    })

    //   // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Failed;
      state.validApplicazioneSoftwareProfilo = [];
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<ApplicazioneSoftwareProfilo>) => {
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Succeeded;
      state.ApplicazioneSoftwareProfilo = payload;
      state.validApplicazioneSoftwareProfilo = state.validApplicazioneSoftwareProfilo.concat([payload]);
    })

    //   // update
    builder.addCase(update.pending, (state) => {
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Failed;
      state.ApplicazioneSoftwareProfilo = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<ApplicazioneSoftwareProfilo>) => {
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Succeeded;
      state.validApplicazioneSoftwareProfilo = state.validApplicazioneSoftwareProfilo.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.ApplicazioneSoftwareProfilo = payload;
    })

    //   // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Failed;
      state.validApplicazioneSoftwareProfilo = [];
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidapplicazioneSoftwareProfilo = StatusEnum.Succeeded;
      state.validApplicazioneSoftwareProfilo = state.validApplicazioneSoftwareProfilo.filter(el => payload.ids.some(idObj => el[idObj.name as ApplicazioneSoftwareProfiloKeys] !== idObj.id));
    })
  }
});

export const { cleanLookup, reset, resetError } = applicazioneSoftwareProfiloSlice.actions;
export default applicazioneSoftwareProfiloSlice.reducer;