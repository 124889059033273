import { Column } from "@material-table/core";
import { Grid, } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import i18n from "../../../i18n";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import { Fields } from "../../../models/Fields";
import { allFields, Contratto, ContrattoKeys, elementIdProps, elementRenderProps } from "../../../models/Contratti";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { fetchAll, insert, update, physicalDel } from "../../../store/slices/contrattiSlice";
import { fetchAll as fetchAllOrganizzazione } from "../../../store/slices/organizzazioneSlice";
import { fetchAll as fetchAllApplicazioniSotware } from "../../../store/slices/applicazioniSoftwareSlice";
import { componentInsertPath, componentTabsPath } from "../../../utils/innerFuncPaths";
import { contrattiPath } from "../../../utils/utilconst";
import GeneralForm, { OptionalArgs } from "../forms/GeneralForm";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import { ExportType } from "../../../utils/utildata";
import { fetchAllProfiliByIdAppSoftware } from "../../../store/slices/applicazioniSoftwareProfiliSlice";

const ContrattiW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);

  const abilitazione = useAppSelector(state => getAbilitazione(state, contrattiPath));

  const [obj, setObj] = useState<Contratto | null>(null);

  const insertCallback = () => {
    history.push(contrattiPath + componentInsertPath);
  };

  const updateDetailCallback = (contratto: Contratto) => {
    setObj(contratto)
    if (contratto.idApplicazioneSoftware)
      dispatch(fetchAllProfiliByIdAppSoftware({
        idApplicazioneSoftware: contratto.idApplicazioneSoftware,
      }))

    history.push(contrattiPath + componentTabsPath)
  }

  const resetError = useCallback(() => {
    /**
     * dispatch delle chiamate per resettare gli errori
     */
  }, []);


  const formCallback = useCallback((formObject: Contratto | null, field: string, optionalArgs: OptionalArgs<Contratto>) => {
    if (formObject) {
      if (field === "idApplicazioneSoftware" && formObject.idApplicazioneSoftware) {
        dispatch(fetchAllProfiliByIdAppSoftware({
          idApplicazioneSoftware: formObject["idApplicazioneSoftware"]
        }))
      }

      setObj(state => {
        return {
          ...state,
          ...formObject
        }
      })
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAllOrganizzazione());
    dispatch(fetchAllApplicazioniSotware());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAll());
  }, [dispatch]);

  const organizzazioneLookup = useAppSelector(state => state.organizzazioni.lookup);
  const applicazioniSoftwareLookup = useAppSelector(state => state.applicazioniSoftware.lookup);
  const profiloLookup = useAppSelector(state => state.applicazioniSoftwareProfilo.lookup);


  const idOrganizzazione = 'idOrganizzazione';
  const idApplicazioneSoftware = 'idApplicazioneSoftware';
  const idApplicazioneSoftwareProfiloDefault = 'idApplicazioneSoftwareProfiloDefault';

  const formLookups = useMemo(() => {
    return {
      idOrganizzazione: organizzazioneLookup,
      idApplicazioneSoftware: applicazioniSoftwareLookup,
      idApplicazioneSoftwareProfiloDefault: profiloLookup,

    }
  }, [applicazioniSoftwareLookup, organizzazioneLookup, profiloLookup])


  const title = t('contractParams');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<Contratto>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<Contratto> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ? f.removable : !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort ? f.sort : "asc",
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as ContrattoKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        /*+++*/
        switch (f.field) {
          case idOrganizzazione:
            obj.lookup = organizzazioneLookup;
            break;
          case idApplicazioneSoftware:
            obj.lookup = applicazioniSoftwareLookup;
            break;
          case idApplicazioneSoftwareProfiloDefault:
            obj.lookup = profiloLookup;
            break;
        }

        /*+++*/


        return obj;


      })

    )
  }, [allFieldsState, applicazioniSoftwareLookup, excludedFieldInTable, organizzazioneLookup, profiloLookup, t]);

  const validContratti = useAppSelector(state => state.contratti.validContratti);

  const statusValidContratti = useAppSelector(state => state.contratti.statusValidContratti);

  const errorBE = useAppSelector(state => state.contratti.error)

  const [data, setData] = useState<Array<object>>([]);

  useEffect(() => {
    setData(validContratti);
  }, [validContratti]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);






  return (
    <>

      <Switch>
        <Route path={contrattiPath} exact>
          <CrudMaterialTableWithoutLogicDelete
            abilitazione={abilitazione}
            title={title}
            columns={columns}
            data={data}
            elementIdProps={elementIdProps}
            elementRenderProps={elementRenderProps}
            fetchAllValid={fetchAll}
            statusValid={statusValidContratti}
            errorBE={errorBE}
            logoUri={logoUri}
            resetErrorCallback={resetError}
            insertCallback={insertCallback}
            updateCallback={updateDetailCallback}
            detailCallback={updateDetailCallback}
            exportType={ExportType.NONE}
            extraOptions={{
              maxBodyHeight: 460,
            }}
            physicalDel={physicalDel}
            localizedDatePicker={true}
          />
        </Route>
        <Route path={contrattiPath + componentInsertPath} exact>
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={contrattiPath}
            tabsUri={contrattiPath + componentInsertPath}
            tabsView={false}
            buttonTitle={t("contractParams")}
            info1={t("")}
            tabs={[
              {
                label: t(""),
                tabPath: "",
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={contrattiPath}
                        action={insert}
                        status={statusValidContratti}
                        error={errorBE}
                        update={false}
                        obj={obj}
                        fields={allFieldsState}
                        lookups={formLookups}
                        translate={t}
                        checksCallback={formCallback}
                      />
                    </Grid>

                  </Grid>
                ),
                componentElse: <Redirect to={contrattiPath} />,
              },
            ]}
          />

        </Route>
        <Route path={contrattiPath + componentTabsPath} exact>
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={contrattiPath}
            tabsUri={contrattiPath + componentTabsPath}
            tabsView={false}
            buttonTitle={t("contractParams")}
            info1={t("")}
            tabs={[
              {
                label: t("contractParams"),
                tabPath: "",
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={contrattiPath}
                        action={update}
                        status={statusValidContratti}
                        error={errorBE}
                        update={true}
                        obj={obj}
                        fields={allFieldsState}
                        lookups={formLookups}
                        checksCallback={formCallback}
                        translate={t}
                      />
                    </Grid>

                  </Grid>
                ),
                componentElse: <Redirect to={contrattiPath} />,
              },
            ]}
          />

        </Route>
      </Switch>

    </>

  )
}
export default ContrattiW;




