import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, getAll, getAllValidByIds, getById, upd } from '../../services/services.service'
import { ApplicazioniSoftwareFunzioniGuida, ApplicazioniSoftwareFunzioniGuidaKeys, elementIdProps } from '../../models/ApplicazioniSoftwareFunzioniGuida';

const microservice = "applicazionisoftwarefunzioni";
const entity = "applicazionisoftwarefunzioniguida";

interface applicazioniSoftwareFunzioniGuidaState {
  statusValidapplicazioniSoftwareFunzioniGuida: StatusEnum,
  validApplicazioniSoftwareFunzioniGuida: ApplicazioniSoftwareFunzioniGuida[],
  ApplicazioniSoftwareFunzioniGuida: ApplicazioniSoftwareFunzioniGuida | null,
  lookup: Lookup,
  error: string | null
}

const initialState: applicazioniSoftwareFunzioniGuidaState = {
  statusValidapplicazioniSoftwareFunzioniGuida: StatusEnum.Succeeded,
  validApplicazioniSoftwareFunzioniGuida: [],
  ApplicazioniSoftwareFunzioniGuida: null,
  lookup: {},
  error: null
}

// @GetMapping("/cerca/applicazionisoftwarefunzioniguida/id/{idApplicazioneSoftware}/{idApplicazioneSoftwareFunzione}")
export const fetchById = createAsyncThunk(microservice + '/fetchById', async (obj: { idApplicazioneSoftware: number, idApplicazioneSoftwareFunzione: number }) => {
  const ids = [obj.idApplicazioneSoftware, obj.idApplicazioneSoftwareFunzione]
  const response = await getById(microservice, entity, ids);
  return response.data as ApplicazioniSoftwareFunzioniGuida[];
});

// @GetMapping("/cerca/applicazionisoftwarefunzioniguida/allValid/{idApplicazioneSoftware}/{idApplicazioneSoftwareFunzione}")
export const fetchAllValid = createAsyncThunk(microservice + '/fetchAllValid', async (obj: { idApplicazioneSoftware: number, idApplicazioneSoftwareFunzione: number }) => {
  const ids = [obj.idApplicazioneSoftware, obj.idApplicazioneSoftwareFunzione]
  const response = await getAllValidByIds(microservice, entity, ids, ['fetchAllValid']);
  return response.data as ApplicazioniSoftwareFunzioniGuida[];
});

// @GetMapping("cerca/applicazionisoftwarefunzioniguida/all")
export const fetchAll = createAsyncThunk(microservice + '/fetchAll', async () => {
  const response = await getAll(microservice, entity,);
  return response.data as ApplicazioniSoftwareFunzioniGuida[];
});

// inserisci/applicazionisoftwarefunzionioperazioni
export const insert = createAsyncThunk(microservice + '/insert', async (appsoftware: ApplicazioniSoftwareFunzioniGuida) => {
  const response = await create(appsoftware, microservice, entity);
  return response.data as ApplicazioniSoftwareFunzioniGuida;
});

// modifica/applicazionisoftwarefunzionioperazioni
export const update = createAsyncThunk(microservice + '/update', async (appsoftware: ApplicazioniSoftwareFunzioniGuida) => {
  const response = await upd(appsoftware, microservice, entity);
  return response.data as ApplicazioniSoftwareFunzioniGuida;
});

// delete/applicazionisoftwarefunzionioperazioni/id/
export const physicalDel = createAsyncThunk(microservice + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const applicazioniSoftwareFunzioniGuidaSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    cleanLookup: (state: applicazioniSoftwareFunzioniGuidaState) => {
      state.lookup = {};
    },
    reset: (state: applicazioniSoftwareFunzioniGuidaState) => {
      return initialState;
    },
    resetError: (state: applicazioniSoftwareFunzioniGuidaState) => {
      state.error = initialState.error;
      state.statusValidapplicazioniSoftwareFunzioniGuida = initialState.statusValidapplicazioniSoftwareFunzioniGuida;
    }
  },
  extraReducers: builder => {

    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidapplicazioniSoftwareFunzioniGuida = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioniSoftwareFunzioniGuida = StatusEnum.Failed;
      state.validApplicazioniSoftwareFunzioniGuida = [];
      state.lookup = {};
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<ApplicazioniSoftwareFunzioniGuida[]>) => {
      state.statusValidapplicazioniSoftwareFunzioniGuida = StatusEnum.Succeeded;
      state.validApplicazioniSoftwareFunzioniGuida = payload ?? [];

    })

    // fetchAllValid
    builder.addCase(fetchAllValid.pending, (state) => {
      state.statusValidapplicazioniSoftwareFunzioniGuida = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValid.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioniSoftwareFunzioniGuida = StatusEnum.Failed;
      state.validApplicazioniSoftwareFunzioniGuida = [];
      state.lookup = {};
    })
    builder.addCase(fetchAllValid.fulfilled, (state, { payload }: PayloadAction<ApplicazioniSoftwareFunzioniGuida[]>) => {
      state.statusValidapplicazioniSoftwareFunzioniGuida = StatusEnum.Succeeded;
      state.validApplicazioniSoftwareFunzioniGuida = payload ?? [];

    })


    // fetchAll
    builder.addCase(fetchAll.pending, (state) => {
      state.statusValidapplicazioniSoftwareFunzioniGuida = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioniSoftwareFunzioniGuida = StatusEnum.Failed;
      state.validApplicazioniSoftwareFunzioniGuida = [];
      state.lookup = {};
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<ApplicazioniSoftwareFunzioniGuida[]>) => {
      state.statusValidapplicazioniSoftwareFunzioniGuida = StatusEnum.Succeeded;
      state.validApplicazioniSoftwareFunzioniGuida = payload ?? [];
    })


    //   // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidapplicazioniSoftwareFunzioniGuida = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioniSoftwareFunzioniGuida = StatusEnum.Failed;
      state.validApplicazioniSoftwareFunzioniGuida = [];
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<ApplicazioniSoftwareFunzioniGuida>) => {
      state.statusValidapplicazioniSoftwareFunzioniGuida = StatusEnum.Succeeded;
      state.ApplicazioniSoftwareFunzioniGuida = payload;
      state.validApplicazioniSoftwareFunzioniGuida = state.validApplicazioniSoftwareFunzioniGuida.concat([payload]);
    })

    //   // update
    builder.addCase(update.pending, (state) => {
      state.statusValidapplicazioniSoftwareFunzioniGuida = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioniSoftwareFunzioniGuida = StatusEnum.Failed;
      state.ApplicazioniSoftwareFunzioniGuida = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<ApplicazioniSoftwareFunzioniGuida>) => {
      state.statusValidapplicazioniSoftwareFunzioniGuida = StatusEnum.Succeeded;
      state.validApplicazioniSoftwareFunzioniGuida = state.validApplicazioniSoftwareFunzioniGuida.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.ApplicazioniSoftwareFunzioniGuida = payload;
    })

    //   // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusValidapplicazioniSoftwareFunzioniGuida = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidapplicazioniSoftwareFunzioniGuida = StatusEnum.Failed;
      state.validApplicazioniSoftwareFunzioniGuida = [];
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidapplicazioniSoftwareFunzioniGuida = StatusEnum.Succeeded;
      state.validApplicazioniSoftwareFunzioniGuida = state.validApplicazioniSoftwareFunzioniGuida.filter(el => payload.ids.some(idObj => el[idObj.name as ApplicazioniSoftwareFunzioniGuidaKeys] !== idObj.id));
    })
  }
});

export const { cleanLookup, reset, resetError } = applicazioniSoftwareFunzioniGuidaSlice.actions;
export default applicazioniSoftwareFunzioniGuidaSlice.reducer;