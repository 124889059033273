export enum StatusEnum {
    Loading = "LOADING",
    Succeeded = "SUCCEEDED",
    Failed = "FAILED"
}

export interface ErrorResponse {
    data: string,
    codice: number,
    messaggio: string
}

export interface SimpleResponse {
    code: string,
    message: string
}

export interface Ids<T> {
    id: any
    name: T,
}

export interface IdsConfig {
    id: number;
    descrizione: string;
    descrizioneBreve: string;
}

export interface PDFExtraData {
    head?: {
        title: string[],
        value: any[],
    };
    extra?: {
        title: string[],
        value: any[],
    }[];
}

export interface PDFOptions {
    bodyFontSize?: number;
}

export interface Lookup {
    [id: number | string]: string;
}

export interface LookupObject {
    [id: number | string]: object;
}

export type TableLayout = "fixed" | "auto";
