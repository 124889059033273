import { Column } from "@material-table/core";
import { Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import i18n from "../../../i18n";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import { Fields } from "../../../models/Fields";
import { allFields, ApplicazioniSoftwareFunzioniOperazioni, ApplicazioniSoftwareFunzioniOperazioniKeys, elementIdProps, elementRenderProps } from "../../../models/ApplicazioniSoftwareFunzioniOperazioni";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { insert, update, physicalDel, fetchById, } from "../../../store/slices/applicazioniSoftwareFunzioniOperazioniSlice";
import { componentInsertPath, componentTabsPath } from "../../../utils/innerFuncPaths";
import { applicazioniSoftwareFunzioniOperazioniPath, applicazioniSoftwareFunzioniPath } from "../../../utils/utilconst";
import GeneralForm from "../forms/GeneralForm";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import { ExportType } from "../../../utils/utildata";

interface ApplicazioniSoftwareProfiliFunzioniProps {
  idApplicazioneSoftware: number,
  idApplicazioneSoftwareFunzione: number,
  applicazioneSoftwareDescrizione: string,
  applicazioneSoftwareFunzioneDescrizione: string,
}

const FunzioniOperazioniW = ({ idApplicazioneSoftwareFunzione, idApplicazioneSoftware, applicazioneSoftwareDescrizione, applicazioneSoftwareFunzioneDescrizione }: ApplicazioniSoftwareProfiliFunzioniProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const abilitazione = useAppSelector(state => getAbilitazione(state, applicazioniSoftwareFunzioniPath));
  const [obj, setObj] = useState<ApplicazioniSoftwareFunzioniOperazioni | null>(null);


  const insertCallback = () => {
    const initValue: Partial<ApplicazioniSoftwareFunzioniOperazioni> = {
      idApplicazioneSoftware: idApplicazioneSoftware,
      applicazioneSoftwareDescrizione: applicazioneSoftwareDescrizione,
      idApplicazioneSoftwareFunzione: idApplicazioneSoftwareFunzione,
      applicazioneSoftwareFunzioneDescrizione: applicazioneSoftwareFunzioneDescrizione,
    };

    setObj(initValue as ApplicazioniSoftwareFunzioniOperazioni);
    history.push(applicazioniSoftwareFunzioniPath + componentTabsPath + applicazioniSoftwareFunzioniOperazioniPath + componentInsertPath);
  };

  const resetError = useCallback(() => {
    /**
     * dispatch delle chiamate per resettare gli errori
     */
  }, []);

  const title = t('applicationSoftwareOperations');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<ApplicazioniSoftwareFunzioniOperazioni>>>([]);

  const fixedProps = useMemo(() => {
    return {
      idApplicazioneSoftwareFunzione: idApplicazioneSoftwareFunzione,
      idApplicazioneSoftware: idApplicazioneSoftware,
    }
  }, [idApplicazioneSoftware, idApplicazioneSoftwareFunzione])

  const abilitazioneLookup = useAppSelector(state => state.lookup.abilitazioneLookup)

  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<ApplicazioniSoftwareFunzioniOperazioni> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ? f.removable : !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort ? f.sort : "asc",
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as ApplicazioniSoftwareFunzioniOperazioniKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        if (f.lookupField)
          switch (f.field) {
            case 'abilitazioni':
              obj.lookup = abilitazioneLookup;
              break;
          }
        return obj;
      })

    )
  }, [abilitazioneLookup, allFieldsState, excludedFieldInTable, t]);

  const formLookup = useMemo(() => {
    return {
      abilitazioni: abilitazioneLookup,
    }
  }, [abilitazioneLookup]);

  const validApplicazioniSoftwareFunzioniOperazioni = useAppSelector(state => state.applicazioniSoftwareFunzioniOperazioni.validApplicazioniSoftwareFunzioniOperazioni);
  const statusValidapplicazioniSoftwareFunzioniOperazioni = useAppSelector(state => state.applicazioniSoftwareFunzioniOperazioni.statusValidapplicazioniSoftwareFunzioniOperazioni);

  const errorBE = useAppSelector(state => state.applicazioniSoftwareProfiliFunzioni.error)

  const [data, setData] = useState<Array<ApplicazioniSoftwareFunzioniOperazioni>>([]);

  const formLookups = useMemo(() => {
    const lookup: Record<string, unknown> = {};

    Object.keys(abilitazioneLookup).forEach(elem => {
      if (!validApplicazioniSoftwareFunzioniOperazioni?.find(i => i.abilitazioni.toString() === elem)) {
        lookup[elem] = abilitazioneLookup[elem];
      }
    })

    return { abilitazioni: lookup }

  }, [abilitazioneLookup, validApplicazioniSoftwareFunzioniOperazioni])

  useEffect(() => {
    setData(validApplicazioniSoftwareFunzioniOperazioni ?? []);
  }, [validApplicazioniSoftwareFunzioniOperazioni]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  return (
    <>

      <Switch>
        <Route path={applicazioniSoftwareFunzioniPath + componentTabsPath + applicazioniSoftwareFunzioniOperazioniPath + componentInsertPath} exact>
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={applicazioniSoftwareFunzioniPath + componentTabsPath + applicazioniSoftwareFunzioniOperazioniPath}
            tabsUri={applicazioniSoftwareFunzioniPath + componentTabsPath + applicazioniSoftwareFunzioniOperazioniPath + componentInsertPath}
            tabsView={false}
            buttonTitle={t("applicationSoftwareOperations")}
            info1={t("")}
            tabs={[
              {
                label: t(""),
                tabPath: "",
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={applicazioniSoftwareFunzioniPath + componentTabsPath + applicazioniSoftwareFunzioniOperazioniPath}
                        action={insert}
                        status={statusValidapplicazioniSoftwareFunzioniOperazioni}
                        error={errorBE}
                        update={false}
                        obj={obj}
                        fields={allFieldsState}
                        fixedProps={fixedProps}
                        lookups={formLookup}
                        translate={t}
                      />
                    </Grid>

                  </Grid>
                ),
                componentElse: <Redirect to={applicazioniSoftwareFunzioniOperazioniPath} />,
              },
            ]}
          />

        </Route>
        <Route path={applicazioniSoftwareFunzioniPath + componentTabsPath + applicazioniSoftwareFunzioniOperazioniPath + componentTabsPath} exact>
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={applicazioniSoftwareFunzioniPath + componentTabsPath + applicazioniSoftwareFunzioniOperazioniPath}
            tabsUri={applicazioniSoftwareFunzioniPath + componentTabsPath + applicazioniSoftwareFunzioniOperazioniPath + componentTabsPath}
            tabsView={false}
            buttonTitle={t("applicationSoftwareOperations")}
            info1={t("")}
            tabs={[
              {
                label: t("applicationSoftwareOperations"),
                tabPath: "",
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={applicazioniSoftwareFunzioniPath + componentTabsPath + applicazioniSoftwareFunzioniOperazioniPath}
                        action={update}
                        status={statusValidapplicazioniSoftwareFunzioniOperazioni}
                        error={errorBE}
                        update={true}
                        obj={obj}
                        fields={allFieldsState}
                        fixedProps={fixedProps}
                        lookups={formLookup}
                        translate={t}
                      />
                    </Grid>
                  </Grid>
                ),
                componentElse: <Redirect to={applicazioniSoftwareFunzioniOperazioniPath} />,
              },
            ]}
          />
        </Route>
        <Route path='*'>
          <CrudMaterialTableWithoutLogicDelete
            abilitazione={abilitazione}
            title={title}
            columns={columns}
            data={data}
            elementIdProps={elementIdProps}
            elementRenderProps={elementRenderProps}
            fetchAllValid={fetchById}
            fixedProps={fixedProps}
            statusValid={statusValidapplicazioniSoftwareFunzioniOperazioni}
            errorBE={errorBE}
            logoUri={logoUri}
            resetErrorCallback={resetError}
            insertCallback={Object.keys(formLookups.abilitazioni).length > 0 ? insertCallback : undefined}
            exportType={ExportType.NONE}
            extraOptions={{
              maxBodyHeight: 460,
            }}
            physicalDel={physicalDel} />
        </Route>

      </Switch>
    </>
  )
}
export default FunzioniOperazioniW;

