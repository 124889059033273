import { ApplicazioneSoftware } from "./ApplicazioniSoftware";
import { Fields } from "./Fields";

export type ApplicazioneSoftwareProfiloKeys = keyof ApplicazioneSoftwareProfilo;

export const elementIdProps: ApplicazioneSoftwareProfiloKeys[] = ["idApplicazioneSoftware", "idApplicazioneSoftwareProfilo",];
export const elementRenderProps: ApplicazioneSoftwareProfiloKeys[] = ["descrizione"];

export const allFields: Fields[] = [
  {
    field: "descrizione", type: 'string', titleKey: "description", required: true, show: true,
  },
  {
    field: "descrizioneEstesa", type: 'string', titleKey: "descriptionExtend", required: true, show: true,
  },
  {
    field: "ruoloPath", type: 'string', titleKey: "ruoloPath", required: true, show: true,
  },
  {
    field: "profiloIcona", type: 'image', titleKey: "profiloIcona", required: false, show: true,
  },
];

export interface ApplicazioneSoftwareProfilo {
  idApplicazioneSoftwareProfilo: number,
  idApplicazioneSoftware: number,
  applicazioneSoftwareDescrizione: string,
  descrizione: string,
  descrizioneEstesa: string,
  ruoloPath: string,
  profiloIcona: string,
  applicazioneSoftware: ApplicazioneSoftware,
  applicazioniSoftwareProfiliFunzioni: any[],
  version: number,
}