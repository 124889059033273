import { Fields } from "./Fields";

export type NomiHostOrganizzazioniKeys = keyof NomiHostOrganizzazioni;

export const elementIdProps: NomiHostOrganizzazioniKeys[] = ["idNomeHost", "idOrganizzazione"];
export const elementRenderProps: NomiHostOrganizzazioniKeys[] = ["organizzazioneNome"];

export const allFields: Fields[] = [
  {
    field: "idOrganizzazione", required: true, show: true, titleKey: "organizationParams", lookupField: true,
  },
];

export interface NomiHostOrganizzazioni {
  idNomeHost: number,
  idOrganizzazione: number,
  organizzazioneNome: string,
  version: number;
}