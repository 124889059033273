import { validateInputTextMinMaxLen, validateTelephoneCellularNumber } from "../utils/utilfunctions";
import { Fields } from "./Fields";
import { UtentiAbilitazioni } from "./UtentiAbilitazioni";

export type UtentiKeys = keyof Utenti;
export type UtentiExtendedKeys = keyof UtentiExtended;

export const elementIdProps: UtentiKeys[] = ["idUtente"];
export const elementRenderProps: UtentiKeys[] = ["nomeUtente"];

export const allFields: Fields[] = [
  {
    field: "nomeUtente", type: "string", required: true, colsNum: 12,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 0, 255, false, helperText, true), keyTradValidation: "size3to250ParamValidation",
    show: true, titleKey: "usernameParam",
  },
  {
    field: "codiceFiscale", type: "string", required: true, show: true, titleKey: "fiscalCodeParam",
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 16, 16, false, helperText, true), keyTradValidation: "size16to16ParamValidation"
  },
  {
    field: "nonAttivo", type: "boolean", required: false, show: true, titleKey: "notActive", colsNum: 6,
  },
  {
    field: "forzaCambioPassword", type: "boolean", required: false, show: true, titleKey: "actionChangePassword", colsNum: 6, showOnAction: "update",
  },
  {
    field: "ultimoLogin", type: "datetime", required: false, show: true, titleKey: "lastLogin",
  },
  {
    field: "ultimoCambioPassword", type: "datetime", required: false, show: true, titleKey: "lastChangePassword",
  },
  {
    field: "nome", type: "string", required: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 0, 250, false, helperText, false), keyTradValidation: "size0to250ParamValidation",
    show: true, titleKey: "nameLabel",
  },
  {
    field: "cognome", type: "string", required: false,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 0, 1000, false, helperText, false), keyTradValidation: "cellTelNumberParamValidation",
    show: true, titleKey: "surnameLabel",
  },
  {
    field: "cellulare", type: "string", required: false,
    validate: (x: string | undefined, helperText: string) => validateTelephoneCellularNumber(x, helperText, false), keyTradValidation: "cellTelNumberParamValidation",
    show: true, titleKey: "mobilePhoneParam",
  },
  {
    field: "emailAlternativo", type: "string", required: true, show: true, titleKey: "alternativeEmail",
  },
  {
    field: "sicurezzaDueFattori", type: "boolean", required: false, show: true, titleKey: "twoFactorSecurity",
  },
];

export interface Utenti {
  idUtente: number;
  nomeUtente: string;
  password: string;
  codiceFiscale: string,
  nonAttivo: boolean;
  forzaCambioPassword: boolean;
  ultimoLogin: string;
  ultimoCambioPassword: string;
  nome: string;
  cognome: string;
  cellulare: string;
  sicurezzaDueFattori: boolean;
  emailAlternativo: string;
  version: number;
}

export interface UtentiExtended extends Utenti {
  utentiAbilitazioni: UtentiAbilitazioni[];
}