import { validateInputTextMinMaxLen, } from "../utils/utilfunctions";
import { Fields } from "./Fields";
import { NomiHostOrganizzazioni } from "./NomiHostOrganizzazioni";

export type NomiHostKeys = keyof NomiHost;

export const elementIdProps: NomiHostKeys[] = ["idNomeHost"];
export const elementRenderProps: NomiHostKeys[] = ["nomeHost"];

export const allFields: Fields[] = [
  {
    field: "nomeHost", type: "string", required: true, colsNum: 6,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 0, 255, false, helperText, true), keyTradValidation: "size3to250ParamValidation",
    show: true, titleKey: "nameHost",
  },
  {
    field: "skinTheme", type: "string", required: false, show: true, titleKey: "skinTheme",
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 0, 1000, false, helperText, false), keyTradValidation: "cellTelNumberParamValidation",
  },
  {
    field: "autenticazione2FA", lookupField: true, required: false, show: true, titleKey: "Autenticazione2FA",
  },
  {
    field: "samlIdPUrl", type: "string", required: false, show: true, titleKey: "SamliIdUrl",
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 0, 1000, false, helperText, false), keyTradValidation: "cellTelNumberParamValidation",
  },
  {
    field: "spidResponseUri", type: "string", required: false, show: true, titleKey: "spidResponseUri", colsNum: 6,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 0, 1000, false, helperText, false), keyTradValidation: "cellTelNumberParamValidation",
  },
  {
    field: "samlIssuerId", type: "string", required: false, show: true, titleKey: "samlIssuerId",
  },
  {
    field: "spidAssertionConsumerServiceIndex", type: "numeric", required: false, show: true, titleKey: "SpidServiceIndex",
  },
  {
    field: "spidAbilitato", type: "boolean", required: false, show: true, titleKey: "spidAbilitato", colsNum: 6,
  },
  {
    field: "samlCertificate", type: "file", required: false, show: true, titleKey: "samlCertificate",
  },
];


export interface NomiHost {
  idNomeHost: number,
  nomeHost: string,
  skinTheme: string,
  autenticazione2FA: number,
  samlIdPUrl: string,
  samlCertificate: string,
  spidAbilitato: boolean,
  spidResponseUri: string,
  samlIssuerId: string,
  spidAssertionConsumerServiceIndex: number,
  version: number,

  nomiHostOrganizzazioni: NomiHostOrganizzazioni[];
}

