import { DizionarioDati } from "./ApplicazioniSoftwareDizionarioDati";
import { ApplicazioneSoftwareProfilo } from "./ApplicazioniSoftwareProfili";
import { Fields } from "./Fields";

export type ApplicazioneSoftwareKeys = keyof ApplicazioneSoftware;

export const elementIdProps: ApplicazioneSoftwareKeys[] = ["idApplicazioneSoftware"];
export const elementRenderProps: ApplicazioneSoftwareKeys[] = ["descrizione"];

export const allFields: Fields[] = [
  {
    field: "descrizione", type: 'string', titleKey: "description", required: true, show: true, editable: "always"
  },
  {
    field: "descrizioneEstesa", type: 'string', titleKey: "descriptionExtend", required: true, show: true,
  },
  {
    field: "urlConnessione", type: 'string', titleKey: "urlConnection", required: false, show: true,
  },
  {
    field: "appPathName", type: 'string', titleKey: "appPathName", required: true, show: true,
  },
  {
    field: "indisponibilitaMotivo", type: 'string', titleKey: "unavailabilityReason", required: false, show: true,
  },
  {
    field: "icona", type: 'image', titleKey: "icona", required: false, show: true,
  },
  {
    field: "favIcon", type: 'image', titleKey: "favIcon", required: false, show: true,
  },
  {
    field: "appIdMobile", type: 'string', titleKey: "appIdMobile", required: false, show: true,
  },
  {
    field: "appWebAbilitata", type: 'boolean', titleKey: "appWebAbilitata", required: false, show: true,
  }
];

export interface ApplicazioneSoftware {
  idApplicazioneSoftware: number,
  applicazioniSoftwareDizionarioDati: DizionarioDati[],
  applicazioniSoftwareProfili: ApplicazioneSoftwareProfilo[],
  descrizione: string,
  descrizioneEstesa: string,
  urlConnessione: string,
  icona: string,
  appPathName: string,
  indisponibilitaMotivo: string,
  favIcon: string,
  appIdMobile: string,
  appWebAbilitata: boolean,
  version: number,
}