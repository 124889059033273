import { Fields } from "./Fields";

export type DizionarioDatiKeys = keyof DizionarioDati;

export const elementIdProps: DizionarioDatiKeys[] = ["idApplicazioneSoftware", "codice", "lingua"];
export const elementRenderProps: DizionarioDatiKeys[] = ["codice", "lingua"];

export const allFields: Fields[] = [
  {
    field: "codice", type: 'string', titleKey: "codice", required: true, show: true, editable: "onAdd",
  },
  {
    field: "lingua", type: 'string', titleKey: "lingua", required: true, show: true, editable: "onAdd"
  },
  {
    field: "valore", type: 'string', titleKey: "valore", required: true, show: true,
  },
];

export interface DizionarioDati {
  idApplicazioneSoftware: number,
  applicazioniSoftwareDizionarioDati: DizionarioDati[],
  codice: string,
  lingua: string,
  valore: string,
  version: number,
}




