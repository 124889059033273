import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, getAll, getAllByIds, getNotExtendedEntityById, upd } from '../../services/services.service'
import { Utenti, elementIdProps, UtentiExtended, UtentiExtendedKeys, } from '../../models/Utenti';
import { createLookup } from '../../utils/utilfunctions';

const microservice = "utenti";
const entity = "utenti";

interface UtentiState {
  statusValidUtenti: StatusEnum,
  validUtenti: Utenti[],
  validUtentiExtended: UtentiExtended[],
  statusValidUtentiExtended: StatusEnum,
  utenti: Utenti | null,
  lookup: Lookup,
  error: string | null
}

const initialState: UtentiState = {
  statusValidUtenti: StatusEnum.Succeeded,
  validUtenti: [],
  validUtentiExtended: [],
  statusValidUtentiExtended: StatusEnum.Succeeded,
  utenti: null,
  lookup: {},
  error: null
}

// @GetMapping("cerca/utenti/id/{idUtenti}")
export const fetchById = createAsyncThunk(microservice + '/fetchById', async (obj: { idUtente: number }) => {
  let ids = [obj.idUtente];
  const response = await getNotExtendedEntityById(microservice, entity, ids);
  return response.data as Utenti;
});


// @GetMapping("cerca/utenti/all")
export const fetchAll = createAsyncThunk(microservice + '/fetchAll', async () => {
  const response = await getAll(microservice, entity);
  return response.data as Utenti[];
});

// @GetMapping("cerca/utenti/all/{extended}")
export const fetchAllExtended = createAsyncThunk(microservice + '/fetchAllExtended', async () => {
  const response = await getAllByIds(microservice, entity, [true]);
  return response.data as UtentiExtended[];
});


// @PostMapping("inserisci/utenti")
export const insert = createAsyncThunk(microservice + '/insert', async (utenti: Utenti) => {
  utenti.nonAttivo = utenti.nonAttivo ?? false
  utenti.forzaCambioPassword = true
  const response = await create(utenti, microservice, entity);
  return response.data as Utenti;
});

// @PutMapping("modifica/utenti")
export const update = createAsyncThunk(microservice + '/update', async (utenti: UtentiExtended) => {
  const response = await upd(utenti, microservice, entity);
  return response.data as UtentiExtended;
});

// @DeleteMapping("delete/utenti/id/{idUtenti}")"
export const physicalDel = createAsyncThunk(microservice + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const utentiSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    cleanLookup: (state: UtentiState) => {
      const newState = { ...state };
      newState.lookup = {};
      return newState;
    },
    reset: (state: UtentiState) => {
      return initialState;
    },
    resetError: (state: UtentiState) => {
      const newState = { ...state };
      newState.error = initialState.error;
      newState.statusValidUtenti = initialState.statusValidUtenti;
      return newState;
    }
  },
  extraReducers: builder => {

    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidUtenti = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUtenti = StatusEnum.Failed;
      state.utenti = null;
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<Utenti>) => {
      state.statusValidUtenti = StatusEnum.Succeeded;
      state.utenti = payload;
    })

    // fetchAll
    builder.addCase(fetchAll.pending, (state) => {
      state.statusValidUtenti = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUtenti = StatusEnum.Failed;
      state.validUtenti = [];
      state.lookup = {};
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<Utenti[]>) => {
      state.statusValidUtenti = StatusEnum.Succeeded;
      state.validUtenti = payload ?? [];
      state.lookup = createLookup(payload, "idUtente", ["nomeUtente"])
    })

    // fetchAllExtended
    builder.addCase(fetchAllExtended.pending, (state) => {
      state.statusValidUtentiExtended = StatusEnum.Loading;
    })
    builder.addCase(fetchAllExtended.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUtentiExtended = StatusEnum.Failed;
      state.validUtentiExtended = [];
      state.lookup = {};
    })
    builder.addCase(fetchAllExtended.fulfilled, (state, { payload }: PayloadAction<UtentiExtended[]>) => {
      state.statusValidUtentiExtended = StatusEnum.Succeeded;
      state.validUtentiExtended = payload ?? [];
      state.lookup = createLookup(payload, "idUtente", ["nomeUtente"])
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidUtenti = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUtenti = StatusEnum.Failed;
      state.utenti = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<Utenti>) => {
      state.statusValidUtenti = StatusEnum.Succeeded;
      state.utenti = payload;
      state.validUtenti = state.validUtenti.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidUtenti = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUtenti = StatusEnum.Failed;
      state.validUtentiExtended = [];
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<UtentiExtended>) => {
      state.statusValidUtenti = StatusEnum.Succeeded;
      state.validUtentiExtended = state.validUtentiExtended.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.utenti = payload;
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusValidUtenti = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUtenti = StatusEnum.Failed;
      state.validUtentiExtended = [];
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidUtenti = StatusEnum.Succeeded;
      state.validUtentiExtended = state.validUtentiExtended.filter(el => payload.ids.some(idObj => el[idObj.name as UtentiExtendedKeys] !== idObj.id));
    })
  }
});

export const { cleanLookup, reset, resetError } = utentiSlice.actions;
export default utentiSlice.reducer;