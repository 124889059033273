import { Fields } from "./Fields";

export type ApplicazioniSoftwareFunzioniKeys = keyof ApplicazioniSoftwareFunzioni;

export const elementIdProps: ApplicazioniSoftwareFunzioniKeys[] = ["idApplicazioneSoftware", "idApplicazioneSoftwareFunzione"];
export const elementRenderProps: ApplicazioniSoftwareFunzioniKeys[] = ["descrizione"];

export const allFields: Fields[] = [
  {
    field: "descrizione", type: 'string', titleKey: "description", required: true, show: true,
  },
  {
    field: "descrizioneEstesa", type: 'string', titleKey: "descriptionExtend", required: true, show: true,
  },
  {
    field: "uriPath", type: 'string', titleKey: "urlConnesione", required: false, show: true,
  },
  {
    field: "idApplicazioneSoftwareFunzioneParent", titleKey: "applicationSoftwareParentFunctionDescription", required: false, show: true, showOn: "form", lookupField: true,
  },

  {
    field: "applicazioneSoftwareFunzioneParentDescrizione", type: 'string', titleKey: "applicationSoftwareParentFunctionDescription", required: false, show: true, showOn: "table"
  },
  {
    field: "sortOrder", type: 'numeric', titleKey: "sortOrder", required: true, show: true,
  },
];

export interface ApplicazioniSoftwareFunzioni {
  idApplicazioneSoftware: number,
  applicazioneSoftwareDescrizione: string,
  idApplicazioneSoftwareFunzione: number,
  descrizione: string,
  descrizioneEstesa: string,
  idApplicazioneSoftwareFunzioneParent: number,
  applicazioneSoftwareFunzioneParentDescrizione: string,
  uriPath: string,
  sortOrder: number,
  isMacro: boolean,
  version: number,

  applicazioneSoftwareFunzioneParent: ApplicazioniSoftwareFunzioni | null,
  applicazioneSoftwareFunzioneChildren: ApplicazioniSoftwareFunzioni[],
  applicazioneSoftwareFunzioniGuida: ApplicazioniSoftwareFunzioni[],
  // applicazioneSoftwareComponenti: ApplicazioniSoftwareFunzioniComponentiExtended[],

}









