import { Column } from "@material-table/core";
import { Box, Grid, Paper } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import i18n from "../../../i18n";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import { Fields } from "../../../models/Fields";
import { allFields, ApplicazioniSoftwareFunzioni, ApplicazioniSoftwareFunzioniKeys, elementIdProps, elementRenderProps } from "../../../models/ApplicazioniSoftwareFunzioni";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { insert, update, physicalDel, resetError as resetErrorapplicazioniSoftwareFunzioni, fetchAllByIdApplicazioneSoftware } from "../../../store/slices/applicazioniSoftwareFunzioniSlice";
import { componentInsertPath, componentTabsPath } from "../../../utils/innerFuncPaths";
import { applicazioniSoftwareFunzioniGuidaPath, applicazioniSoftwareFunzioniOperazioniPath, applicazioniSoftwareFunzioniPath, applicazioniSoftwareProfiliFunzioniPath, componentiFunzioniPath } from "../../../utils/utilconst";
import GeneralForm from "../forms/GeneralForm";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import SimpleIdSelects from "../selects/SimpleIdSelects";
import { isUrlRoot } from "../../../utils/utilfunctions";
import { fetchAll as fetchAllApplicazioneSoftware } from "../../../store/slices/applicazioniSoftwareSlice";
import FunzioniProfiliW from "./FunzioniProfiliW";
import FunzioniOperazioniW from "./FunzioniOperazioniW";
import FunzioniGuidaW from "./FunzioniGuidaW";
import { ExportType } from "../../../utils/utildata";
import ComponentiFunzioniW from "./ComponentiFunzioniW";

const FunzioniW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const abilitazione = useAppSelector(state => getAbilitazione(state, applicazioniSoftwareFunzioniPath));

  const [obj, setObj] = useState<ApplicazioniSoftwareFunzioni | null>(null);

  const insertCallback = () => {
    history.push(applicazioniSoftwareFunzioniPath + componentInsertPath);
  };

  const updateDetailCallback = (applicazioniSoftwareFunzioni: ApplicazioniSoftwareFunzioni) => {
    setObj(applicazioniSoftwareFunzioni)
    history.push(applicazioniSoftwareFunzioniPath + componentTabsPath)
  }

  const applicazioniSoftwareFunzioniName = useMemo(() => t("applicationsSoftwareParam"), [t]);
  const applicazioniSoftwareFunzioniLabel = useMemo(() => t("applicationsSoftwareParam"), [t]);

  const applicazioniSoftwareFunzioniLookup = useAppSelector(state => state.applicazioniSoftwareFunzioni.lookup)

  const lookupSoftware = useAppSelector((state) => state.applicazioniSoftware.lookup);

  const [states, setStates] = useState<{
    [selectName: string]: number | string | null;
  }>({
    [applicazioniSoftwareFunzioniName]: null,
  });

  useEffect(() => {
    setStates({
      [applicazioniSoftwareFunzioniName]: null,

    });
  }, [applicazioniSoftwareFunzioniName]);

  const formLookups = useMemo(() => {
    return { idApplicazioneSoftwareFunzioneParent: applicazioniSoftwareFunzioniLookup };
  }, [applicazioniSoftwareFunzioniLookup])


  const isCurrentPageNotIndex = !isUrlRoot(history.location.pathname, applicazioniSoftwareFunzioniPath);

  const fixedProps = useMemo(() => {
    return { idApplicazioneSoftware: states[applicazioniSoftwareFunzioniName] }

  }, [applicazioniSoftwareFunzioniName, states])

  /*chiamata per lookup di ApplicazioneSoftware */

  useEffect(() => {
    dispatch(fetchAllApplicazioneSoftware());
  }, [dispatch]);


  const resetError = useCallback(() => {
    dispatch(resetErrorapplicazioniSoftwareFunzioni())
  }, [dispatch]);

  const title = t('applicationSoftwareFunction');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<ApplicazioniSoftwareFunzioni>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<ApplicazioniSoftwareFunzioni> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ? f.removable : !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort ? f.sort : "asc",
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as ApplicazioniSoftwareFunzioniKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;

        }

        return obj;
      })

    )
  }, [allFieldsState, excludedFieldInTable, t]);

  const validApplicazioniSoftwareFunzioni = useAppSelector(state => state.applicazioniSoftwareFunzioni.validApplicazioniSoftwareFunzioni);

  const statusValidApplicazioniSoftwareFunzioni = useAppSelector(state => state.applicazioniSoftwareFunzioni.statusValidapplicazioneSoftwareProfilo);

  const errorBE = useAppSelector(state => state.applicazioniSoftwareFunzioni.error)

  const [data, setData] = useState<Array<object>>([]);

  useEffect(() => {
    setData(validApplicazioniSoftwareFunzioni);
  }, [validApplicazioniSoftwareFunzioni]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  return (
    <>
      <Paper elevation={2}>
        <Box p={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SimpleIdSelects
                selectsArray={[
                  {
                    name: applicazioniSoftwareFunzioniName,
                    lookup: lookupSoftware,
                    label: applicazioniSoftwareFunzioniLabel,
                    disabled: isCurrentPageNotIndex,
                    breakpoints: { xs: 12, md: 6, sm: 12, lg: 4 }
                  },

                ]}
                states={states}
                setStates={setStates}
              />
            </Grid>

          </Grid>
        </Box>
      </Paper>

      <Box marginTop={2}>
        {
          states[applicazioniSoftwareFunzioniName] &&
          <Switch>
            <Route path={applicazioniSoftwareFunzioniPath} exact>
              <CrudMaterialTableWithoutLogicDelete
                abilitazione={abilitazione}
                title={title}
                columns={columns}
                data={data}
                elementIdProps={elementIdProps}
                elementRenderProps={elementRenderProps}
                fetchAllValid={fetchAllByIdApplicazioneSoftware}
                statusValid={statusValidApplicazioniSoftwareFunzioni}
                errorBE={errorBE}
                logoUri={logoUri}
                resetErrorCallback={resetError}
                insertCallback={insertCallback}
                updateCallback={updateDetailCallback}
                detailCallback={updateDetailCallback}
                exportType={ExportType.NONE}
                fixedProps={fixedProps}
                extraOptions={{
                  maxBodyHeight: 460,
                }}
                physicalDel={physicalDel} />

            </Route>
            <Route path={applicazioniSoftwareFunzioniPath + componentInsertPath} exact>
              <InnerComponentViews
                abilitazione={abilitazione}
                mainUri={applicazioniSoftwareFunzioniPath}
                tabsUri={applicazioniSoftwareFunzioniPath + componentInsertPath}
                tabsView={false}
                buttonTitle={t("applicationsSoftwareParam")}
                info1={t("")}
                tabs={[
                  {
                    label: t(""),
                    tabPath: "",
                    abilitazione: Abilitazione.READ_UPDATE,
                    componentIf: (
                      <Grid container spacing={2}>
                        <Grid item xs xl>
                          <GeneralForm
                            readOnly={false}
                            language={i18n.language}
                            componentPath={applicazioniSoftwareFunzioniPath}
                            action={insert}
                            status={statusValidApplicazioniSoftwareFunzioni}
                            error={errorBE}
                            update={false}
                            obj={{}}
                            fields={allFieldsState}
                            fixedProps={fixedProps}
                            lookups={formLookups}
                            translate={t}
                          />
                        </Grid>

                      </Grid>
                    ),
                    componentElse: <Redirect to={applicazioniSoftwareFunzioniPath} />,
                  },
                ]}
              />

            </Route>
            <Route path={applicazioniSoftwareFunzioniPath + componentTabsPath}>
              <InnerComponentViews
                abilitazione={abilitazione}
                mainUri={applicazioniSoftwareFunzioniPath}
                tabsUri={applicazioniSoftwareFunzioniPath + componentTabsPath}
                tabsView={true}
                buttonTitle={t("applicationsSoftwareParam")}
                info1={obj?.descrizione ?? ''}
                tabs={[
                  {
                    label: t("applicationsSoftwareParam"),
                    tabPath: applicazioniSoftwareFunzioniPath,
                    abilitazione: Abilitazione.READ_UPDATE,
                    componentIf: (
                      <Grid container spacing={2}>
                        <Grid item xs xl>
                          <GeneralForm
                            readOnly={false}
                            language={i18n.language}
                            componentPath={applicazioniSoftwareFunzioniPath}
                            action={update}
                            status={statusValidApplicazioniSoftwareFunzioni}
                            error={errorBE}
                            update={true}
                            obj={obj}
                            fields={allFieldsState}
                            fixedProps={fixedProps}
                            lookups={formLookups}
                            translate={t}
                          />
                        </Grid>
                      </Grid>
                    ),
                    componentElse: <Redirect to={applicazioniSoftwareFunzioniPath} />,
                  },
                  {
                    label: t("operationParam"),
                    tabPath: applicazioniSoftwareFunzioniOperazioniPath,
                    abilitazione: Abilitazione.READ,
                    componentIf: obj?.idApplicazioneSoftware && obj?.idApplicazioneSoftwareFunzione && obj?.applicazioneSoftwareDescrizione && obj?.descrizione &&
                      <FunzioniOperazioniW
                        idApplicazioneSoftware={obj.idApplicazioneSoftware}
                        idApplicazioneSoftwareFunzione={obj.idApplicazioneSoftwareFunzione}
                        applicazioneSoftwareDescrizione={obj.applicazioneSoftwareDescrizione}
                        applicazioneSoftwareFunzioneDescrizione={obj.descrizione}
                      />,
                    componentElse: <Redirect to={applicazioniSoftwareFunzioniPath} />
                  },
                  {
                    label: t("guidaParams"),
                    tabPath: applicazioniSoftwareFunzioniGuidaPath,
                    abilitazione: Abilitazione.READ,
                    componentIf: obj?.idApplicazioneSoftware && obj?.idApplicazioneSoftwareFunzione &&
                      <FunzioniGuidaW
                        idApplicazioneSoftware={obj.idApplicazioneSoftware}
                        idApplicazioneSoftwareFunzione={obj.idApplicazioneSoftwareFunzione}
                      />,
                    componentElse: <Redirect to={applicazioniSoftwareFunzioniPath} />
                  },
                  {
                    label: t("profilesParam"),
                    tabPath: applicazioniSoftwareProfiliFunzioniPath,
                    abilitazione: Abilitazione.READ,
                    componentIf: obj?.idApplicazioneSoftwareFunzione && obj?.idApplicazioneSoftware && obj?.applicazioneSoftwareDescrizione && obj?.descrizione &&
                      <FunzioniProfiliW
                        idApplicazioneSoftwareFunzione={obj.idApplicazioneSoftwareFunzione}
                        idApplicazioneSoftware={obj.idApplicazioneSoftware}
                        applicazioneSoftwareDescrizione={obj.applicazioneSoftwareDescrizione}
                        applicazioneSoftwareFunzioneDescrizione={obj.descrizione}
                      />,
                    componentElse: <Redirect to={applicazioniSoftwareFunzioniPath} />
                  },
                  {
                    label: t("componentsParam"),
                    tabPath: componentiFunzioniPath,
                    abilitazione: Abilitazione.READ,
                    componentIf: obj?.idApplicazioneSoftwareFunzione && obj?.idApplicazioneSoftware &&
                      <ComponentiFunzioniW
                        idApplicazioneSoftwareFunzione={obj.idApplicazioneSoftwareFunzione}
                        idApplicazioneSoftware={obj.idApplicazioneSoftware}
                      />,
                    componentElse: <Redirect to={applicazioniSoftwareFunzioniPath} />
                  },
                ]}
              />
            </Route>
          </Switch>
        }
      </Box>
    </>
  )
}

export default FunzioniW;