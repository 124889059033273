import { Column } from "@material-table/core";
import { Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import i18n from "../../../i18n";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import { Fields } from "../../../models/Fields";
import { allFields, ApplicazioniSoftwareFunzioniGuidaKeys, elementIdProps, elementRenderProps } from "../../../models/ApplicazioniSoftwareFunzioniGuida";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { insert, update, physicalDel, fetchAllValid, } from "../../../store/slices/applicazioniSoftwareFunzioniGuidaSlice";
import { componentInsertPath, componentTabsPath } from "../../../utils/innerFuncPaths";
import { applicazioniSoftwareFunzioniGuidaPath, applicazioniSoftwareFunzioniPath } from "../../../utils/utilconst";
import GeneralForm from "../forms/GeneralForm";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import { ApplicazioniSoftwareFunzioniGuida } from "../../../models/ApplicazioniSoftwareFunzioniGuida";
import { ExportType } from "../../../utils/utildata";

interface ApplicazioniSoftwareFunzioniGuidaProps {
  idApplicazioneSoftware: number,
  idApplicazioneSoftwareFunzione: number,
}

const FunzioniGuidaW = ({ idApplicazioneSoftwareFunzione, idApplicazioneSoftware }: ApplicazioniSoftwareFunzioniGuidaProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const abilitazione = useAppSelector(state => getAbilitazione(state, applicazioniSoftwareFunzioniPath));
  const [obj, setObj] = useState<ApplicazioniSoftwareFunzioniGuida | null>(null);


  const insertCallback = () => {
    history.push(applicazioniSoftwareFunzioniPath + componentTabsPath + applicazioniSoftwareFunzioniGuidaPath + componentInsertPath);
  };

  const updateDetailCallback = (idApplicazioneSoftwareFunzione: ApplicazioniSoftwareFunzioniGuida) => {
    setObj(idApplicazioneSoftwareFunzione)

    history.push(applicazioniSoftwareFunzioniPath + componentTabsPath + applicazioniSoftwareFunzioniGuidaPath + componentTabsPath)
  }



  const resetError = useCallback(() => {
    /**
     * dispatch delle chiamate per resettare gli errori
     */
  }, []);

  const title = t('applicationSoftwareOperations');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<ApplicazioniSoftwareFunzioniGuida>>>([]);

  const fixedProps = useMemo(() => {
    return {
      idApplicazioneSoftwareFunzione: idApplicazioneSoftwareFunzione,
      idApplicazioneSoftware: idApplicazioneSoftware,
    }
  }, [idApplicazioneSoftware, idApplicazioneSoftwareFunzione])



  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<ApplicazioniSoftwareFunzioniGuida> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ? f.removable : !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort ? f.sort : "asc",
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as ApplicazioniSoftwareFunzioniGuidaKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;

        }

        return obj;
      })

    )
  }, [allFieldsState, excludedFieldInTable, t]);

  const validApplicazioniSoftwareFunzioniGuida = useAppSelector(state => state.applicazioniSoftwareFunzioniGuida.validApplicazioniSoftwareFunzioniGuida);
  const statusValidapplicazioniSoftwareFunzioniGuida = useAppSelector(state => state.applicazioniSoftwareFunzioniGuida.statusValidapplicazioniSoftwareFunzioniGuida);

  const errorBE = useAppSelector(state => state.applicazioniSoftwareFunzioniGuida.error)

  const [data, setData] = useState<ApplicazioniSoftwareFunzioniGuida[]>([]);

  useEffect(() => {
    setData(validApplicazioniSoftwareFunzioniGuida);
  }, [validApplicazioniSoftwareFunzioniGuida]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  return (
    <>

      <Switch>
        <Route path={applicazioniSoftwareFunzioniPath + componentTabsPath + applicazioniSoftwareFunzioniGuidaPath + componentInsertPath} exact>
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={applicazioniSoftwareFunzioniPath + componentTabsPath + applicazioniSoftwareFunzioniGuidaPath}
            tabsUri={applicazioniSoftwareFunzioniPath + componentTabsPath + applicazioniSoftwareFunzioniGuidaPath + componentInsertPath}
            tabsView={false}
            buttonTitle={t("applicationSoftwareOperations")}
            info1={t("")}
            tabs={[
              {
                label: t(""),
                tabPath: "",
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={applicazioniSoftwareFunzioniPath + componentTabsPath + applicazioniSoftwareFunzioniGuidaPath}
                        action={insert}
                        status={statusValidapplicazioniSoftwareFunzioniGuida}
                        error={errorBE}
                        update={false}
                        obj={{}}
                        fields={allFieldsState}
                        fixedProps={fixedProps}
                        translate={t}
                      />
                    </Grid>

                  </Grid>
                ),
                componentElse: <Redirect to={applicazioniSoftwareFunzioniGuidaPath} />,
              },
            ]}
          />

        </Route>
        <Route path={applicazioniSoftwareFunzioniPath + componentTabsPath + applicazioniSoftwareFunzioniGuidaPath + componentTabsPath} exact>
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={applicazioniSoftwareFunzioniPath + componentTabsPath + applicazioniSoftwareFunzioniGuidaPath}
            tabsUri={applicazioniSoftwareFunzioniPath + componentTabsPath + applicazioniSoftwareFunzioniGuidaPath + componentTabsPath}
            tabsView={false}
            buttonTitle={t("applicationSoftwareOperations")}
            info1={t("")}
            tabs={[
              {
                label: t("applicationSoftwareOperations"),
                tabPath: "",
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={applicazioniSoftwareFunzioniPath + componentTabsPath + applicazioniSoftwareFunzioniGuidaPath}
                        action={update}
                        status={statusValidapplicazioniSoftwareFunzioniGuida}
                        error={errorBE}
                        update={true}
                        obj={obj}
                        fields={allFieldsState}
                        fixedProps={fixedProps}
                        translate={t}
                      />
                    </Grid>
                  </Grid>
                ),
                componentElse: <Redirect to={applicazioniSoftwareFunzioniGuidaPath} />,
              },
            ]}
          />
        </Route>
        <Route path='*'>
          <CrudMaterialTableWithoutLogicDelete
            abilitazione={abilitazione}
            title={title}
            columns={columns}
            data={data}
            elementIdProps={elementIdProps}
            elementRenderProps={elementRenderProps}
            fetchAllValid={fetchAllValid}
            fixedProps={fixedProps}
            statusValid={statusValidapplicazioniSoftwareFunzioniGuida}
            errorBE={errorBE}
            logoUri={logoUri}
            resetErrorCallback={resetError}
            insertCallback={insertCallback}
            updateCallback={updateDetailCallback}
            detailCallback={updateDetailCallback}
            exportType={ExportType.NONE}
            extraOptions={{
              maxBodyHeight: 460,
            }}
            physicalDel={physicalDel} />
        </Route>

      </Switch>
    </>
  )
}
export default FunzioniGuidaW;

