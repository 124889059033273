import { createSlice, } from '@reduxjs/toolkit'
import { AbilitazioneLookup, Autenticazione2FALookup, ComponentiLookup, ModificabileLookup, MostraSoloInLookup, TipoCampoLookup } from '../../utils/utildata';

const entity = "qualifiche";

interface LookupState {
  abilitazioneLookup: { [key: string]: string },
  tipoCampoLookup: { [key: string]: string },
  mostraSoloInLookup: { [key: string]: string },
  modificabileLookup: { [key: string]: string },
  componentiLookup: { [key: string]: string },
  autenticazione2FALookup: { [key: string]: string },
}

const initialState: LookupState = {
  abilitazioneLookup: AbilitazioneLookup,
  tipoCampoLookup: TipoCampoLookup,
  mostraSoloInLookup: MostraSoloInLookup,
  modificabileLookup: ModificabileLookup,
  componentiLookup: ComponentiLookup,
  autenticazione2FALookup: Autenticazione2FALookup,
}

function removeEmpty(obj: { [key: string]: string }) {
  const temp: Record<string, string> = {};
  Object.keys(obj).forEach((elem) => {
    if (obj[elem]) {
      temp[elem] = obj[elem]
    }
  })

  return temp
}

export const lookupSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: LookupState) => {
      return initialState;
    },
    setAbilitazioneLookup: (state: LookupState, action: { payload: { [key: string]: string } }) => {
      state.abilitazioneLookup = { ...removeEmpty(action.payload) };
    },
    setTipoCampoLookup: (state: LookupState, action: { payload: { [key: string]: string } }) => {
      state.tipoCampoLookup = { ...removeEmpty(action.payload) };
    },
    setMostraSoloInLookup: (state: LookupState, action: { payload: { [key: string]: string } }) => {
      state.mostraSoloInLookup = { ...removeEmpty(action.payload) };
    },
    setModificabileLookup: (state: LookupState, action: { payload: { [key: string]: string } }) => {
      state.modificabileLookup = { ...removeEmpty(action.payload) };
    },
    setComponentiLookup: (state: LookupState, action: { payload: { [key: string]: string } }) => {
      state.componentiLookup = { ...removeEmpty(action.payload) };
    },
    setAutenticazione2FALookup: (state: LookupState, action: { payload: { [key: string]: string } }) => {
      state.autenticazione2FALookup = { ...removeEmpty(action.payload) };
    },
  },
});

export const { reset, setAbilitazioneLookup, setTipoCampoLookup, setMostraSoloInLookup, setModificabileLookup, setComponentiLookup, setAutenticazione2FALookup } = lookupSlice.actions;
export default lookupSlice.reducer;