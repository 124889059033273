import { Column } from "@material-table/core";
import { Box, Grid, Paper } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import i18n from "../../../i18n";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import { Fields } from "../../../models/Fields";
import { allFields, FunzioniComponenti, elementIdProps, elementRenderProps, FunzioniComponentiKeys } from "../../../models/FunzioniComponenti";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { insert, update, physicalDel, resetError as resetErrorapplicazioniSoftwareFunzioni, } from "../../../store/slices/funzioniComponentiSlice";
import { componentInsertPath, componentTabsPath } from "../../../utils/innerFuncPaths";
import { campiComponentiPath, funzioniComponentiPath, } from "../../../utils/utilconst";
import GeneralForm from "../forms/GeneralForm";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import SimpleIdSelects from "../selects/SimpleIdSelects";
import { isUrlRoot } from "../../../utils/utilfunctions";
import { fetchAll as fetchAllApplicazioneSoftware } from "../../../store/slices/applicazioniSoftwareSlice";
import { ComponentiLookup, ExportType } from "../../../utils/utildata";
import CampiComponentiW from "./CampiComponentiW";
import { fetchAllValidFunzioniComponenti } from "../../../store/slices/funzioniComponentiSlice";

const FunzioniComponentiW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const abilitazione = useAppSelector(state => getAbilitazione(state, funzioniComponentiPath));

  const [obj, setObj] = useState<FunzioniComponenti | null>(null);

  const insertCallback = () => {
    history.push(funzioniComponentiPath + componentInsertPath);
  };

  const updateDetailCallback = (applicazioniSoftwareFunzioni: FunzioniComponenti) => {
    setObj(applicazioniSoftwareFunzioni)
    history.push(funzioniComponentiPath + componentTabsPath)
  }

  const applicazioniSoftwareFunzioniName = useMemo(() => t("applicationsSoftwareParam"), [t]);
  const applicazioniSoftwareFunzioniLabel = useMemo(() => t("applicationsSoftwareParam"), [t]);

  const applicazioniSoftwareFunzioniLookup = useAppSelector(state => state.applicazioniSoftwareFunzioni.lookup)

  const lookupSoftware = useAppSelector((state) => state.applicazioniSoftware.lookup);

  const [states, setStates] = useState<{ [selectName: string]: number | string | null; }>({
    [applicazioniSoftwareFunzioniName]: null,
  });

  useEffect(() => {
    setStates({
      [applicazioniSoftwareFunzioniName]: null,
    });
  }, [applicazioniSoftwareFunzioniName]);

  const formLookups = useMemo(() => {
    return {
      idApplicazioneSoftwareFunzioneParent: applicazioniSoftwareFunzioniLookup,
      tipoComponente: ComponentiLookup,
    };
  }, [applicazioniSoftwareFunzioniLookup])

  const isCurrentPageNotIndex = !isUrlRoot(history.location.pathname, funzioniComponentiPath);

  const fixedProps = useMemo(() => {
    return { idApplicazioneSoftware: states[applicazioniSoftwareFunzioniName] }
  }, [applicazioniSoftwareFunzioniName, states])

  /*chiamata per lookup di ApplicazioneSoftware */

  useEffect(() => {
    dispatch(fetchAllApplicazioneSoftware());
  }, [dispatch]);

  const resetError = useCallback(() => {
    dispatch(resetErrorapplicazioniSoftwareFunzioni())
  }, [dispatch]);

  const title = t('componentsParam');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<FunzioniComponenti>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<FunzioniComponenti> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ? f.removable : !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort ? f.sort : "asc",
          emptyValue: f.defaultValue ?? 'N/A',
        }

        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as FunzioniComponentiKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }

        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }

        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        if (f.lookupField) {
          switch (f.field) {
            case 'tipoComponente':
              obj.lookup = ComponentiLookup
              break;
          }
        }

        return obj;
      })

    )
  }, [allFieldsState, excludedFieldInTable, t]);

  const validFunzioniComponenti = useAppSelector(state => state.funzioniComponenti.validFunzioniComponenti);
  const statusValidFunzioniComponenti = useAppSelector(state => state.funzioniComponenti.statusValidFunzioniComponenti);

  const errorBE = useAppSelector(state => state.funzioniComponenti.error)

  const [data, setData] = useState<FunzioniComponenti[]>([]);

  useEffect(() => {
    setData(validFunzioniComponenti);
  }, [validFunzioniComponenti]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  return (
    <>
      <Paper elevation={2}>
        <Box p={4}>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <SimpleIdSelects
                selectsArray={[
                  {
                    name: applicazioniSoftwareFunzioniName,
                    lookup: lookupSoftware,
                    label: applicazioniSoftwareFunzioniLabel,
                    disabled: isCurrentPageNotIndex,
                    breakpoints: { xs: 12, md: 6, sm: 12, lg: 4 }
                  },
                ]}
                states={states}
                setStates={setStates}
              />
            </Grid>

          </Grid>
        </Box>
      </Paper>

      <Box marginTop={2}>
        {
          states[applicazioniSoftwareFunzioniName] &&
          <Switch>
            <Route path={funzioniComponentiPath} exact>
              <CrudMaterialTableWithoutLogicDelete
                abilitazione={abilitazione}
                title={title}
                columns={columns}
                data={data}
                elementIdProps={elementIdProps}
                elementRenderProps={elementRenderProps}
                fetchAllValid={fetchAllValidFunzioniComponenti}
                statusValid={statusValidFunzioniComponenti}
                errorBE={errorBE}
                logoUri={logoUri}
                resetErrorCallback={resetError}
                insertCallback={insertCallback}
                updateCallback={updateDetailCallback}
                detailCallback={updateDetailCallback}
                exportType={ExportType.NONE}
                fixedProps={fixedProps}
                extraOptions={{
                  maxBodyHeight: 460,
                }}
                physicalDel={physicalDel} />
            </Route>
            <Route path={funzioniComponentiPath + componentInsertPath} exact>
              <InnerComponentViews
                abilitazione={abilitazione}
                mainUri={funzioniComponentiPath}
                tabsUri={funzioniComponentiPath + componentInsertPath}
                tabsView={false}
                buttonTitle={t("componentsParam")}
                info1={t("")}
                tabs={[
                  {
                    label: t(""),
                    tabPath: "",
                    abilitazione: Abilitazione.READ_UPDATE,
                    componentIf: (
                      <Grid container spacing={2}>
                        <Grid item xs xl>
                          <GeneralForm
                            readOnly={false}
                            language={i18n.language}
                            componentPath={funzioniComponentiPath}
                            action={insert}
                            status={statusValidFunzioniComponenti}
                            error={errorBE}
                            update={false}
                            obj={{}}
                            fields={allFieldsState}
                            fixedProps={fixedProps}
                            lookups={formLookups}
                            translate={t}
                          />
                        </Grid>

                      </Grid>
                    ),
                    componentElse: <Redirect to={funzioniComponentiPath} />,
                  },
                ]}
              />
            </Route>
            <Route path={funzioniComponentiPath + componentTabsPath}>
              <InnerComponentViews
                abilitazione={abilitazione}
                mainUri={funzioniComponentiPath}
                tabsUri={funzioniComponentiPath + componentTabsPath}
                tabsView={true}
                buttonTitle={t("componentsParam")}
                info1={t("")}
                tabs={[
                  {
                    label: t("componentsParam"),
                    tabPath: funzioniComponentiPath,
                    abilitazione: Abilitazione.READ_UPDATE,
                    componentIf: (
                      <Grid container spacing={2}>
                        <Grid item xs xl>
                          <GeneralForm
                            readOnly={false}
                            language={i18n.language}
                            componentPath={funzioniComponentiPath}
                            action={update}
                            status={statusValidFunzioniComponenti}
                            error={errorBE}
                            update={true}
                            obj={obj}
                            fields={allFieldsState}
                            fixedProps={fixedProps}
                            lookups={formLookups}
                            translate={t}
                          />
                        </Grid>
                      </Grid>
                    ),
                    componentElse: <Redirect to={funzioniComponentiPath} />,
                  },
                  {
                    label: t("fieldsParam"),
                    tabPath: campiComponentiPath,
                    abilitazione: Abilitazione.READ,
                    componentIf: obj?.idApplicazioneSoftware && obj?.idApplicazioneSoftwareComponente &&
                      <CampiComponentiW
                        idApplicazioneSoftware={obj.idApplicazioneSoftware}
                        idApplicazioneSoftwareComponente={obj.idApplicazioneSoftwareComponente}
                      />,
                    componentElse: <Redirect to={funzioniComponentiPath} />
                  },
                ]}
              />
            </Route>
          </Switch>
        }
      </Box>
    </>
  )
}

export default FunzioniComponentiW;