import { Fields } from "./Fields";

export type CampiComponentiKeys = keyof CampiComponenti;

export const elementIdProps: CampiComponentiKeys[] = ["idApplicazioneSoftware", "idApplicazioneSoftwareComponente", "idApplicazioneSoftwareComponenteCampo"];
export const elementRenderProps: CampiComponentiKeys[] = ["nomeCampoBackend"];

export const allFields: Fields[] = [
  {
    field: "idApplicazioneSoftware", titleKey: "idApplicazioneSoftware", required: false, show: false,
  },
  {
    field: "idApplicazioneSoftwareComponente", titleKey: "idApplicazioneSoftwareComponente", required: false, show: false,
  },
  {
    field: "idApplicazioneSoftwareComponenteCampo", titleKey: "idApplicazioneSoftwareComponenteCampo", required: false, show: false,
  },
  {
    field: "ordinamento", type: 'string', titleKey: "Ordinamento", required: false, show: true,
  },
  {
    field: "nomeCampoBackend", type: 'string', titleKey: "Nome Campo Backend", required: true, show: true,
  },
  {
    field: "progressivoChiave", type: 'numeric', titleKey: "progressivoChiave", required: false, show: true,
  },
  {
    field: "etichettaCodice", type: 'string', titleKey: "Etichetta Codice", required: false, show: true,
  },
  {
    field: "tipo", titleKey: "Tipo", required: true, show: true, lookupField: true,
  },
  {
    field: "richiesto", type: 'boolean', titleKey: "Richiesto", required: false, show: true, editable: "always",
  },
  {
    field: "removibile", type: 'boolean', titleKey: "Removibile", required: false, show: true, editable: "always",
  },
  {
    field: "mostra", type: 'boolean', titleKey: "Mostra", required: false, show: true, editable: "always",
  },
  {
    field: "solaLettura", type: 'boolean', titleKey: "Sola Lettura", required: false, show: true, editable: "always",
  },
  {
    field: "nomeGruppoCodice", type: 'string', titleKey: "Nome Gruppo Codice", required: false, show: true, editable: "always", sort: 'desc',
  },
  {
    field: "nomeSottoGruppoCodice", type: 'string', titleKey: "Nome Sotto Gruppo Codice", required: false, show: true, editable: "always",
  },
  {
    field: "validazioneRegola", type: 'string', titleKey: "Validazione Regola", required: false, show: true, editable: "always",
  },
  {
    field: "validazioneMessaggioCodice", type: 'string', titleKey: "Validazione Messaggio Codice", required: false, show: true, editable: "always",
  },
  {
    field: "validazione2Regola", type: 'string', titleKey: "Validazione2 Regola", required: false, show: true, editable: "always",
  },
  {
    field: "validazione2Campo", type: 'string', titleKey: "validazione2 Campo", required: false, show: true, editable: "always",
  },
  {
    field: "validazione2MessaggioCodice", type: 'string', titleKey: "Validazione2 Messaggio Codice", required: false, show: true, editable: "always",
  },
  {
    field: "accetta", type: 'string', titleKey: "File Accettati", required: false, show: true, editable: "always",
  },
  {
    field: "lookup", type: 'boolean', titleKey: "Lookup", required: false, show: true, editable: "always",
  },
  {
    field: "etichetta", type: 'boolean', titleKey: "Etichetta", required: false, show: true, editable: "always",
  },
  {
    field: "completamentoAutomatico", type: 'boolean', titleKey: "Completamento Automatico", required: false, show: true, editable: "always",
  },
  {
    field: "origineDati", type: 'string', titleKey: "Origine Dati", required: false, show: true, editable: "always",
  },
  {
    field: "numeroColonne", titleKey: "Numero Colonne", required: false, show: true, editable: "always",
  },
  {
    field: "modificabile", titleKey: "Modificabile", required: false, show: true, editable: "always", lookupField: true
  },
  {
    field: "mostraSoloIn", titleKey: "Mostra Solo In", required: false, show: true, editable: "always", lookupField: true,
  },
  {
    field: "valoreMinimo", titleKey: "Valore Minimo", required: false, show: true, editable: "always",
  },
  {
    field: "valoreMassimo", titleKey: "Valore Massimo", required: false, show: true, editable: "always",
  },
  {
    field: "valoreDefault", type: 'string', titleKey: "Valore Default", required: false, show: true, editable: "always",
  },
  {
    field: "lunghezzaMinimo", titleKey: "Lunghezza Minimo", required: false, show: true, editable: "always",
  },
  {
    field: "lunghezzaMassimo", titleKey: "Lunghezza Massimo", required: false, show: true, editable: "always",
  },
  {
    field: "righeMultiple", type: 'boolean', titleKey: "Righe Multiple", required: false, show: true, editable: "always",
  },
  {
    field: "funzioneRender", type: 'string', titleKey: "Funzione Render", required: false, show: true, editable: "always",
  },
  {
    field: "operazioneInizializzazione", type: 'string', titleKey: "Operazione Inizializzazione", required: false, show: true, editable: "always",
  },
];

export interface CampiComponenti {
  idApplicazioneSoftware: number,
  idApplicazioneSoftwareComponente: number,
  idApplicazioneSoftwareComponenteCampo: number,
  nomeCampoBackend: string,
  progressivoChiave: number,
  etichettaCodice: string,
  tipo?: string,
  richiesto: boolean,
  removibile: boolean,
  mostra: boolean,
  solaLettura: boolean,
  ordinamento: string,
  nomeGruppoCodice: string,
  nomeSottoGruppoCodice: string,
  validazioneRegola: string,
  validazioneMessaggioCodice: string,
  validazione2Regola: string,
  validazione2Campo: String,
  validazione2MessaggioCodice: string,
  accetta: string,
  lookup: boolean,
  etichetta: boolean,
  completamentoAutomatico: boolean,
  origineDati: string,
  numeroColonne: number,
  modificabile: string,
  mostraSoloIn: string,
  valoreMinimo: number,
  valoreMassimo: number,
  valoreDefault: string,
  lunghezzaMinimo: number,
  lunghezzaMassimo: number,
  righeMultiple: boolean,
  funzioneRender: string,
  operazioneInizializzazione: string,
  version: number,
}