import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, getAll, getAllValid, getAllValidByIds, upd } from '../../services/services.service'
import { FunzioniComponenti, elementIdProps, FunzioniComponentiKeys } from '../../models/FunzioniComponenti'
import { createLookup } from '../../utils/utilfunctions';

const microservice = "appsoftware";
const entity = "applicazionesoftwarecomponenti";

interface FunzioniComponenetiState {
  statusValidFunzioniComponenti: StatusEnum,
  validFunzioniComponenti: FunzioniComponenti[],
  FunzioniComponenti: FunzioniComponenti | null,
  lookup: Lookup,
  error: string | null
}

const initialState: FunzioniComponenetiState = {
  statusValidFunzioniComponenti: StatusEnum.Succeeded,
  validFunzioniComponenti: [],
  FunzioniComponenti: null,
  lookup: {},
  error: null
}

//"/cerca/applicazionesoftwarecomponenti/all/valid/{idApplicazioneSoftware}"
export const fetchAllValidFunzioniComponenti = createAsyncThunk(entity + '/fetchAllValidFunzioniComponenti', async (obj: { idApplicazioneSoftware: number }) => {
  let ids = [obj.idApplicazioneSoftware];
  const response = await getAllValidByIds(microservice, entity, ids, ['fetchAllValidFunzioniComponenti']);
  return response.data as FunzioniComponenti[];
});

//@GetMapping("cerca/appsoftware/all")
export const fetchAll = createAsyncThunk(entity + '/fetchAll', async () => {
  const response = await getAll(microservice, entity);
  return response.data as FunzioniComponenti[];
});

//@GetMapping("cerca/appsoftware/allValid")
export const fetchAllValid = createAsyncThunk(entity + '/fetchAllValid', async () => {
  const response = await getAllValid(microservice, entity);
  return response.data as FunzioniComponenti[];
});

// inserisci/appsoftware
export const insert = createAsyncThunk(entity + '/insert', async (appsoftware: FunzioniComponenti) => {
  const response = await create(appsoftware, microservice, entity);
  return response.data as FunzioniComponenti;
});

// modifica/appsoftware
export const update = createAsyncThunk(entity + '/update', async (appsoftware: FunzioniComponenti) => {
  const response = await upd(appsoftware, microservice, entity);
  return response.data as FunzioniComponenti;
});

// delete/appsoftware/id/{idappsoftware}
export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const funzioniComponentiSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    cleanLookup: (state: FunzioniComponenetiState) => {
      state.lookup = {};
    },
    reset: (state: FunzioniComponenetiState) => {
      return initialState;
    },
    resetError: (state: FunzioniComponenetiState) => {
      state.error = initialState.error;
      state.statusValidFunzioniComponenti = initialState.statusValidFunzioniComponenti;
    }
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchAllValidFunzioniComponenti.pending, (state) => {
      state.statusValidFunzioniComponenti = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValidFunzioniComponenti.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidFunzioniComponenti = StatusEnum.Failed;
      state.FunzioniComponenti = null;
    })
    builder.addCase(fetchAllValidFunzioniComponenti.fulfilled, (state, { payload }: PayloadAction<FunzioniComponenti[]>) => {
      state.statusValidFunzioniComponenti = StatusEnum.Succeeded;
      state.validFunzioniComponenti = payload;
      state.lookup = createLookup(payload, "idApplicazioneSoftwareComponente", ["titoloCodice", "tipoComponente"], null, '-')
    })

    // fetchAll
    builder.addCase(fetchAll.pending, (state) => {
      state.statusValidFunzioniComponenti = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidFunzioniComponenti = StatusEnum.Failed;
      state.validFunzioniComponenti = [];
      state.lookup = {};
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<FunzioniComponenti[]>) => {
      state.statusValidFunzioniComponenti = StatusEnum.Succeeded;
      state.validFunzioniComponenti = payload ?? [];
      state.lookup = createLookup(payload, "idApplicazioneSoftwareComponente", ["titoloCodice", "tipoComponente"], null, '-')
    })

    // fetchAllValid
    builder.addCase(fetchAllValid.pending, (state) => {
      state.statusValidFunzioniComponenti = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValid.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidFunzioniComponenti = StatusEnum.Failed;
      state.validFunzioniComponenti = [];
      state.lookup = {};
    })
    builder.addCase(fetchAllValid.fulfilled, (state, { payload }: PayloadAction<FunzioniComponenti[]>) => {
      state.statusValidFunzioniComponenti = StatusEnum.Succeeded;
      state.validFunzioniComponenti = payload ?? [];
      state.lookup = createLookup(payload, "idApplicazioneSoftwareComponente", ["titoloCodice", "tipoComponente"], null, '-')
    })

    //   // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidFunzioniComponenti = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidFunzioniComponenti = StatusEnum.Failed;
      state.FunzioniComponenti = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<FunzioniComponenti>) => {
      state.statusValidFunzioniComponenti = StatusEnum.Succeeded;
      state.FunzioniComponenti = payload;
      state.validFunzioniComponenti = state.validFunzioniComponenti.concat([payload]);
    })

    //   // update
    builder.addCase(update.pending, (state) => {
      state.statusValidFunzioniComponenti = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidFunzioniComponenti = StatusEnum.Failed;
      state.FunzioniComponenti = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<FunzioniComponenti>) => {
      state.statusValidFunzioniComponenti = StatusEnum.Succeeded;
      state.validFunzioniComponenti = state.validFunzioniComponenti.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.FunzioniComponenti = payload;
    })

    //   // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusValidFunzioniComponenti = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidFunzioniComponenti = StatusEnum.Failed;
      state.validFunzioniComponenti = [];
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidFunzioniComponenti = StatusEnum.Succeeded;
      state.validFunzioniComponenti = state.validFunzioniComponenti.filter(el => payload.ids.some(idObj => el[idObj.name as FunzioniComponentiKeys] !== idObj.id));
    })
  }
});

export const { cleanLookup, reset, resetError } = funzioniComponentiSlice.actions;
export default funzioniComponentiSlice.reducer;