import React, { useState, useRef } from 'react'
import { Box, Button, ButtonGroup, ClickAwayListener, Grow, Paper, Popper, useTheme } from '@material-ui/core'
import HelpButton from '../../buttons/HelpButton'
import LanguageSelector from '../LanguageSelector'
import { useTranslation } from 'react-i18next'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ButtonUser from '../ButtonUser';

const ButtonLogout = React.lazy(() => import('../ButtonLogout'));

interface RightActionButtonsMobileProps {
  guidaHelp: boolean;
  titleID: number;
  titleBar: string;
}

const RightActionButtonsMobile = (props: RightActionButtonsMobileProps) => {
  const { guidaHelp, titleID, titleBar } = props;

  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const { t } = useTranslation();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (!(anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)))
      setOpen(false);
  };

  return (
    <Box  display='flex' flexDirection='row' justifyContent='flex-end'>
      <ButtonGroup ref={anchorRef} variant="text" color="inherit" >
        <Button onClick={() => { handleToggle() }}
       >
          {
            open
              ? <KeyboardArrowDownIcon fontSize="large" titleAccess={t("Apri")} />
              : <KeyboardArrowUpIcon fontSize="large" titleAccess={t("Chiudi")} />
          }
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal placement='bottom'>
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'top',
            }}
          >
            <Paper style={{ backgroundColor: theme.palette.primary.main, color: 'white', border: '1px solid #ffffff5e' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box display='flex' flexDirection='column' justifyContent='space-between' alignItems='center' px={1} py={2}>
                  <LanguageSelector />
                  {guidaHelp && <><HelpButton topics={{ [titleID]: titleBar }} size='large' /></>}
                  <ButtonLogout />
                  <ButtonUser />
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}

export default RightActionButtonsMobile