import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { responsiveFontSizes, ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import LeftSideNavbarWithAppBar from '../core/components/leftSideNavbarWithAppBar/LeftSideNavbarWithAppBar';
import { PrivateRoute } from '../core/components/PrivateRoute';
import LoadingSvg from '../core/components/svgs/LoadingSvg';
import PageLogin from '../core/pages/PageLogin';
import PageForbidden from '../core/pages/PageForbidden';
import PageNotFound from '../core/pages/PageNotFound';
import {
  loginPagePath, notFoundPath, forbiddenPath,
  organizzazioniPath,
  contrattiPath,
  utentiPath,
  applicazioniSoftwareFunzioniPath,
  appSoftwarePath,
  applicazioniSoftwareFunzioniProfiliPath,
  nomiHostPath,
  funzioniComponentiPath,
} from '../utils/utilconst';
import { useApp } from './useApp';
import themes from '../themes/themes';
import DialogConfirm from '../core/components/dialogs/DialogConfirm';
import OrganizzazioniW from '../core/components/crudWrappers/OrganizzazioniW';
import { itIT } from '@material-ui/core/locale';
import ContrattiW from '../core/components/crudWrappers/ContrattiW';
import UtentiW from '../core/components/crudWrappers/UtentiW';
import FunzioniW from '../core/components/crudWrappers/FunzioniW';
import ApplicazioniSoftwareW from '../core/components/crudWrappers/ApplicazioniSoftwareW';
import ProfiliW from '../core/components/crudWrappers/ProfiliW';
import NomiHostW from '../core/components/crudWrappers/NomiHostW';
import FunzioniComponentiW from '../core/components/crudWrappers/FunzioniComponentiW';

function App() {
  const { homeUri, logoUri, nomeOrganizzazione, hasIdAppSoftwareCookie } = useApp();

  return (
    <ThemeProvider theme={responsiveFontSizes(createTheme(themes["asl"], itIT))}>
      {
        (homeUri && logoUri)
          ? (
            <Suspense fallback={<LoadingSvg color="primary" width={300} />}>
              <LeftSideNavbarWithAppBar logoUri={logoUri} nomeOrganizzazione={nomeOrganizzazione}>
                <Switch>
                  {/* Gestione Organizzazione*/}
                  <PrivateRoute path={nomiHostPath}>
                    <NomiHostW />
                  </PrivateRoute>
                  <PrivateRoute path={organizzazioniPath}>
                    <OrganizzazioniW />
                  </PrivateRoute>
                  <PrivateRoute path={contrattiPath}>
                    <ContrattiW />
                  </PrivateRoute>
                  <PrivateRoute path={utentiPath}>
                    <UtentiW />
                  </PrivateRoute>

                  {/* Gestione Applicazione*/}
                  <PrivateRoute path={appSoftwarePath}>
                    <ApplicazioniSoftwareW />
                  </PrivateRoute>
                  <PrivateRoute path={applicazioniSoftwareFunzioniPath}>
                    <FunzioniW />
                  </PrivateRoute>
                  <PrivateRoute path={applicazioniSoftwareFunzioniProfiliPath} >
                    <ProfiliW />
                  </PrivateRoute>
                  <PrivateRoute path={funzioniComponentiPath} >
                    <FunzioniComponentiW />
                  </PrivateRoute>

                  <Route path={notFoundPath}><PageNotFound /></Route>
                  <Route path={forbiddenPath}><PageForbidden /></Route>
                  <Route path={loginPagePath}><PageLogin /></Route>
                  <Route path="/" exact><Redirect to={homeUri} /></Route>
                  <Route path="*"><Redirect to={notFoundPath} /></Route>
                </Switch>
                <DialogConfirm />
              </LeftSideNavbarWithAppBar>
            </Suspense>
          )
          : hasIdAppSoftwareCookie
            ? <LoadingSvg color="primary" width={400} />
            : (
              <Suspense fallback={<LoadingSvg color="primary" width={300} />}>
                <PageForbidden />
              </Suspense>
            )
      }
    </ThemeProvider>
  );
}
export default App;
