import { Column } from "@material-table/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, createStyles, Grid, IconButton, makeStyles, Modal, Paper, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import i18n from "../../../i18n";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import { Fields } from "../../../models/Fields";
import { allFields, ApplicazioneSoftwareProfiloKeys, elementIdProps, elementRenderProps } from "../../../models/ApplicazioniSoftwareProfili";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { insert, update, physicalDel, fetchAllProfiliByIdAppSoftware } from "../../../store/slices/applicazioniSoftwareProfiliSlice";
import { componentInsertPath, componentTabsPath } from "../../../utils/innerFuncPaths";
import { applicazionisoftwareProfiloPath, appSoftwarePath } from "../../../utils/utilconst";
import GeneralForm from "../forms/GeneralForm";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import { ApplicazioneSoftwareProfilo } from "../../../models/ApplicazioniSoftwareProfili";
import { ExportType } from "../../../utils/utildata";
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 20,
      maxWidth: 1000,
      marginLeft: 'auto',
      marginRight: 'auto',
      overflow: 'auto',
    },
    paper: {
      maxHeight: '85vh'
    },

    image: {
      height: 200,
    }
  }),
);

interface ApplicazioneSoftwareProfiliProps {
  idApplicazioneSoftware: number,
  applicazioniSoftwareProfili: ApplicazioneSoftwareProfilo[],
}


const ApplicazioniSoftwareProfiliW = ({ idApplicazioneSoftware, applicazioniSoftwareProfili }: ApplicazioneSoftwareProfiliProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const abilitazione = useAppSelector(state => getAbilitazione(state, appSoftwarePath));
  const [obj, setObj] = useState<ApplicazioneSoftwareProfilo | null>(null);

  const fixedProps = useMemo(() => {
    return {
      idApplicazioneSoftware: idApplicazioneSoftware,
    }
  }, [idApplicazioneSoftware])

  const classes = useStyles();

  const insertCallback = () => {
    history.push(appSoftwarePath + componentTabsPath + applicazionisoftwareProfiloPath + componentInsertPath);
  };

  const updateDetailCallback = (applicazioniSoftwareProfilo: ApplicazioneSoftwareProfilo) => {
    setObj(applicazioniSoftwareProfilo)

    history.push(appSoftwarePath + componentTabsPath + applicazionisoftwareProfiloPath + componentTabsPath)
  }

  const [logo, setLogo] = useState<string>()
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setIsOpen(true);
  }

  const handleClose = () => {
    setIsOpen(false);
    setLogo(undefined);
  }



  const resetError = useCallback(() => {
    /**
     * dispatch delle chiamate per resettare gli errori
     */
  }, []);

  const title = t('profilesParam');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<ApplicazioneSoftwareProfilo>>>([]);

  useEffect(() => {
    if (fixedProps.idApplicazioneSoftware) {
      dispatch(fetchAllProfiliByIdAppSoftware({
        idApplicazioneSoftware: fixedProps.idApplicazioneSoftware as number,
      }));
    }
  }, [dispatch, fixedProps.idApplicazioneSoftware]);

  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<ApplicazioneSoftwareProfilo> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ? f.removable : !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort ? f.sort : "asc",
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as ApplicazioneSoftwareProfiloKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;

        }

        if (f.type && f.type === "image") {

          obj.render = (rowData: ApplicazioneSoftwareProfilo) => {
            const handleclick = () => {
              setLogo(rowData?.profiloIcona)
              handleOpen()
            }
            return <Button
              onClick={handleclick}
              size='small'
              variant='outlined'
              color='primary'>
              {t('show')}
            </Button>
          }
        }

        return obj;
      })

    )
  }, [allFieldsState, excludedFieldInTable, t]);

  const validApplicazioniSoftwareProfili = useAppSelector(state => state.applicazioniSoftwareProfilo.validApplicazioneSoftwareProfilo);

  const statusValidApplicazioniSoftwareProfili = useAppSelector(state => state.applicazioniSoftwareProfilo.statusValidapplicazioneSoftwareProfilo);

  const errorBE = useAppSelector(state => state.applicazioniSoftwareProfilo.error)

  const [data, setData] = useState<Array<object>>([]);

  useEffect(() => {
    setData(validApplicazioniSoftwareProfili);
  }, [validApplicazioniSoftwareProfili]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  return (
    <>

      <Switch>
        <Route path={appSoftwarePath + componentTabsPath + applicazionisoftwareProfiloPath + componentInsertPath} exact>
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={appSoftwarePath + componentTabsPath + applicazionisoftwareProfiloPath}
            tabsUri={appSoftwarePath + componentTabsPath + applicazionisoftwareProfiloPath + componentInsertPath}
            tabsView={false}
            buttonTitle={t("profilesParam")}
            info1={t("")}
            tabs={[
              {
                label: t(""),
                tabPath: "",
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={appSoftwarePath + componentTabsPath + applicazionisoftwareProfiloPath}
                        action={insert}
                        status={statusValidApplicazioniSoftwareProfili}
                        error={errorBE}
                        update={false}
                        obj={{}}
                        fields={allFieldsState}
                        fixedProps={fixedProps}
                        translate={t}
                      />
                    </Grid>

                  </Grid>
                ),
                componentElse: <Redirect to={applicazionisoftwareProfiloPath} />,
              },
            ]}
          />

        </Route>
        <Route path={appSoftwarePath + componentTabsPath + applicazionisoftwareProfiloPath + componentTabsPath} exact>
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={appSoftwarePath + componentTabsPath + applicazionisoftwareProfiloPath}
            tabsUri={appSoftwarePath + componentTabsPath + applicazionisoftwareProfiloPath + componentTabsPath}
            tabsView={false}
            buttonTitle={t("profilesParam")}
            info1={''}
            tabs={[
              {
                label: t("profilesParam"),
                tabPath: "",
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={appSoftwarePath + componentTabsPath + applicazionisoftwareProfiloPath}
                        action={update}
                        status={statusValidApplicazioniSoftwareProfili}
                        error={errorBE}
                        update={true}
                        obj={obj}
                        fields={allFieldsState}
                        fixedProps={fixedProps}
                        translate={t}
                      />
                    </Grid>
                  </Grid>
                ),
                componentElse: <Redirect to={applicazionisoftwareProfiloPath} />,
              },
            ]}
          />
        </Route>
        <Route path='*'>
          <CrudMaterialTableWithoutLogicDelete
            abilitazione={abilitazione}
            title={title}
            columns={columns}
            data={data}
            elementIdProps={elementIdProps}
            elementRenderProps={elementRenderProps}
            fetchAllValid={fetchAllProfiliByIdAppSoftware}
            fixedProps={fixedProps}
            statusValid={statusValidApplicazioniSoftwareProfili}
            errorBE={errorBE}
            logoUri={logoUri}
            resetErrorCallback={resetError}
            insertCallback={insertCallback}
            updateCallback={updateDetailCallback}
            detailCallback={updateDetailCallback}
            exportType={ExportType.NONE}
            extraOptions={{
              maxBodyHeight: 460,
            }}
            physicalDel={physicalDel} />
        </Route>

      </Switch>
      <Modal
        className={clsx(classes.container)}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper className={clsx(classes.paper)}>
          <Grid container>
            <Grid item xs={9} />
            <Grid item xs={3}>
              <Box display='flex' justifyContent='right'>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>

            </Grid>
          </Grid>
          <Box
            display='flex'
            justifyContent='center'
            minWidth={800}
            maxWidth={800}
            mx='auto'
            p={10}
          >

            <img className={clsx(classes.image)}
              src={logo?.startsWith('data')
                ? logo
                : ![null, undefined, ''].includes(logo)
                  ? 'data:image/png;base64,' + logo
                  : ''
              }

              alt={t('logo')}
            />
          </Box>
        </Paper>
      </Modal>
    </>
  )
}
export default ApplicazioniSoftwareProfiliW;

