import { Column } from "@material-table/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Fields } from "../../../models/Fields";
import { allFields, ComponentiFunzioni, ComponentiFunzioniKeys, elementIdProps, elementRenderProps, } from "../../../models/ComponentiFunzioni";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { insert, update, physicalDel, fetchAllComponentiFunzioni } from "../../../store/slices/componentiFunzioniSlice";
import { applicazioniSoftwareFunzioniPath } from "../../../utils/utilconst";
import CrudMaterialTable from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import { fetchAllValidFunzioniComponenti } from "../../../store/slices/funzioniComponentiSlice";

interface ComponentiFunzioniProps {
  idApplicazioneSoftwareFunzione: number,
  idApplicazioneSoftware: number,
}

const ComponentiFunzioniW = ({ idApplicazioneSoftwareFunzione, idApplicazioneSoftware, }: ComponentiFunzioniProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const abilitazione = useAppSelector(state => getAbilitazione(state, applicazioniSoftwareFunzioniPath));

  const fixedProps = useMemo(() => {
    return {
      idApplicazioneSoftware: idApplicazioneSoftware,
      idApplicazioneSoftwareFunzione: idApplicazioneSoftwareFunzione,
    }
  }, [idApplicazioneSoftware, idApplicazioneSoftwareFunzione])

  const resetError = useCallback(() => {
    /**
     * dispatch delle chiamate per resettare gli errori
     */
  }, []);

  const title = t('componentsParam');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<ComponentiFunzioni>>>([]);

  const validComponentiFunzioni = useAppSelector(state => state.componentiFunzioni.validComponentiFunzioni);
  const statusValidComponentiFunzioni = useAppSelector(state => state.componentiFunzioni.statusValidComponentiFunzioni);
  const errorBE = useAppSelector(state => state.componentiFunzioni.error)

  const componentiFunzioniLookup = useAppSelector(state => state.funzioniComponenti.lookup)

  useEffect(() => {
    dispatch(fetchAllValidFunzioniComponenti({
      idApplicazioneSoftware: idApplicazioneSoftware
    }
    ))
  }, [dispatch, idApplicazioneSoftware])

  const [data, setData] = useState<Array<ComponentiFunzioni>>([]);

  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<ComponentiFunzioni> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ? f.removable : !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort ? f.sort : "asc",
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as ComponentiFunzioniKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        if (f.lookupField) {
          switch (f.field) {
            case 'idApplicazioneSoftwareComponente':
              obj.lookup = componentiFunzioniLookup;
              break;
          }
        }
        return obj;
      })
    )
  }, [allFieldsState, componentiFunzioniLookup, excludedFieldInTable, t]);

  useEffect(() => {
    setData(validComponentiFunzioni);
  }, [validComponentiFunzioni]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  return (

    <CrudMaterialTable
      abilitazione={abilitazione}
      title={title}
      columns={columns}
      columnsButton={true}
      data={data}
      elementIdProps={elementIdProps}
      elementRenderProps={elementRenderProps}
      fetchAllValid={fetchAllComponentiFunzioni}
      insert={insert}
      physicalDel={physicalDel}
      update={update}
      statusValid={statusValidComponentiFunzioni}
      errorBE={errorBE}
      logoUri={logoUri}
      fixedProps={fixedProps}
      resetErrorCallback={resetError}
      extraOptions={{
        maxBodyHeight: 460,
      }}
    />
  )
}
export default ComponentiFunzioniW;

