import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormControl, Grid, GridSize, TextField } from "@material-ui/core";
import {
  useCallback,
  useEffect
} from "react";
import { useAppDispatch } from '../../../store/hooks';
import { Lookup } from '../../../models/Utils';

interface IdSelect {
  name: string;
  label: string;
  lookup: Lookup;
  disabled: boolean;
  colsNum?: GridSize;
  breakpoints?: { xs?: GridSize, sm?: GridSize, md?: GridSize, lg?: GridSize };
  callback?: () => any;
}

interface SimpleIdSelectsProps {
  selectsArray: IdSelect[];
  states: { [selectName: string]: number | string | null };
  setStates: React.Dispatch<React.SetStateAction<{ [selectName: string]: string | number | null; }>>
}

const SimpleIdSelects = ({ selectsArray, states, setStates }: SimpleIdSelectsProps) => {
  const dispatch = useAppDispatch();

  const handleChange = useCallback((val: number | null, name: string, ind: number) => {
    let newProps: Record<string, number | null> = {}
    newProps[name] = val;

    // resetta i valori da ind in poi e pulisce il lookup precedente
    for (let i = ind + 1; i < selectsArray.length; i++) {
      newProps[selectsArray[i].name] = null;
      const callback = selectsArray[i].callback;
      callback && dispatch(callback());
    }
    setStates(prevState => { return { ...prevState, ...newProps } });
  }, [dispatch, setStates, selectsArray,])

  useEffect(() => {
    selectsArray.forEach((select, ind) => {
      if (Object.keys(select.lookup).length === 1 && (!states[select.name]))
        handleChange(Number(Object.keys(select.lookup)[0]), select.name, ind);
    });
  }, [handleChange, selectsArray, states]);


  return <>
    <Grid container spacing={2}>
      {
        selectsArray.map((select, ind) => {
          const data: Array<[string, string | number]> = [];

          for (const [key, value] of Object.entries(select.lookup)) {
            data.push([value, key])
          }

          data.sort((a: [string, string | number], b: [string, string | number]) => a[0].toLowerCase() < b[0].toLowerCase() ? -1 : 1);

          if (Object.entries(select?.lookup ?? {}).length > 0 || ind === 0) {
            const index = states[selectsArray[ind].name];
            let valoreCorrente = null !== index ? select.lookup[index] : '';

            return (
              <Grid key={select.name} item xs={select.colsNum ?? select.breakpoints?.xs} sm={select.colsNum ?? select.breakpoints?.sm} md={select.colsNum ?? select.breakpoints?.md} lg={select.colsNum ?? select.breakpoints?.lg}>
                <FormControl fullWidth>
                  <Autocomplete
                    disabled={selectsArray[ind].disabled}
                    options={data}
                    value={valoreCorrente ? [valoreCorrente.trim(), ''] : null}
                    onChange={(_: unknown, newValue: [string, string | number] | null) => handleChange(newValue ? Number(newValue[1]) : null, select.name, ind)}
                    id={"controllable-states-demo" + select.name}
                    getOptionLabel={(option) => option[0] ?? ''}
                    fullWidth
                    renderInput={(params) => <TextField
                      {...params}
                      label={select.label} variant="outlined" />}
                  />
                </FormControl>
              </Grid>
            )
          } else return <span key={select.name}></span>
        })
      }
    </Grid>
  </>
}

export default SimpleIdSelects;