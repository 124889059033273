import { Column } from "@material-table/core";
import { Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import i18n from "../../../i18n";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import { Fields } from "../../../models/Fields";
import { allFieldsFunzione, ApplicazioniSoftwareProfiliFunzioniKeys, elementIdProps, elementRenderProps } from "../../../models/ApplicazioniSoftwareProfiliFunzioni";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { insert, update, physicalDel, fetchProfiliByIds, } from "../../../store/slices/applicazioniSoftwareProfiliFunzioniSlice";
import { componentInsertPath, componentTabsPath } from "../../../utils/innerFuncPaths";
import { applicazioniSoftwareProfiliFunzioniPath, applicazioniSoftwareFunzioniPath as applicazioniSoftwareFunzioniProfiliPath } from "../../../utils/utilconst";
import GeneralForm from "../forms/GeneralForm";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import { ApplicazioniSoftwareProfiliFunzioni } from "../../../models/ApplicazioniSoftwareProfiliFunzioni";
import { AbilitazioneLookup, AbilitazioneLookupKeys, ExportType } from "../../../utils/utildata";
import { fetchAllProfiliByIdAppSoftware } from "../../../store/slices/applicazioniSoftwareProfiliSlice";
import { fetchById } from "../../../store/slices/applicazioniSoftwareFunzioniOperazioniSlice";
import { fetchAll as fetchAllApplicazioneSoftware } from "../../../store/slices/applicazioniSoftwareSlice";
import { Lookup } from "../../../models/Utils";

interface ApplicazioniSoftwareProfiliFunzioniProps {
  idApplicazioneSoftwareFunzione: number,
  idApplicazioneSoftware: number,
  applicazioneSoftwareDescrizione: string,
  applicazioneSoftwareFunzioneDescrizione: string,
}

const FunzioniProfiliW = ({ idApplicazioneSoftwareFunzione, idApplicazioneSoftware, applicazioneSoftwareDescrizione, applicazioneSoftwareFunzioneDescrizione }: ApplicazioniSoftwareProfiliFunzioniProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const abilitazione = useAppSelector(state => getAbilitazione(state, applicazioniSoftwareFunzioniProfiliPath));
  const [obj, setObj] = useState<ApplicazioniSoftwareProfiliFunzioni | null>(null);

  const insertCallback = () => {
    const initValue: Partial<ApplicazioniSoftwareProfiliFunzioni> = {
      idApplicazioneSoftware: idApplicazioneSoftware,
      applicazioneSoftwareDescrizione: applicazioneSoftwareDescrizione,
      idApplicazioneSoftwareFunzione: idApplicazioneSoftwareFunzione,
      applicazioneSoftwareFunzioneDescrizione: applicazioneSoftwareFunzioneDescrizione,
      abilitazioni: abilitazione,
    };

    setObj(initValue as ApplicazioniSoftwareProfiliFunzioni);
    history.push(applicazioniSoftwareFunzioniProfiliPath + componentTabsPath + applicazioniSoftwareProfiliFunzioniPath + componentInsertPath);
  };

  const resetError = useCallback(() => {
    /**
     * dispatch delle chiamate per resettare gli errori
     */
  }, []);


  const updateDetailCallback = (applicazioniSoftwareProfiliFunzioni: ApplicazioniSoftwareProfiliFunzioni) => {
    setObj(applicazioniSoftwareProfiliFunzioni)

    history.push(applicazioniSoftwareFunzioniProfiliPath + componentTabsPath + applicazioniSoftwareProfiliFunzioniPath + componentTabsPath)
  }

  const title = t('applicationSoftwareFunctionProfile');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFieldsFunzione);
  const [columns, setColumns] = useState<Array<Column<ApplicazioniSoftwareProfiliFunzioni>>>([]);

  const fixedProps = useMemo(() => {
    return {
      idApplicazioneSoftwareFunzione: idApplicazioneSoftwareFunzione,
      idApplicazioneSoftware: idApplicazioneSoftware,
    }
  }, [idApplicazioneSoftware, idApplicazioneSoftwareFunzione])

  useEffect(() => {
    dispatch(fetchAllApplicazioneSoftware());
    dispatch(fetchAllProfiliByIdAppSoftware({
      idApplicazioneSoftware: idApplicazioneSoftware
    }))
    dispatch(fetchById({
      idApplicazioneSoftware: idApplicazioneSoftware,
      idApplicazioneSoftwareFunzione: idApplicazioneSoftwareFunzione
    }))
  }, [dispatch, idApplicazioneSoftware, idApplicazioneSoftwareFunzione])

  const validApplicazioniSoftwareProfiliFunzioni = useAppSelector(state => state.applicazioniSoftwareProfiliFunzioni.validApplicazioniSoftwareProfiliFunzioni);
  const statusValidApplicazioniSoftwareProfiliFunzioni = useAppSelector(state => state.applicazioniSoftwareProfiliFunzioni.statusValidapplicazioniSoftwareProfiliFunzioni);
  const errorBE = useAppSelector(state => state.applicazioniSoftwareProfiliFunzioni.error)
  const applicazioneSoftwareProfiloLookup = useAppSelector(state => state.applicazioniSoftwareProfilo.lookup);

  const [data, setData] = useState<Array<ApplicazioniSoftwareProfiliFunzioni>>([]);

  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<ApplicazioniSoftwareProfiliFunzioni> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ? f.removable : !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort ? f.sort : "asc",
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as ApplicazioniSoftwareProfiliFunzioniKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        if (f.lookupField)
          switch (f.field) {
            case 'abilitazioni':
              obj.lookup = AbilitazioneLookup;
              break;
          }
        return obj;
      })

    )
  }, [allFieldsState, applicazioneSoftwareProfiloLookup, excludedFieldInTable, t]);

  const formLookups = useMemo(() => {
    const lookup: Lookup = {}

    Object.keys(AbilitazioneLookup).forEach((elem: unknown) => {
      lookup[elem as AbilitazioneLookupKeys] = AbilitazioneLookup[elem as AbilitazioneLookupKeys];
    })

    return {
      abilitazioni: lookup,
      idApplicazioneSoftwareProfilo: applicazioneSoftwareProfiloLookup,
    }
  }, [applicazioneSoftwareProfiloLookup])

  useEffect(() => {
    setData(validApplicazioniSoftwareProfiliFunzioni);
  }, [validApplicazioniSoftwareProfiliFunzioni]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  return (
    <>
      <Switch>
        <Route path={applicazioniSoftwareFunzioniProfiliPath + componentTabsPath + applicazioniSoftwareProfiliFunzioniPath + componentInsertPath} exact>
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={applicazioniSoftwareFunzioniProfiliPath + componentTabsPath + applicazioniSoftwareProfiliFunzioniPath}
            tabsUri={applicazioniSoftwareFunzioniProfiliPath + componentTabsPath + applicazioniSoftwareProfiliFunzioniPath + componentInsertPath}
            tabsView={false}
            buttonTitle={t("applicationsSoftwareParam")}
            info1={t("")}
            tabs={[
              {
                label: t(""),
                tabPath: "",
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={applicazioniSoftwareFunzioniProfiliPath + componentTabsPath + applicazioniSoftwareProfiliFunzioniPath}
                        action={insert}
                        status={statusValidApplicazioniSoftwareProfiliFunzioni}
                        error={errorBE}
                        update={false}
                        obj={obj}
                        fields={allFieldsState}
                        lookups={formLookups}
                        fixedProps={fixedProps}
                        translate={t}

                      />
                    </Grid>

                  </Grid>
                ),
                componentElse: <Redirect to={applicazioniSoftwareProfiliFunzioniPath} />,
              },
            ]}
          />

        </Route>
        <Route path={applicazioniSoftwareFunzioniProfiliPath + componentTabsPath + applicazioniSoftwareProfiliFunzioniPath + componentTabsPath} exact>
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={applicazioniSoftwareFunzioniProfiliPath + componentTabsPath + applicazioniSoftwareProfiliFunzioniPath}
            tabsUri={applicazioniSoftwareFunzioniProfiliPath + componentTabsPath + applicazioniSoftwareProfiliFunzioniPath + componentTabsPath}
            tabsView={false}
            buttonTitle={t("applicationSoftwareProfileFunction")}
            info1={obj?.applicazioneSoftwareProfiloDescrizione ?? ''}
            tabs={[
              {
                label: t("applicationSoftwareProfileFunction"),
                tabPath: "",
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={applicazioniSoftwareFunzioniProfiliPath + componentTabsPath + applicazioniSoftwareProfiliFunzioniPath}
                        action={update}
                        status={statusValidApplicazioniSoftwareProfiliFunzioni}
                        error={errorBE}
                        update={true}
                        obj={obj}
                        fields={allFieldsState}
                        fixedProps={fixedProps}
                        lookups={formLookups}
                        translate={t}
                      />
                    </Grid>
                  </Grid>
                ),
                componentElse: <Redirect to={applicazioniSoftwareProfiliFunzioniPath} />,
              },
            ]}
          />
        </Route>
        <Route path='*'>
          <CrudMaterialTableWithoutLogicDelete
            abilitazione={abilitazione}
            title={title}
            columns={columns}
            data={data}
            elementIdProps={elementIdProps}
            elementRenderProps={elementRenderProps}
            fetchAllValid={fetchProfiliByIds}
            fixedProps={fixedProps}
            statusValid={statusValidApplicazioniSoftwareProfiliFunzioni}
            errorBE={errorBE}
            logoUri={logoUri}
            resetErrorCallback={resetError}
            insertCallback={insertCallback}
            updateCallback={updateDetailCallback}
            detailCallback={updateDetailCallback}
            exportType={ExportType.NONE}
            extraOptions={{
              maxBodyHeight: 460,
            }}
            physicalDel={physicalDel} />
        </Route>
      </Switch>
    </>
  )
}

export default FunzioniProfiliW;