import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, getAll, getAllValid, getById, upd } from '../../services/services.service'
import { ApplicazioneSoftware, ApplicazioneSoftwareKeys, elementIdProps } from '../../models/ApplicazioniSoftware';
import { createLookup } from '../../utils/utilfunctions';

const microservice = "appsoftware";
const entity = "appsoftware";

interface ApplicazioniSoftwareState {
  statusValidApplicazioniSoftware: StatusEnum,
  validApplicazioniSoftware: ApplicazioneSoftware[],
  ApplicazioniSoftware: ApplicazioneSoftware | null,
  translation: { [lang: string]: { [codice: string]: any } },
  lookup: Lookup,
  error: string | null
}

const initialState: ApplicazioniSoftwareState = {
  statusValidApplicazioniSoftware: StatusEnum.Succeeded,
  validApplicazioniSoftware: [],
  ApplicazioniSoftware: null,
  lookup: {},
  translation: {},
  error: null
}

//@GetMapping("cerca/appsoftware/id/{idappsoftware}")
export const fetchById = createAsyncThunk(microservice + '/fetchById', async (obj: { idappsoftware: number }) => {
  let ids = [obj.idappsoftware];
  const response = await getById(microservice, entity, ids);
  return response.data as ApplicazioneSoftware;
});

//@GetMapping("cerca/appsoftware/all")
export const fetchAll = createAsyncThunk(microservice + '/fetchAll', async () => {
  const response = await getAll(microservice, entity);
  return response.data as ApplicazioneSoftware[];
});

//@GetMapping("cerca/appsoftware/allValid")
export const fetchAllValid = createAsyncThunk(microservice + '/fetchAllValid', async () => {
  const response = await getAllValid(microservice, entity);
  return response.data as ApplicazioneSoftware[];
});

// inserisci/appsoftware
export const insert = createAsyncThunk(microservice + '/insert', async (appsoftware: ApplicazioneSoftware) => {
  const response = await create(appsoftware, microservice, entity);
  return response.data as ApplicazioneSoftware;
});

// modifica/appsoftware
export const update = createAsyncThunk(microservice + '/update', async (appsoftware: ApplicazioneSoftware) => {
  appsoftware.applicazioniSoftwareDizionarioDati = [];
  appsoftware.applicazioniSoftwareProfili = [];
  appsoftware.appWebAbilitata = !!appsoftware.appWebAbilitata
  const response = await upd(appsoftware, microservice, entity);
  return response.data as ApplicazioneSoftware;
});

// delete/appsoftware/id/{idappsoftware}
export const physicalDel = createAsyncThunk(microservice + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const applicazioneSoftwareSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    cleanTranslation: (state: ApplicazioniSoftwareState) => {
      state.translation = {};
    },
    cleanLookup: (state: ApplicazioniSoftwareState) => {
      state.lookup = {};
    },
    reset: (state: ApplicazioniSoftwareState) => {
      return initialState;
    },
    resetError: (state: ApplicazioniSoftwareState) => {
      state.error = initialState.error;
      state.statusValidApplicazioniSoftware = initialState.statusValidApplicazioniSoftware;
    }
  },
  extraReducers: builder => {

    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidApplicazioniSoftware = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidApplicazioniSoftware = StatusEnum.Failed;
      state.ApplicazioniSoftware = null;
      state.translation = {};
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<ApplicazioneSoftware>) => {
      state.statusValidApplicazioniSoftware = StatusEnum.Succeeded;
      state.ApplicazioniSoftware = payload;
      const temp: Record<string, any> = {}
      payload.applicazioniSoftwareDizionarioDati.forEach(elem => {
        if (!temp[elem.lingua]) {
          temp[elem.lingua] = {};
        }
        temp[elem.lingua][elem.codice] = elem.valore.startsWith('[') ? JSON.parse(elem.valore) : elem.valore;
      })
      state.translation = { ...temp }
    })

    // fetchAll
    builder.addCase(fetchAll.pending, (state) => {
      state.statusValidApplicazioniSoftware = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidApplicazioniSoftware = StatusEnum.Failed;
      state.validApplicazioniSoftware = [];
      state.lookup = {};
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<ApplicazioneSoftware[]>) => {
      state.statusValidApplicazioniSoftware = StatusEnum.Succeeded;
      state.validApplicazioniSoftware = payload ?? [];
      state.lookup = createLookup(payload, "idApplicazioneSoftware", ["descrizione"])
    })

    // fetchAllValid
    builder.addCase(fetchAllValid.pending, (state) => {
      state.statusValidApplicazioniSoftware = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValid.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidApplicazioniSoftware = StatusEnum.Failed;
      state.validApplicazioniSoftware = [];
      state.lookup = {};
    })
    builder.addCase(fetchAllValid.fulfilled, (state, { payload }: PayloadAction<ApplicazioneSoftware[]>) => {
      state.statusValidApplicazioniSoftware = StatusEnum.Succeeded;
      state.validApplicazioniSoftware = payload ?? [];
      state.lookup = createLookup(payload, "idApplicazioneSoftware", ["descrizione"])
    })

    //   // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidApplicazioniSoftware = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidApplicazioniSoftware = StatusEnum.Failed;
      state.ApplicazioniSoftware = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<ApplicazioneSoftware>) => {
      state.statusValidApplicazioniSoftware = StatusEnum.Succeeded;
      state.ApplicazioniSoftware = payload;
      state.validApplicazioniSoftware = state.validApplicazioniSoftware.concat([payload]);
    })

    //   // update
    builder.addCase(update.pending, (state) => {
      state.statusValidApplicazioniSoftware = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidApplicazioniSoftware = StatusEnum.Failed;
      state.ApplicazioniSoftware = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<ApplicazioneSoftware>) => {
      state.statusValidApplicazioniSoftware = StatusEnum.Succeeded;
      state.validApplicazioniSoftware = state.validApplicazioniSoftware.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.ApplicazioniSoftware = payload;
    })

    //   // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusValidApplicazioniSoftware = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidApplicazioniSoftware = StatusEnum.Failed;
      state.validApplicazioniSoftware = [];
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidApplicazioniSoftware = StatusEnum.Succeeded;
      state.validApplicazioniSoftware = state.validApplicazioniSoftware.filter(el => payload.ids.some(idObj => el[idObj.name as ApplicazioneSoftwareKeys] !== idObj.id));
    })
  }
});

export const { cleanLookup, reset, resetError } = applicazioneSoftwareSlice.actions;
export default applicazioneSoftwareSlice.reducer;

