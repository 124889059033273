import React, { useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
export interface TabElem {
  label: string,
  tabPath: string,
  abilitazione: number,
  componentIf: React.ReactNode,
  componentElse: React.ReactNode
}

export interface SimpleTabsProps {
  tabs: TabElem[],
  tabRoute: (uri: string) => void
}

export default function SimpleTabs({ tabs, tabRoute }: SimpleTabsProps) {
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (value >= 0) {
      setValue(value);
      tabRoute(tabs[value].tabPath);
    }
  }, [value, tabRoute, tabs]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    return () => {
      setValue(0);
    };
  }, []);

  return <>
    {value >= 0 && (
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant='scrollable'
      // centered
      >
        {
          tabs.map(t => <Tab wrapped key={t.label} label={t.label} />)
        }
      </Tabs>
    )}
  </>;
}